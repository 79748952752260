import React from "react";
import './Slider.css'
import '../../../css/animate.css'
import '../../../css/material-icons.css'
// import {Carousel} from 'react-bootstrap'
import { getWithQuery,toCloudinary } from '../../../services/CommonServices'
import Spinner from '../../Loading/Loading'
import Carousel from 'nuka-carousel';

class Slider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      ready: false,
      models: []
    }

  }

  componentWillMount() {
    var outsideThis = this
    getWithQuery("model","find",{latest:true})
      .then(function (response) {
        const models = response.data.sort(function (a, b) {
          return new Date(b.modified) - new Date(a.modified);
        });
        outsideThis.setState({ models: models, ready: true })
      })
  }
  handleClick(id) {
    this.props.history.push({
      pathname: '/single-product/' + id
    })
  }
  render() {
    if (!this.state.ready) {
      return (
        <div style={{ marginTop: 200, marginBottom: 200 }}>
          <Spinner />
        </div>
      )
    }
    return (
      <div>
        {/* Start Section Showrooms */}
 
        <section className="gemaa-slider">
          <div className="gemaa-slider__inner-holder container-fluid">
            <div className="gemaa-slider__wrapper row">
              <div className="gemaa-slider__carousel carousel slide" data-ride="carousel" id="gemaa-slider-carousel">
                <div className="gemaa-slider__inner carousel-inner">
                <Carousel
                autoplay
                wrapAround
                speed={1000}
                autoplayInterval={4000}
                renderBottomCenterControls={false}
                easing={"easeCubicInOut"}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button className={"left-carousel-button"} onClick={previousSlide}></button>
                )}
                
                renderCenterRightControls={({ nextSlide }) => (
                  <button className={"right-carousel-button"}onClick={nextSlide}></button>
                )}
                >
                  {
                    this.state.models.map((model, key) => (
                        <React.Fragment>
                            <div onClick={()=>this.handleClick(model._id)} className="gemaa-slide__caption-holder"> 
                              <div className="gemaa-slide__centerd col-md-12 text-center">
                                <img src={require("../../../img/slider/chape-holder.png")} alt="Secon slide" />
                              <div className="gemaa-slider__caption">
                                  <h2 class="wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.4s">Our latest collection </h2>
                                  <h1 className="wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
                                    {model.name}
                                  </h1>
                                </div>
                              </div>
                            </div>

                          <div className={"gemaa-slider__carousel-holder item" + (key === 0 ? (" active") : (''))}>
                          
                            <img  class="gemma-slider_image" src={(toCloudinary(model.images[0],null,1440)) || (require("../../../img/download.png"))} alt="First slide" />
                          
                          </div>
                       </React.Fragment>
                      
                    ))
                    
                  }
                  </Carousel>
                </div>
              </div>
              {/* Slider Controllers */}
              {/* <a className="left carousel-control" href="#gemaa-slider-carousel" data-slide="prev">
                <span> <img src={require("../../../img/slider/arrow-left.png")} className="glyphicon glyphicon-chevron-left" /></span> </a>
              <a className="right carousel-control" href="#gemaa-slider-carousel" data-slide="next">
                <span><img src={require("../../../img/slider/arrow-right.png")} className="glyphicon glyphicon-chevron-right" />
                </span></a> */}
              {/* Slider Controllers */}
            </div>
          </div>
        </section>
        {/* End Section Showrooms */}
      </div>
    )
  }
}
export default Slider;