import React from "react";
import { Pagination } from "react-bootstrap";
import './pagination.css'



class pagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleSelect(eventKey) {
    this.props.onPageChange(eventKey.target.id*1)
  }
  handleNext(){
    let modelsLength=this.props.modelsLength;
    if(this.props.currentPage<(modelsLength/this.props.Productslimit)-1){
      this.props.onPageChange(this.props.currentPage+1)
    }
  }
  handlePrevious(){
    if(this.props.currentPage>0){
      this.props.onPageChange(this.props.currentPage-1)
    }
  }
  render() {
    let items = [];
    let modelsLength=this.props.modelsLength;
    for (let number =0; number <modelsLength/this.props.Productslimit; number++) {
      items.push(
        <Pagination.Item active={number === this.props.currentPage} id={number} onClick={this.handleSelect}>{number+1}</Pagination.Item>
      );
    }
    return (
      <div className="shop-pagination">
        <Pagination className="shop-pagination__link shop-pagination__active" bsSize="medium" >
          <Pagination.Prev className="shop-pagination__link-previous" onClick={this.handlePrevious}>
            Previous Page
          </Pagination.Prev>
          {
            items
          }
          <Pagination.Next className="shop-pagination__link-next" onClick={this.handleNext}>
            Next Page
          </Pagination.Next>
        </Pagination>
      </div>
    );
  }
}

export default pagination;