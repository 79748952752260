import React from "react";
import "./NewsLetter.css";
class NewsLetter extends React.PureComponent {
    render() {
        return (
            <div className="clearfix">

                <div className="col-md-9">
                    <div className="user-border__right">
                        <div id="user-newsletter" className="user-newsletter">
                            <div className="user-newsletter__caption">
                                <p> Adjust the options below to control how often you want to be updated <br /> regarding GEMMA’S new products and promotions.</p>
                            </div>
                            <div className="interested-in control-group">
                                <p>Receive emails regarding:</p>
                                <label className="interested-in__name control control--radio">
                                    Status change in my order
                  <input type="radio" name="radio" defaultChecked="checked" />
                                    <div className="control__indicator" />
                                </label>
                                <label className="interested-in__name control control--radio">
                                    New information is added to the FAQ
                  <input type="radio" name="radio" />
                                    <div className="control__indicator" />
                                </label>
                                <label className="interested-in__name control control--radio">
                                    GEMMA promotions and discounts
                  <input type="radio" name="radio" />
                                    <div className="control__indicator" />
                                </label>
                                <label className="interested-in__name control control--radio">
                                    New online showrooms available
                  <input type="radio" name="radio" />
                                    <div className="control__indicator" />
                                </label>
                                <div className="user-wishlist__btn-holder">
                                    <button type="button" className="user-wishlist__cancel-btn btn btn-default"> Cancel</button>
                                    <button type="button" className="user-wishlist__save-btn btn btn-default">Save Changes </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NewsLetter;