import React from 'react'
import './Banner.css'
const Banner = (props) => {
    return props.show?(
        (
            <div className="warning-banner">
               <h4>FOR A BETTER EXPERIENCE, KEEP YOUR BROWSER UP TO DATE. <a href="http://outdatedbrowser.com/en">CHECK HERE</a> FOR LATEST VERSIONS.</h4>
            </div>
        )
    ):('')
  };
export default Banner;