import React from "react";
import './Collections.css'
import '../../../css/material-icons.css'
import { Link } from "react-router-dom"
import { getAllObjects ,toCloudinary} from "../../../services/CommonServices";
import Spinner from '../../Loading/Loading'
import { Parallax } from 'react-parallax';
import Plx from "react-plx";

class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      cms: {
      },
      ready: false
    }
  }
  handleClick(id) {
    this.props.history.push({
      pathname: '/single-product/' + id
    })
  }
  componentDidMount() {
    getAllObjects("cms", {})
      .then((response) => {
        this.setState({ cms: response.data[0], ready: true })
      })
  }
  render() {
    const strength = (isMobile()) ? (0) : (170)
    const duration = 0
    const start = 'collection__holder' //collection__holder
    function isMobile() {
      return window.innerWidth <= 760;
    }
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <div class="main-title">

          <h2 class="main-line-title wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.6s">
            <Plx disabled={isMobile()} style={{ display: "inline" }} parallaxData={[{
              start: 'self',
              duration: 600,
              properties: [
                {
                  startValue: -4,
                  endValue: 4,
                  property: "letter-spacing"
                }
              ]
            }]}>collections
          </Plx>
          </h2>
        </div>
        {
          !this.state.ready ?
            (
              <div style={{ marginTop: 200, marginBottom: 200 }}>
                <Spinner />
              </div>
            ) :
            (
              <section className="collection">
                <div className="collection__holder">
                  <div className="collection__main-item">
                    <div onClick={() => this.handleClick(this.state.cms.topLeft._id)} className="collection__item">
                      <div className="collection__image">
                      {console.log(strength)}
                      {console.log(isMobile())}
                        <div className="collection__item-one ">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromLeftTopXY}> */}
                            <div class="caption">
                              <Parallax
                                bgImage={this.state.cms.topLeft ? (toCloudinary(this.state.cms.topLeft.images[0],900,null)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container bottom-top">
                                  <div className="collection__caption top-bottom" />
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.topLeft ? (this.state.cms.topLeft.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.top._id)} className="collection__item">
                      <div className="collection__image">
                        <div className="collection__item-two">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromTopY}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.top ? (toCloudinary(this.state.cms.top.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container bottom-right">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.top ? (this.state.cms.top.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.center._id)} className="collection__item">
                      <div className="collection__image ">
                        <div className="collection__item-three">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromLeftX}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.center ? (toCloudinary(this.state.cms.center.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container bottom-left">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.center ? (this.state.cms.center.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.bottomLeft._id)} className="collection__full-width no-padding">
                      <div className="collection__image">
                        <div className="collection__item-four ">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromLeftBotXY}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.bottomLeft ? (toCloudinary(this.state.cms.bottomLeft.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container right">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.bottomLeft ? (this.state.cms.bottomLeft.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="collection__main-item">
                    <div onClick={() => this.handleClick(this.state.cms.topRight._id)} className="collection__full-width no-padding">
                      <div className="collection__image">
                        <div className="collection__item-five ">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromRightTopXY}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.topRight ? (toCloudinary(this.state.cms.topRight.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container left">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.topRight ? (this.state.cms.topRight.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.bottom._id)} style={{ paddingRight: 15 }} className="collection__item">
                      <div className="collection__image ">
                        <div className="collection__item-six">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromBotY}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.bottom ? (toCloudinary(this.state.cms.bottom.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container top-bottom">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.bottom ? (this.state.cms.bottom.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.right._id)} className="collection__item">
                      <div className="collection__image  ">
                        <div className="collection__item-seven">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromRightX}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.right ? (toCloudinary(this.state.cms.right.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container top-right">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.right ? (this.state.cms.right.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.handleClick(this.state.cms.bottomRight._id)} className="collection__item">
                      <div className="collection__image ">
                        <div className="collection__item-eight">
                          {/* <Plx disabled={isMobile()} parallaxData={translateFromRightBotXY}> */}
                            <div class="caption">

                              <Parallax
                                bgImage={this.state.cms.bottomRight ? (toCloudinary(this.state.cms.bottomRight.images[0],900)) : ('')}
                                bgImageAlt="404"
                                strength={strength}
                              >
                                <div className="collection__container top-left">
                                  <div className="collection__caption top-bottom">
                                  </div>
                                </div>
                              </Parallax>
                              <div class="details">
                                <span class="title"><h3>{this.state.cms.bottomRight ? (this.state.cms.bottomRight.name) : ('')}</h3></span>
                              </div>
                            </div>
                          {/* </Plx> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="collection-btn">
                    <Link to={{
                      pathname: "/shop",
                      search: "?latest=true"
                    }}><button type="button" className="main-button collection__btn btn btn-default">VIEW LATEST COLLECTIONS</button></Link>
                  </div>
                </div>
              </section>
            )
        }
        {/* End Section  collection-grid */}
        {/* Javascripts
          ================================================== */}
      </div>
    );
  }
}
export default Collections;