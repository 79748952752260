import React from "react";
import "./PersonalInformation.css";
import { getObjectWithToken, updateObjectWithToken } from '../../services/CommonServices'
import { NotificationManager } from 'react-notifications';
import Axios from "axios";
import config from '../../config';
class PersonalInformation extends React.Component {
    constructor(props) {
        super(props)
        this.AddInformation = this.AddInformation.bind(this);
        this.state = {
            name: "",
            newEmail: "",
            telephone: "",
            old_password: undefined,
            new_password: undefined,
            retyped_password: undefined

        }
    }
    AddInformation(event) {
        var outsideThis = this;
        event.preventDefault();
        if (this.state.new_password) {
            if (!this.state.old_password) {
                // TODO:Old password is missing
                NotificationManager.error('Old Password is required', 'Update Error', 5000, () => {
                })
                return;
            }
            if (this.state.retyped_password !== this.state.new_password) {
                // TODO:Passwords do not match
                NotificationManager.error('Passwords do not match!', 'Update Error!', 5000, () => {
                })

                return;
            }
        }
        updateObjectWithToken("users/profile", {
            email:this.state.newEmail,
            name: this.state.name,
            phone_number: this.state.telephone,
            old_password: this.state.old_password,
            password: this.state.new_password,
        })
            .then(function (response) {
                if (response.data.error) {
                    //TODO: Handle error
                } else {
                    NotificationManager.success('Successfully updated your account', 'Successful Update!', 5000, () => { })
                    outsideThis.componentDidMount()
                }
            })


    }

    componentDidMount() {
        const context = this;
        getObjectWithToken("users/profile")
            .then((response) => {
                if (response.data.error) {
                    //TODO: Handle error
                } else {
                    context.setState({
                        name: response.data.name,
                        newEmail: response.data.email,
                        oldEmail: response.data.email,
                        telephone: response.data.phone_number,
                        old_password: undefined,
                        new_password: undefined,
                        retyped_password: undefined
                    })

                }
            })
    }
    changeValue(event, key) {
        this.setState({ [key]: event.target.value })
    }
    render() {
        return (

            <form onSubmit={this.AddInformation}>
                <div className="col-md-9">
                    <div className="user-border__right">
                        <div id="user-information" className="user-account-information">

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="user-account__name">
                                        <p>Name</p>
                                        <input
                                            value={this.state.name}
                                            onChange={(e) => { this.changeValue(e, "name") }}
                                            className="contact-form__name-btn"
                                            placeholder="Name"
                                            type="text" />
                                    </div>
                                    <div className="user-account__name">
                                        <p>Email</p>
                                        <input
                                            value={this.state.newEmail}
                                            onChange={(e) => { this.changeValue(e, "newEmail") }}
                                            className="contact-form__name-btn"
                                            placeholder="example@example.com"
                                            type="email" />
                                    </div>
                                    <div className="user-account__name">
                                        <p>Phone Number</p>
                                        <input
                                            disabled
                                            value={this.state.telephone}
                                            onChange={(e) => { this.changeValue(e, "telephone") }}
                                            className="contact-form__name-btn"
                                            placeholder="----"
                                            type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="user-account__name">
                                        <p>Old Password</p>
                                        <input
                                            value={this.state.old_password}
                                            onChange={(e) => { this.changeValue(e, "old_password") }}
                                            className="contact-form__name-btn"
                                            placeholder="Old Password"
                                            type="password" />
                                    </div>
                                    <div className="user-account__name">
                                        <p>New Password</p>
                                        <input
                                            value={this.state.new_password}
                                            onChange={(e) => { this.changeValue(e, "new_password") }}
                                            className="contact-form__name-btn"
                                            placeholder="New Password"
                                            type="password" />
                                    </div>
                                    <div className="user-account__name">
                                        <p>Retype New Password</p>
                                        <input
                                            value={this.state.retyped_password}
                                            onChange={(e) => { this.changeValue(e, "retyped_password") }}
                                            className="contact-form__name-btn"
                                            placeholder="Retype New Password"
                                            type="password" />
                                    </div>
                                    <div className="user-account__btn">
                                        <button type="button" className="user-account__cancel btn btn-default">Cancel</button>
                                        <button type="submit" className="user-account__save btn btn-default">Save info </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>

        )
    }
}
export default PersonalInformation;