import React from "react";
import './Home.css'
import '../../../css/animate.css'
import '../../../css/material-icons.css'
import Slider from '../../shop/Slider/Slider'
import Collections from '../../shop/Collections/Collections'
import DesignYourOwn from '../../shop/DesignYourOwn/DesignYourOwn'
import ShowRooms from '../../shop/ShowRooms/ShowRooms'
import { Parallax } from 'react-parallax';
import { getAllObjects } from "../../../services/CommonServices";
import { Grid, Row, Col } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import HomerBanner from "../../HomeBanner/HomeBanner";
class Home extends React.Component {
  state = {
    wall_url: "",
    floor_url: "",
    porcelain_url: "",
    showModal: false,

  }
  componentDidMount() {
    getAllObjects("cms")
      .then(res => {
        this.setState({ wall_url: res.data[0].wall_url, floor_url: res.data[0].floor_url, porcelain_url: res.data[0].porcelain_url, showModal: true, });
      });
      
  }


  handleViewClick = (viewOption) => {
    let win = null
    if (viewOption === "wall") {
      win = window.open(this.state.wall_url, '_blank');
      win.focus();
    }
    else if (viewOption === 'floor')
    {
      win = window.open(this.state.floor_url, '_blank');
      win.focus();
    } else {
      win = window.open(this.state.porcelain_url, '_blank');
      win.focus();
    }
  }

  onModalHide = () => {
    this.setState({
      showModal: false,
    })
  }

  render() {
    return (
      <div >
        <Slider {...this.props} />
        <Collections history={this.props.history} />
        <div className="design-img">
          <Parallax
            bgImage={require("../../../img/collections/cersei.jpg")}
            bgImageAlt="404"
            strength={300}
          >
            <Grid style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
              <Row style={{ margin: 'auto' }} >
                <Col xs={12} sm={12} md={12}>
                  <div className="catalogue-container">
                    <Link to="/shop"><button type="button" className="main-button collection__btn btn btn-default catalogue-btn" >Online Shopping</button></Link>
                  </div>
                </Col>
                {/* <Col xs={12} sm={6} md={6}>
                  <div className="catalogue-container">
                    <button onClick={() => this.handleViewClick("floor")} type="button" className="main-button collection__btn btn btn-default catalogue-btn" type="button" >View Floor Catalogue</button>
                  </div>
                </Col> */}
              </Row>
            </Grid>
          </Parallax>
        </div>
       <HomerBanner/>
        <DesignYourOwn />
        <ShowRooms />
      </div>
    );
  }
}
export default Home;