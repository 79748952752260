import React from "react";
import './Confirmation.css'
import '../../../css/material-icons.css'

class Confirmation extends React.Component {
    
    componentDidMount(){
        this.props.onChangeCart();
    }
    render() {
        return (
            <div className="confirmation">
                <h1>Your Order Has Been Confirmed</h1>
                <h2>{"Order ID : "+this.props.location.state.order} </h2>
                {this.props.location.state.receipt?(<h2>{"Receipt No : "+this.props.location.state.receipt} </h2>):('')}
            </div>

        );
    }
}
export default Confirmation;

