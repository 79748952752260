import React from "react";
import './Cart.css'
import { Link } from "react-router-dom"
import { NotificationManager } from 'react-notifications';
import { toPrice, toBoxPrice, getObjectWithToken, updateObjectWithToken,toCloudinary } from '../../../services/CommonServices'
import { Glyphicon } from 'react-bootstrap'
import Spinner from '../../Loading/Loading';
import Lightbox from 'react-images';
import Tooltip from "react-simple-tooltip"

class Cart extends React.Component {
  constructor(props) {
    super(props)
    //  this.orderProducts = this.orderProducts.bind(this)
    // this.checkCode = this.checkCode.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrev = this.gotoPrev.bind(this);
    this.handleLightClick = this.handleLightClick.bind(this);
    this.clickThumbnail = this.clickThumbnail.bind(this);
    this.state = {
      cart: {
        products: []
      },
      code: '',
      user: {},
      products: [],
      ready: false,
      lightboxImages: [
        {
          src: ''
        }
      ],
      lightboxIsOpen: false,
      currentImage: 0,
      blockCheckout: false
    }
  }
  removeFromCart(key) {
    let cart = this.state.cart
    cart.products.splice(key, 1);
    this.setState({ cart: cart })
    updateObjectWithToken('users', {
      cart: {
        products: cart.products
      }
    })
      .then((response) => {
        if (this.props.onChangeCart) {
          this.props.onChangeCart()
          this.checkifEnoughStock();
        }
      })
  }
  getTotalPrice() {
    let products = this.state.cart.products
    let total = 0;
    for (let i = 0; i < products.length; i++) {
      if (products[i].product.prices[products[i].tier]) {
        total += toBoxPrice(products[i].product, products[i].product.prices[products[i].tier]) * products[i].quantity;
      }
    }
    return total;
  }
  componentDidMount() {
    var outsideThis = this
    getObjectWithToken("users/cart")
      .then((response) => {
        outsideThis.setState({ cart: response.data.cart, ready: true }, this.checkifEnoughStock)
      })

    getObjectWithToken("users/profile")
    .then((response) => {
        if (response.data) {
            outsideThis.setState({ user: response.data })
        }
    })
    // getObjectWithToken("users/profile")
    //   .then((response) => {
    //     if (response.data) {
    //       outsideThis.setState({ user: response.data })
    //     }
    //   })
  }
  componentWillMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push({
        pathname: '/register',
      })
    }
  }
  changeQuantity(event, key) {
    let cart = this.state.cart
    cart.products[key].quantity = event.target.value
    this.setState({ cart: cart })
    updateObjectWithToken('users', {
      cart: {
        products: cart.products
      }
    })
      .then((response) => {
        if (this.props.onChangeCart) {
          this.props.onChangeCart()
        }
        console.log('Change in quantity');
        this.checkifEnoughStock();
      })
  }

  // checkCode() {
  //   if (this.state.code === this.state.user.loyalityId) {
  //     NotificationManager.success('Your code is valid', 'Success', 3000)
  //   } else {
  //     NotificationManager.error("Your code is invalid", 'Update Error', 3000)

  //   }
  // }
  handleCodeChange(e) {
    this.setState({ code: e.target.value })
  }
  handleLightClick(product) {
    let images = []
    product.product.images.forEach(image => {
      images.push({
        src: image
      })
    });
    this.setState({
      lightboxImages: images,
      lightboxIsOpen: true
    })
  }
  gotoPrev() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  clickThumbnail(index) {
    this.setState({ currentImage: index })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
      lightboxImages: [
        {
          src: ''
        }
      ]
    });
  }

  checkifEnoughStock = () => {
    let newStockCheck = false;
    this.state.cart.products.map((product, key) =>  {
      let stockCheck = this.hasEnoughStock(product);
      if(!stockCheck){
        this.setState({
          blockCheckout: true
        })
        newStockCheck = true;
      }
    })
    if(!newStockCheck){
      this.setState({
        blockCheckout: false
      })
    }
  }

  hasEnoughStock = (product) => {
    if(!product.product.stock){
      return false;
    } 
    return product.product.stock.find((stockItem)=>{
      return (stockItem.available >= product.quantity*product.product.boxSizeMeter) && (stockItem.price === product.tier)
    })
  }
  render() {
    const discount = this.state.user.verified ? 0.1 : 0
    return (
      <div>
        <Lightbox
          backdropClosesModal
          images={this.state.lightboxImages}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this.gotoPrev}
          onClickNext={this.gotoNext}
          preloadNextImage
          showThumbnails
          onClickThumbnail={this.clickThumbnail}
          onClose={this.closeLightbox}
        />
        <div className="cart">
          <div className="main-title">
            <h2 className="main-line-title">CART</h2>
          </div>
          {
            !this.state.ready ?
              (
                <div style={{ marginTop: 200, marginBottom: 200 }}>
                  <Spinner />
                </div>
              ) :
              (

                <div className="container">
                  {
                    this.state.cart.products.length === 0 ? (<h1 style={{ "color": "#333333", "font-family": "Baskerville", margin: "100px auto", width: "fit-content" }}>YOUR CART IS EMPTY</h1>) : (
                      <div className="cart__table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="cart__item">Item Name &amp; ID</th>
                              <th className="cart__img"> </th>
                              <th className="cart__color">Color</th>
                              <th className="cart__size">Size</th>
                              <th className="cart__quantiy-box">Quantity (box)</th>
                              <th className="cart__price"> price/box</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.cart.products.map((product, key) => (
                                <tr>
                                  <td>
                                    <div className="cart__item-holder">
                                      <h3>{product.product.name ? (product.product.name) : ("This product's model no longer exists in database")}</h3>
                                      <p>Product ID: {product.product.segmentId}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div onClick={() => this.handleLightClick(product)} className="cart__img-holder">
                                      <img src={product.product.images[0] ? (toCloudinary(product.product.images[0],null,300)) : (require("../../../img/white.png"))} />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart__color-holder">
                                      <h3>{product.product.color ? (product.product.color.name) : ("This product's color no longer exists in database")}</h3>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart__size-holder">
                                      <h3>{product.product.size ? (product.product.size.length + " x " + product.product.size.width) : ("This product's size no longer exists in database")}</h3>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart__quantity-holder">
                                      <div style={{ width: 100, display: 'inline-block' }}>
                                        <input className="quantity__btn form-group" onChange={(e) => this.changeQuantity(e, key)} min={1} type="number" value={product.quantity} />
                                        <a onClick={() => { this.removeFromCart(key) }} href="#"> remove item</a>
                                      </div>
                                      {
                                        this.hasEnoughStock(product) ? (null) : (
                                          <Tooltip
                                            arrow={15}
                                            background="#000"
                                            border="#000"
                                            color="#fff"
                                            content={'There is not enough stock for this product'}
                                            fadeDuration={400}
                                            fadeEasing="linear"
                                            fixed={false}
                                            fontFamily="inherit"
                                            fontSize="inherit"
                                            offset={0}
                                            padding={10}
                                            style={{ width: 460, position: 'absolute', right: -220, top: 8 }}
                                            placement="top"
                                            radius={0}
                                            zIndex={1}
                                          >
                                            <Glyphicon style={{ color: '#f0ad4e' }} glyph="warning-sign" />
                                          </Tooltip>
                                        )
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart__price-holder">
                                      <h3>{product.product.prices && product.product.prices[product.tier] ? (toPrice(toBoxPrice(product.product, product.product.prices[product.tier])) + " EGP") : ("Unknown Price")}</h3>
                                      <p>{product.product.boxSize ? (product.product.boxSize + " per box") : ("1 per box")}</p>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                        {/* <input id="code" value={this.state.code} onChange={this.handleCodeChange} className="cart__loyalty-input" placeholder="Loyalty Points / Promo Code" type="text" /> */}
                          <div className="cart-total" placeholder="Loyalty Points/Promo Code" type="text">
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                              <div>Subtotal</div>
                              <div>{toPrice(this.getTotalPrice())} EGP</div>
                            </div>
                          </div>
                          {discount > 0 && (
                            <React.Fragment>
                              <div className="cart-total" placeholder="Loyalty Points/Promo Code" type="text">
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}>
                                  <div>Loyalty Program Discount</div>
                                  <div style={{ color: 'rgb(212, 19, 13)' }}>-{toPrice(discount * this.getTotalPrice())} EGP</div>
                                </div>
                              </div>
                            
                              <div className="cart-total" placeholder="Loyalty Points/Promo Code" type="text">
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}>
                                  <div>Total</div>
                                  <div>{toPrice(this.getTotalPrice() - ( this.getTotalPrice() * discount))} EGP</div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        <br />
                        {/* <button onClick={this.checkCode} type="button" className="cart__btn-cheack btn btn-default">Check Code</button> */}
                        <div className="cart__continue">
                          <Link to="/shop"><button type="button" className="cart__btn-continue btn btn-default">Continue Shopping</button></Link>
                          <Link to="/shipping-information"><button type="button" className="cart__btn-payment btn btn-default" disabled={this.state.blockCheckout}>Checkout</button></Link>
                        </div>
                      </div>
                    )
                  }
                </div>
              )
          }
        </div>
        {/* Javascripts
          ================================================== */}
      </div>
    );
  }
}

export default Cart;