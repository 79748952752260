import React from 'react'
import {Modal, Grid, Col, Row} from 'react-bootstrap'

class Verify extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pin: ''
    }
  }
  render() {
    return (
      <Row>
        <Col md={6} mdOffset={2}>
          <Modal
            show={this.props.showModal}
            onHide={this.props.onModalHide}
            className={'verifyModal'}
          >
            <Modal.Header closeButton>
              <h3>{this.props.name}</h3>
            </Modal.Header>
            <Modal.Body>
              <Grid className="modal-price__holder">
                <Row>
                  <Col md={12} mdOffset={2}>
                    <span>
                      You Have received pin in phone +20********
                      {this.props.phone.substring(
                        this.props.phone.length - 3,
                        this.props.phone.length
                      )}
                    </span>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={8} mdOffset={2}>
                    <input
                      className="pinInput"
                      placeholder="please enter the received pin  "
                      type="text"
                      value={this.state.pin}
                      onChange={e => this.setState({pin: e.target.value})}
                    />{' '}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={8} mdOffset={2}>
                    <button
                      className="login-now__forgot-button"
                      onClick={()=>this.props.sendpin(this.props.phone)}
                    >
                      Resend pin Again
                    </button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={3} mdOffset={4}>
                    <button
                      class="creat-account__submit-btn btn btn-default"
                      onClick={() =>
                        this.props.handleVerifyPin(
                          this.state.pin,
                          this.props.phone
                        )
                      }
                    >
                      Send
                    </button>
                  </Col>
                </Row>
              </Grid>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    )
  }
}
export default Verify
