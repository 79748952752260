import axios from 'axios';
import config from '../config';
var qs = require('qs');


export function getAllObjects(objectName,query) {
    return axios.get(config.API_URL + objectName + '/',{
        params:query
    })
}

export function getWithQuery(objectName,url,query){
    const queryString = qs.stringify(query)
    return axios({
        method: 'GET',
        baseURL: config.API_URL+objectName+'/',
        url:url+"?"+queryString,    
    })
}
export function getSingleObject(objectName, object_id) {
    return axios({
        method: 'GET',
        baseURL: config.API_URL+objectName+'/'+object_id
    })
}

export function getObjectWithToken(objectName) {
    var options = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
    }
    return axios(config.API_URL+objectName+'/',options)
}

export function postObjectWithToken(objectName, body,progress) {
    var options = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        }
    }
    return axios.post(config.API_URL+objectName+'/',body, options)
}


export function postObject(objectName, body) {
    return axios.post(config.API_URL+objectName+ '/', body)
}

export function deleteObject(objectName, body) {
    return axios({
        method: 'DELETE',
        url:body._id,
        baseURL: config.API_URL+objectName+'/'
    })
}

export function updateObject(objectName, query, body) {
    body["_id"] = query._id
    return axios({
        method: 'PUT',
        url: config.API_URL+objectName,
        data: body
    })
}


export function updateObjectWithToken(objectName, body) {
    var headers = {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
    }
    return axios({
        method: 'PUT',
        url: config.API_URL+objectName,
        data: body,
        headers
    })
}

export function closeNotification(context) {
    setTimeout(function () {
        context.setState({
            isShowing: false,
        })
    }, 3000)
}

export function handleCatch(err,context) {
    context.setState({
        message: err.message,
        isShowing: true,
        status: 'ERROR!',
        alert: 'danger'
    })
}

export function toPrice(price) {
    if(!price && price !== 0) return null
    return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function getDesign(segmentId) {
    const designKey = segmentId?(segmentId.split(".")[1]):('')
    return (config.designs[designKey]?(config.designs[designKey]):(''))
}
// export function toBoxPrice(product,price) {
//     let boxSize = 1
//     if(product.boxSize){
//         boxSize = product.boxSize;
//     }
//     if(boxSize === 1 ){
//         return price;
//     }
//     return price * (product.size.length/100) * (product.size.width/100) * boxSize
// }

export function toBoxPrice(product,price) {
    let boxSize = 1
    if(product.boxSizeMeter){
        boxSize = product.boxSizeMeter;
    }
    if(boxSize === 1 ){
        return price;
    }
    return price * boxSize
}

export function toCloudinary(image,height,width){
    if(!image) return null
    let transformation = ""
    if(height){
        transformation += "h_"+height+(width?(","):(""))
    }
    if(width){
        transformation += "w_"+width
    } else width = 1410
    return `https://image.devteam9260.workers.dev/?image=${image}${width ? `&width=${width}` : ''}`
}