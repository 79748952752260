import React from 'react'
import './Login.css'
import 'react-notifications/lib/notifications.css'
import config from '../../config'
import {toast, Flip} from 'react-toastify'
import {getWithQuery, postObject} from '../../services/CommonServices'
import axios from 'axios'
import {Link} from 'react-router-dom'
import VerifyModal from './Modals/verify'

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  transition: Flip,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  className: 'sub-toast'
}
class Login extends React.Component {
  constructor() {
    super()
    this.handleSignup = this.handleSignup.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.state = {
      phoneSignup: '',
      password1: '',
      password2: '',
      phoneLogin: '',
      passwordLogin: '',
      verifyModal: false,
      modalName: '',
      phone: '',
      email: '',
      name: '',
    }
  }
  componentDidMount() {
    window.scroll(0, 0)
  }
  handleSignup(e) {
    e.preventDefault()
    if (!this.state.phoneSignup) {
      toast.error('Phone number is required', toastSettings)
      return
    }
    if (!this.state.email) {
      toast.error('Email is required', toastSettings)
      return
    }
    if (!this.state.name) {
      toast.error('Full Name is required', toastSettings)
      return
    }
    if (!this.state.password1) {
      toast.error('Password is required', toastSettings)
      return
    }
    if (this.state.password1.length < 6) {
      toast.error(
        'Password needs to be at least 6 charachters long',
        toastSettings
      )
      return
    }
    if (!this.state.password2) {
      toast.error('Please retype the password', toastSettings)
      return
    }
    if (!(this.state.password1 === this.state.password2)) {
      toast.error('Passwords do not match', toastSettings)
      return
    }
    this.setState({modalName: 'SIGN UP'})
    const context = this
    this.setState({phone: this.state.phoneSignup})
    getWithQuery('users', 'check', {phone_number: this.state.phoneSignup}).then(
      response => {
        if (response.data === false) {
          postObject('users/tempUser', {
            password: context.state.password1,
            phone_number: context.state.phoneSignup,
            email: context.state.email,
            name: context.state.name
          }).then(res => {
            if (res.data.success) {
              if (response.data.token) {
                localStorage.setItem('token', response.data.token)
                toast.success('Welcome to Gemma', toastSettings)
                this.props.history.push({
                  pathname: '/account-settings'
                })
              }
            } else {
              toast.error(res.data.message, toastSettings)
            }
          })
          // window.smsLogin(context.state.phoneSignup, context.state.password1, axios, config.API_URL, context.props.history)
        } else {
          toast.error('This phone number already exists', toastSettings)
        }
      }
    )
  }
  handleLogin(e) {
    e.preventDefault()
    if (!this.state.phoneLogin) {
      toast.error('Phone number is required', toastSettings)
      return
    }
    if (!this.state.passwordLogin) {
      toast.error('Password is required', toastSettings)
      return
    }

    postObject('users/login', {
      phone_number: this.state.phoneLogin,
      password: this.state.passwordLogin
    }).then(response => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
        this.props.onChangeCart()
        toast.success('Welcome to Gemma', toastSettings)
        this.props.history.push({
          pathname: '/account-settings'
        })
      } else {
        toast.error('Incorrect login credentials', toastSettings)
      }
    })
  }
  changeValue(event, key) {
    this.setState({[key]: event.target.value})
  }
  handleVerifyPin = (pin, phone) => {
    postObject('users/verifypin', {
      pin: pin,
      phone_number: phone
    })
      .then(response => {
        if (response.data.success) {
          this.setState({verifyModal: false})
          if (this.state.modalName === 'Forget Password') {
            this.props.history.push({
              pathname: '/forget-password',
              state: {phone: this.state.phone}
            })
          } else {
            if (response.data.token) {
              localStorage.setItem('token', response.data.token)
              toast.success('Welcome to Gemma', toastSettings)
              this.props.history.push({
                pathname: '/account-settings'
              })
            }
          }
        } else {
          toast.error(response.data.message, toastSettings)
        }
      })
      .catch(error => toast.error(error.data.message, toastSettings))
  }
  handleForgetPassword = e => {
    e.preventDefault()
    if (!this.state.phoneLogin) {
      toast.error('Phone number is required', toastSettings)
      return
    }
    getWithQuery('users', 'check', {phone_number: this.state.phoneLogin}).then(
      response => {
        if (response.data === true) {
          postObject('users/forgetPassword', {
            phone_number: this.state.phoneLogin
          })
            .then(res => {
              this.setState({
                verifyModal: true,
                modalName: 'Forget Password',
                phone: this.state.phoneLogin
              })
            })
            .catch(err => {
              toast.error(err.data.message, toastSettings)
            })
        } else {
          toast.error('This phone number not exists', toastSettings)
        }
      }
    )
  }
  onModalHide = () => {
    this.setState({verifyModal: false})
  }
  handleResendPin = phone => {
    postObject('users/resend_pin', {
      phone_number: phone
    }).then(res => {
      if (res.data.success) {
        toast.success(res.data.message, toastSettings)
      } else {
        toast.error(res.data.message, toastSettings)
      }
    })
  }
  render() {
    return (
      <div className="Login">
        <div className="login__title col-md-12">
          <h2 className="main-line-title">REGISTER/LOGIN</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 ">
              <div className="Login__caption">
                <p>
                  Welcome to GEMMA, enter your info below to sign up for an
                  account and enjoy shopping online, designing your own spaces,
                  calculating everything you need and much more.{' '}
                </p>
              </div>
              <form onSubmit={this.handleSignup}>
                <div className="col-md-6 col-sm-6">
                  <div className="creat-account_holder">
                    <div className="creat-account__caption">
                      <p> FIRST TIME?</p>
                      <h3> CREATE A NEW ACCOUNT </h3>
                    </div>
                    <div className="creat-account__btn-holder">
                      <p>
                        {' '}
                        Full Name <sup>*</sup>{' '}
                      </p>
                      <input
                        className="creat-account__btn-name creat__placeholder"
                        placeholder="-----"
                        type="text"
                        value={this.state.name}
                        onChange={e => {
                          this.changeValue(e, 'name')
                        }}
                      />
                    </div>
                    <div className="creat-account__btn-holder">
                      <p>
                        {' '}
                        Phone Number <sup>*</sup>{' '}
                      </p>
                      <input
                        className="creat-account__btn-email creat__placeholder"
                        placeholder="-----"
                        type="text"
                        value={this.state.phoneSignup}
                        onChange={e => {
                          this.changeValue(e, 'phoneSignup')
                        }}
                      />
                    </div>
                    <div className="creat-account__btn-holder">
                      <p>
                        {' '}
                        Email <sup>*</sup>{' '}
                      </p>
                      <input
                        className="creat-account__btn-email creat__placeholder"
                        placeholder="-----"
                        type="email"
                        value={this.state.email}
                        onChange={e => {
                          this.changeValue(e, 'email')
                        }}
                      />
                    </div>
                    <div className="creat-account__btn-holder">
                      <p>
                        Create a Password <sup>*</sup>{' '}
                      </p>
                      <input
                        className="creat-account__btn-password creat__placeholder"
                        placeholder="Create a new password"
                        type="password"
                        value={this.state.password1}
                        onChange={e => this.changeValue(e, 'password1')}
                      />
                    </div>
                    <div className="creat-account__btn-holder">
                      <p>
                        {' '}
                        Confirm Your Password <sup>*</sup>{' '}
                      </p>
                      <input
                        className="creat-account__btn-repassword creat__placeholder"
                        placeholder="Re-type the same password"
                        type="password"
                        value={this.state.password2}
                        onChange={e => this.changeValue(e, 'password2')}
                      />
                    </div>
                    <div className="creat-account__submit-holder">
                      <button
                        type="submit"
                        class="creat-account__submit-btn btn btn-default"
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <hr className="hr-login" />
              <form onSubmit={this.handleLogin}>
                <div className="col-md-6 col-sm-6">
                  <div className="login-now_holder">
                    <div className="login-now__caption">
                      <p> WELCOME BACK,</p>
                      <h3> LOGIN HERE </h3>
                    </div>
                    <div className="login-now__btn-holder">
                      <p>
                        {' '}
                        Phone Number <sup>*</sup>{' '}
                      </p>
                      <input
                        className="login-now__btn-email creat__placeholder"
                        placeholder="-----"
                        type="text"
                        value={this.state.phoneLogin}
                        onChange={e => {
                          this.changeValue(e, 'phoneLogin')
                        }}
                      />
                    </div>
                    <div className="login-now__btn-holder">
                      <p>
                        Password <sup>*</sup>{' '}
                      </p>
                      <input
                        className="login-now__btn-password creat__placeholder"
                        placeholder="password"
                        type="password"
                        value={this.state.passwordLogin}
                        onChange={e => this.changeValue(e, 'passwordLogin')}
                      />
                    </div>
                    <div className="login-now__forgot-password">
                      <button
                        // to="/forget-password"
                        className="login-now__forgot-button"
                        onClick={e => this.handleForgetPassword(e)}
                      >
                        Forgot your password?
                      </button>
                    </div>
                    <div className="login-now__submit-holder">
                      <button
                        type="submit"
                        class="login-now__submit-btn btn btn-default"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <VerifyModal
                sendpin={this.handleResendPin}
                showModal={this.state.verifyModal}
                onModalHide={this.onModalHide}
                name={this.state.modalName}
                phone={this.state.phone}
                handleVerifyPin={this.handleVerifyPin}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
