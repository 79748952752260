import React from "react";
import { Link } from "react-router-dom";
import "./single-product-grid-component.css";
import "../../../css/material-icons.css";
import { toCloudinary } from "../../../services/CommonServices";
class gridProduct extends React.PureComponent {
  handleMainClick(model) {
    let images = [];
    images.push(...model.images);
    this.props.setLightboxImages(images);
  }
  render() {
    return (
      <div id="grid__product" className="grid-product">
        <div className="row">
          {this.props.models.map((model, key) => (
            <div className="grid-product-wrapper__holder col-md-6 col-sm-6 col-lg-6 col-xl-4">
              <div className="grid-product-wrapper box">
                <div class="grid-product-custome col-md-9">
                  <div className="grid-product__main-holder">
                    <div className="grid-product__main-photo">
                      <img
                        style={{
                          cursor: "pointer"
                        }}
                        id={"myImage" + key}
                        src={
                          model.images[0]
                            ? toCloudinary(model.images[0], 250, 400)
                            : ""
                        }
                        onError={e => {
                          e.target.src = this.props.errorImage;
                        }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/single-product/" + model._id
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix grid__product__padding col-md-3">
                  <div className="grid-product__colors">
                    {model.images[0] ? (
                      <div className="grid-product__colors-img">
                        <img
                          className="thumb-img"
                          src={toCloudinary(model.images[0], 250, 400)}
                          onError={e => {
                            e.target.src = this.props.errorImage;
                          }}
                          onClick={() => {
                            document.getElementById(
                              "myImage" + key
                            ).src = toCloudinary(model.images[0], 250, 400);
                          }}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                    {model.images[1] ? (
                      <div className="grid-product__colors-img">
                        <img
                          className="thumb-img-no-padding-right"
                          src={toCloudinary(model.images[1], 250, 400)}
                          onError={e => {
                            e.target.src = this.props.errorImage;
                          }}
                          onClick={() => {
                            document.getElementById(
                              "myImage" + key
                            ).src = toCloudinary(model.images[1], 250, 400);
                          }}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                    {model.images[2] ? (
                      <div
                        style={{ marginBottom: 0 }}
                        className="grid-product__colors-img"
                      >
                        <img
                          className="thumb-img-no-padding-right"
                          src={toCloudinary(model.images[2], 250, 400)}
                          onError={e => {
                            e.target.src = this.props.errorImage;
                          }}
                          onClick={() => {
                            document.getElementById(
                              "myImage" + key
                            ).src = toCloudinary(model.images[2], 250, 400);
                          }}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <hr className="grid-product__hr" />
                    <div className="grid-product__info_container">
                      <div className="grid-product__info col-md-9 col-xs-9">
                        <div className="grid-product__name">
                          <Link to={"/single-product/" + model._id}>
                            <h4> {model.name} </h4>
                          </Link>
                        </div>
                        <div className="grid-product__id-holder">
                          <p>
                            Product ID:{" "}
                            <span className="grid-produc__id">
                              {model.name +
                                " " +
                                model.size.length +
                                "x" +
                                model.size.width}{" "}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="grid-product__range-holder">
                          <div className="col-md-5 no-padding">
                            <div className="grid-product__range">
                              <p>from </p>
                              <div className="grid-product__range-price">
                                <p>{model.minPrice + " EGP"}</p>
                              </div>
                              <div className="grid-product__range-currency">
                                <p> per box </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid-product__range-holder" />
                      </div>
                      <div className="col-md-3 col-xs-3 no-padding">
                        <div className="grid-product__icons">
                          <div className="grid-product__icons-wrapper">
                            <div className="grid-product__icons-menu">
                              <a
                                onClick={() => this.handleMainClick(model)}
                                className="grid-product__icons-link"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Hooray!"
                              >
                                <i
                                  class="material-icons-new grid-product__fav-icon sharp-photo_library icon-white"
                                  aria-hidden="true"
                                >
                                </i>
                              </a>
                              <a
                                onClick={e =>
                                  this.props.addModelToWishlist(
                                    e,
                                    model._id,
                                    key
                                  )
                                }
                                className="grid-product__icons-link"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Hooray!"
                              >
                                <i style={{
                                  marginLeft: 4,
                                  color: this.props.starColor[key]
                                }} className="material-icons list-product__fav-icon ">
                                  star_rate
                                </i>
                              </a>
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  this.props.history.push({
                                    pathname: "/single-product/" + model._id
                                  });
                                }}
                                className="grid-product__icons-link"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Hooray!"
                              >
                                <i className="material-icons-new icon-white grid-product__compare-icon sharp-add_shopping_cart">
                                </i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {model.newCollection ? (
                  <div class="ribbon list">
                    <span>New</span>
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default gridProduct;
