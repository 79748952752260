import React from "react";
import './Single-product-list-component.css'
import {toCloudinary} from '../../../services/CommonServices'
import {Link} from 'react-router-dom'
class ListProduct extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {

    };
  }
  handleMainClick(model){
    let images = []
    images.push(...model.images)
    this.props.setLightboxImages(images);
  }
  render() {
    return (
      <div id="list__product" className="list-product">
        {
          this.props.models.map((model, key) => (
              <div className="list-product-wrapper box">
              {model.newCollection?(<div class="ribbon"><span>New</span></div>):('')}
                <div className="col-sm-4 col-md-4 no-padding">
                  <div className="list-product__main-holder">
                    <div className="list-product__main-photo">
                      <img alt=""
                        data-toggle="modal"
                        data-target="#myModal"
                        style={{
                          cursor: "pointer"
                        }}
                        id={"myImage" + key}
                        src={
                          model.images[0] ? (toCloudinary(model.images[0],246)) : (require("../../../img/download.png"))
                        }
                        onError={(e) => { e.target.src = this.props.errorImage }}
                        onClick={() => this.props.history.push({
                          pathname: '/single-product/' + model._id
                        }
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12 col-sm-5">
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-12">
                      <Link to={'/single-product/' + model._id}>
                      <div className="list-product__name">
                        <h4>
                          {model.name}
                        </h4>
                      </div>
                        </Link>
                    </div>
                  </div>
                  <div className="list-product__range-holder">
                    <div className="col-md-4">
                      <div className="list-product__range secondry-font">
                        <p>from </p>
                        <div className="list-product__range-price secondry-font">
                          <p> {
                            model.minPrice
                          } </p>
                        </div>
                        <div className="list-product__range-currency secondry-font">
                          <p> egp per box </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-product__icons">
                    <div className="list-product__icons-wrapper col-md-12">
                      <div className="list-product__icons-menu">

                            <a onClick={()=>this.handleMainClick(model)}
                                className="list-product__icons-link"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Hooray!"
                              >
                                {/* <i className="material-icons grid-product__fav-icon">
                                photo_library

                          </i> */}
                          <i class="list-product__fav-icon fa fa-picture-o" aria-hidden="true"></i>

                              </a>
                              
                        <a onClick={(e) => this.props.addModelToWishlist(e, model._id,key)}
                          className="list-product__icons-link"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title
                          data-original-title="Hooray!">
                          <i style={{ color: this.props.starColor[key] }} className="material-icons list-product__fav-icon ">
                            star_rate
                          </i>
                        </a>
                        <a
                          onClick={(e) =>{e.preventDefault(); this.props.history.push({
                            pathname: '/single-product/' + model._id
                          }
                          )}}
                          className="list-product__icons-link"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title
                          data-original-title="Hooray!"
                        >
                          <i className="material-icons list-product__compare-icon">
                            add_shopping_cart
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="wishlist__id-holder">
                      <p>
                        Product ID:{" "}
                        <span className="grid-produc__id">{model.name + "" + model.size.length + "X" + model.size.width} </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="list-product__colors">
                  <div className="col-md-2 col-sm-3">
                    <div className="list-product__colors-img">
                      {
                        model.images[0] ? (
                          <div className="grid-product__colors-img">
                            <img alt=""
                              className="thumb-img"
                              src={
                                (toCloudinary(model.images[0],246)) 
                              }
                              onError={(e) => { e.target.src = this.props.errorImage }}
                              onClick={() => { document.getElementById('myImage' + key).src = (toCloudinary(model.images[0],246))  }}
                            />
                          </div>
                        ) : ('')
                      }
                    </div>
                    <div className="list-product__colors-img">
                      {
                        model.images[1] ? (
                          <div className="grid-product__colors-img">
                            <img alt=""
                              className="thumb-img"
                              src={
                                (toCloudinary(model.images[1],246)) 
                              }
                              onError={(e) => { e.target.src = this.props.errorImage }}
                              onClick={() => { document.getElementById('myImage' + key).src = (toCloudinary(model.images[1],246))  }}
                            />
                          </div>
                        ) : ('')
                      }
                    </div>
                    <div className="list-product__colors-img">
                      {
                        model.images[2] ? (
                          <div className="grid-product__colors-img">
                            <img alt=""
                              className="thumb-img"
                              src={
                                (toCloudinary(model.images[2],246)) 
                              }
                              onError={(e) => { e.target.src = this.props.errorImage }}
                              onClick={() => { document.getElementById('myImage' + key).src = (toCloudinary(model.images[2],246))  }}
                            />
                          </div>
                        ) : ('')
                      }
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
          ))
        }

      </div>
    );
  }
}

export default ListProduct;
