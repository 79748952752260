import React from "react";
import './Footer.css'
import { Link } from "react-router-dom"
import { getAllObjects } from "../../../services/CommonServices";
import config from '../../../config'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { toast, Flip } from 'react-toastify';
import MobileStoreButton from 'react-mobile-store-button';

class Footer extends React.Component {
  state = {
    gallery_url: "",
    email: ''
  }
  componentDidMount = () => {
    getAllObjects("cms")
      .then(res => {
        this.setState({ gallery_url: res.data[0].gallery_url, wall_url: res.data[0].wall_url, floor_url: res.data[0].floor_url, porcelain_url: res.data[0].porcelain_url });
      });

  }
  handleMessage(status, message) {
    if (!message && !status) {
      return ('')
    }
    if (status === "sending") {
      return ('Sending...')
    }
    if (status === "error") {
      if (message.includes('already')) {
        return ('You are already signed up to our newsletter')
      }
      return (message)
    }
    return ('Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you.')
  }
  render() {
    return (
      <div>
        <section className="footer clearfix">
          <div className="footer__holder container">
            <div className="footer__newsletter">
              <h2> Subscribe to our newsletter </h2>
              <MailchimpSubscribe
                url={config.MC_URL}
                render={({ subscribe, status, message }) => (
                  <form onSubmit={(e) => {
                    e.preventDefault()
                    toast.dismiss()
                    this.toastId = toast('Sending...', {
                      position: "top-right",
                      autoClose: 8000,
                      transition: Flip,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      className: "sub-toast"
                    });
                    subscribe(
                      {
                        EMAIL: this.state.email
                      }
                    )
                  }} className="footer__form" name="sign-up">
                    {toast.update(this.toastId, {
                      render: this.handleMessage(status, message),
                    })}
                    <div>
                      <input onChange={(e) => { this.setState({ email: e.target.value }) }} type="email" className="footer__newsletter-input" id="email" name="email" placeholder="email@example.com" required />
                      <button className="main-button collection__btn btn btn-default subscribe-btn" type="submit">SUBMIT</button>
                    </div>
                  </form>
                )}
              />
              <hr className="footer__line" />
              <div className="footer__links">
                <div className="col-md-2 col-xs-6">
                  <div className="footer__title">
                    <h4> Main</h4>
                  </div>
                  <ul className="footer__links-menu">
                    <li className="footer__item"> <Link to="/">Home </Link></li>
                    <li className="footer__item"> <Link to="/shop">Products </Link> </li>
                    <li className="footer__item"> <Link to="/about">About Us</Link> </li>
                    <li className="footer__item"> <Link to="/contact">Contact Us </Link> </li>
                    {/* <li className="footer__item"> <a>Projects</a> </li> */}
                    <li className="footer__item"> <Link to="/design-your-own">Design Your Own </Link> </li>
                    <li className="footer__item"> <Link to="/catalogues"> <a href={this.state.floor_url}  > Catalogues </a></Link></li>
                  </ul>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className="footer__title">
                    <h4>customer service</h4>
                  </div>
                  <ul className="footer__links-menu">

                    <li className="footer__item"> <Link to="/loyalty-program">Loyalty Program </Link> </li>
                    {/* <li className="footer__item"> <a>Ticket Support</a> </li> */}
                    {/* <li className="footer__item"> <a>Youtube Library</a> </li> */}
                    <li className="footer__item"> <Link to="/contact/distributors">Distributors </Link></li>
                    <li className="footer__item"> <Link to="/contact/showroom-locator">Showroom Locator </Link> </li>
                    <li className="footer__item"> <Link to="/investor-relations">Investor Relations </Link> </li>
                    {/* <li className="footer__item"> <Link to="/financial-reports"> Financial Reports </Link> </li> */}
                    <li className="footer__item"> <a href={this.state.gallery_url}  > Gallery </a></li>
                    <li className="footer__item"> <Link to="/terms"> Terms & Conditions </Link></li>
                  </ul>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="footer__title contact-footer">
                    <h4> contact</h4>
                  </div>
                  <ul className="footer__links-menu">
                    <li className="footer__location">
                      <p>Head Office :<br />25 Cairo-Alexandria Dessert Road Remaya Square Cairo Egypt</p>
                    </li>
                    <li style={{ fontSize: 24 }} className="footer__phone"> Hotline: 16757</li>
                    <li className="footer__phone"> Tel: + (202) 3377 8857/8/9</li>
                    <li className="footer__fax">Fax: +(202) 3377 8852</li>
                  </ul>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="row" style={{ marginBottom: 20 }} className="footer__logo">
                    <a><img src={require('../../../img/logo-footer.png')} alt="gemma-logo-footer" /> </a>
                  </div>
                  <div className="row footer-icons">
                    <div className="col-xs-6">
                      <MobileStoreButton
                        store="ios"
                        url={"https://itunes.apple.com/us/app/gemma-ceramic-porcelain/id1448843043?mt=8"}
                        linkProps={{ title: 'iOS Store Button' }}
                        width={125}
                        height={50}
                      />
                    </div>
                    <div className="col-xs-6">
                      <MobileStoreButton
                        store="android"
                        url={"https://play.google.com/store/apps/details?id=com.gemma_app"}
                        linkProps={{ title: 'iOS Store Button' }}
                        width={125}
                        height={50}
                      />
                    </div>
                  </div>

                  {/* <div style={{marginBottom:20}} className="footer__logo">
                      <a><img style={{width:65}} src={require('../../../img/Visa.gif')} alt="gemma-logo-footer" /> </a>
                    </div>  
                    <div style={{marginBottom:20}} className="footer__logo">
                      <a><img style={{width:65}} src={require('../../../img/mc.png')} alt="gemma-logo-footer" /> </a>
                    </div>  
                    <div style={{marginBottom:20}} className="footer__logo">
                      <a><img style={{width:165}} src={require('../../../img/aaib.png')} alt="gemma-logo-footer" /> </a>
                    </div>                */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Section footer  */}
      </div>
    );
  }
}

export default Footer;