import React from "react";
import './MapStyle.css'
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap"

class showMap extends React.PureComponent {
  render() {
    return (
      <div className="col-md-12">
        <div className="row">
          <div style={{float:'right'}}>
            <div className="btn-group">
              <ButtonToolbar>
                <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                  <ToggleButton onClick={() => this.props.changeView(false)}
                    value={1}>List View </ToggleButton>
                  <ToggleButton onClick={() => this.props.changeView(true)}
                    value={2}>Map View</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default showMap;