import React, { Component } from 'react';

class LoyaltyFaq extends Component {
    state = {  }
    render() { 
        return ( <React.Fragment>
            <div id="fa_q" className="faq" data-background="#3498db">
            <div className="question_answer">
            <h3 className="question">How do I collect Rewards Points for every purchase transaction?</h3>
            <p className="answer">You will earn 1 Reward Point for every purchase value of EGP 40 of 1st Choice and EGP 80 of 2nd Choice. For example if you spent EGP 4000 of 1st choice you will get 100 points on GEMMA Loyalty Card. Redemption of points starts after reaching ……….. points.</p>
            <h3 className="question">Will I earn Rewards Points for all my GEMMA purchases?</h3>
            <p className="answer">You will earn Rewards points for all purchase transactions with no certain limit on spending for each purchase transaction.</p>
            <h3 className="question">How do I keep track of the points I have collected?</h3>
            <p className="answer">By visiting <a href="https://www.gemma.com.eg" > www.gemma.com.eg </a>  or by calling our Hotline 19757 between 8:00 am and 5:00 pm.</p>
            <h3 className="question">How will Rewards be reflected in my GEMMA statement?</h3>
            <p className="answer">A Summary box of Points will appear on your account on <a href="https://www.gemma.com.eg" > www.gemma.com.eg </a> . The details in the Rewards Points box are as follows: 
            <ul><li>- Opening Balance of points: Rewards Points (if any) carried forward from your previous purchases.</li> <li>
                - Rewards Points earned: Rewards points earned from the current purchase transaction.</li> <li>
                - Closing Balance: Total number of Rewards Points (if any) carried forwards to your next statement.</li></ul></p>
            <h3 className="question">How can I redeem Rewards Points?</h3>
            <p className="answer">Rewards Points can be redeemed by wither logging to <a href="https://www.gemma.com.eg" > www.gemma.com.eg </a>  or by calling our Hotline 19757 between 8:00 am and 5:00 pm.</p>
            <h3 className="question">When can I redeem Rewards Points?</h3>
            <p className="answer">You can start redeeming your Rewards Points whenever you wish. Please refer to the Rewards listed on <a href="https://www.gemma.com.eg" > www.gemma.com.eg </a>   for the number Rewards Points required for the Reward of your choice.</p>
            <h3 className="question">How will I receive and use my GEMMA Reward Points Card?</h3>
            <p className="answer">Your GEMMA Ponts Card will be mailed to you by courier.</p>
            <h3 className="question">Who can redeem Rewards Points?</h3>
            <p className="answer">Only the Primary Cardholder can request for the redemption of Rewards Points.</p>
            <h3 className="question">Do Rewards Points have an expiry date period?</h3>
            <p className="answer">No You can continue earning and redeeming Rewards Points as long as you have valid GEMMA Rewards points Card.</p>
            <h3 className="question">Can I transfer my Rewards Points to someone else?</h3>
            <p className="answer">Rewards Points can not be transferred to anyone else.</p>
            <h3 className="question">Can I exchange my Rewards Points for Cash?</h3>
            <p className="answer">Rewards Points can not be exchanged for Cash. However, its can be deducted from your next purchase.</p>
            <h3 className="question">How can I collect my Reward?</h3>
            <p className="answer">Once you redeem your reward from <a href="https://www.gemma.com.eg/" > www.gemma.com.eg </a> you will receive a call from GEMMA agent and will receive your reward within 7 working days.</p>
            </div>
            </div>
        </React.Fragment> );
    }
}
 
export default LoyaltyFaq;