import React from 'react';
import { getAllObjects } from '../../../services/CommonServices'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapContainer extends React.Component {
  constructor(props) {
    super(props)
    this.onMapClicked = this.onMapClicked.bind(this)
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.state = {
      lat: 30.06263,
      long: 31.24967,
      result: [],
      showingInfoWindow: false,
      activeMarker: {},
      activeContact: {
        contact:[]
      },
      selectedPlace: {}
      // objecttype:this.props.location.state.objecttype
    }
  }
  componentDidMount() {
    var outsideThis = this;
    getAllObjects(this.props.objecttype)
      .then(function (response) {
        outsideThis.setState({ result: response.data })
      })
      .catch(function (err) {
        outsideThis.setState({ result: [{ name: "ERROR" }] })
      })


    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        long: position.coords.longitude,
        lat: position.coords.latitude
      })
    });
  }
  onMarkerClick = (props, marker, contact) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      activeContact: contact
    });
  }
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        activeContact: null,
      })
    }
  };
  render() {
    return (
      <div className="map-content">
        <Map className="map-data"
          initialCenter={{
            lat: this.state.lat,
            lng: this.state.long
          }}
          onClick={this.onMapClicked}
          google={this.props.google}
          zoom={10}>
          {/* <Marker onClick={this.onMarkerClick}
            position={{
              lat: this.state.lat,
              lng: this.state.long
            }}
            icon={{
              url: require("../../../../src/img/marker.png"),
              anchor: new window.google.maps.Point(32, 32),
              scaledSize: new window.google.maps.Size(45, 45)
            }}
            title="Your Location"

          /> */}
          {
            this.state.result.map((obj, key) => (
              <Marker onClick={(props, marker) => this.onMarkerClick(props, marker, obj)}
                position={{
                  lat: obj.latitude,
                  lng: obj.longitude
                }}
                icon={{
                  url: require("../../../../src/img/marker.png"),
                  anchor: new window.google.maps.Point(32, 32),
                  scaledSize: new window.google.maps.Size(45, 45)
                }}
                title={obj.name}

              />
            ))
          }


          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onInfoWindowClose}>

            <div class="map-holder-content">
            {
            this.state.activeContact?(
              <React.Fragment>
            <h1>{this.state.activeContact.name}</h1>
            <h1>{this.state.activeContact.city}</h1>
            <h1>{this.state.activeContact.address}</h1>
            
              {
                this.state.activeContact.contact.map((contact, key) => (
                 this.props.objecttype === "distributors" ? (
                    <h1>{contact.contactType + ":" + contact.number}</h1>) : (<h1>{contact.contactType + ":" + contact.contactValue}</h1>)
                ))
              }
              </React.Fragment>
            ):("")
            }
            </div>
          </InfoWindow>



        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: ("AIzaSyD2lEwcVVq92D9gqq0R5SgEERIf3_HKhlY")
})(MapContainer)
