import React from "react";
import './HeaderMenu.css'
import '../../../css/material-icons.css'
import { NavLink } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import NotificationBadge from 'react-notification-badge';
import { Link } from 'react-router-dom'

class HeaderMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      position: "inherit"
    }
  }
  componentWillUpdate() {
    if (window.location.pathname === "/" && this.state.position !== "absolute") {
      this.setState({ position: "absolute" })
    }
    if (window.location.pathname !== "/" && this.state.position !== "inherit") {
      this.setState({ position: "inherit" })
    }
  }
  componentWillMount() {
    if (window.location.pathname === "/" && this.state.position !== "absolute") {
      this.setState({ position: "absolute" })
    }
    if (window.location.pathname !== "/" && this.state.position !== "inherit") {
      this.setState({ position: "inherit" })
    }
  }

  changeValue(event, key) {
    this.setState({ [key]: event.target.value })
  }

  render() {

    return (
      <div>
        <section className="header-menu">
          <nav style={{ position: this.state.position }} className="header-menu__header-nav navbar navbar-default">
            <div className="header-menu__navbar-header">

            </div>
            <a className="header-menu__logo navbar-brand">
              <NavLink to="/"><img alt="logo" src={require('../../../img/logo.png')} /></NavLink>
            </a>

            <div className="header-menu__holder container-fluid">
              {/* Brand and toggle get grouped for better mobile display */}

              {/* Collect the nav links, forms, and other content for toggling */}
              <div className="header-menu__collapse collapse navbar-collapse" id="bs-slide-dropdown">
                <ul className="header-menu__social-media nav navbar-nav">
                </ul>
                <ul className="header-menu__important-links nav navbar-nav navbar-right">
                  <li><NavLink exact activeClassName="activeLine" to="/">Home</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/shop">Products</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/catalogues">Catalogues</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/design-your-own">Design Your Own</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/show-rooms">Online showrooms</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/projects">Projects</NavLink></li>
                  <li><NavLink exact activeClassName="activeLine" to="/contact">Contact Us</NavLink></li>


                </ul>
              </div>



              {/* /.navbar-collapse */}
              <div className="header-mobile_holder">

                <Menu isOpen={this.state.burgerOpen} className="header-mobile" pageWrapId={"elastic"} outerContainerId={"elastic"}>

                  <div className="floating-header__mobile">
                    <ul className="floating-header__important-links nav navbar-nav navbar-right">

                      <li onClick={() => { this.setState({ burgerOpen: false }) }} className="floating-header__icon-favicon"> <Link to="/wishlist-main"><i class="fa fa-star"></i></Link></li>
                      <li onClick={() => { this.setState({ burgerOpen: false }) }} className="floating-header__icon-favicon"> 
                        <Link to="/account-settings"><i class="fa fa-user"></i></Link>
                      </li>
                      <li onClick={() => { this.setState({ burgerOpen: false }) }} className="floating-header__icon-favicon">
                        <NotificationBadge className="cart_badge" duration={0.7} style={{ marginRight: 2 }} count={this.props.cartLength} effect={[null, null, { top: '-5px', zIndex: 1, "padding-right": 6, "padding-left": 6 }, { top: '0px', zIndex: 1, "padding-right": 6, "padding-left": 6 }]} />
                        <Link to="/cart">
                            <i class="fa fa-shopping-cart" />
                        </Link>

                      </li>
                    </ul>
                      <ul className ="floating-header__important-links nav navbar-nav navbar-right">

                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink className="custome" exact activeClassName="activeLine" to="/">Home</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/shop">Products</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/catalogues">Catalogues</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/design-your-own">Design Your Own</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/show-rooms">Online showrooms</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/projects">Projects</NavLink></li>
                      <li style={{width:'fit-content'}} onClick={() => { this.setState({ burgerOpen: false }) }}><NavLink exact className="custome" activeClassName="activeLine" to="/contact">Contact Us</NavLink></li>

                      </ul>



                      <div className="login-now_holder login-bg">
                        <div className="col-md-12 ">

                          <div className="login-now__caption">
                            <li onClick={() => { this.setState({ burgerOpen: false }) }}><Link exact activeClassName="activeLine" to="/Register">Login (CLICK HERE TO REGISTER )</Link></li>


                          </div>
                          <div className="login-now__btn-holder">
                            <p> Phone Number <sup>*</sup>{" "} </p>
                            <input
                              className="login-now__btn-email creat__placeholder"
                              placeholder="-----"
                              type="text"
                              value={this.state.phoneLogin}
                              onChange={(e) => { this.changeValue(e, "phoneLogin") }}
                            />
                          </div>
                          <div className="login-now__btn-holder">
                            <p>
                              Password <sup>*</sup>{" "}
                            </p>
                            <input
                              className="login-now__btn-password creat__placeholder"
                              placeholder="password"
                              type="password"
                              value={this.state.passwordLogin}
                              onChange={(e) => this.changeValue(e, "passwordLogin")}
                            />
                          </div>
                          <div className="login-now__forgot-password">
                            <a class="login-now__forgot-link" >
                              Forgot your password?{" "}
                            </a>
                          </div>
                          <div className="login-now__submit-holder">
                            <button
                              type="button"
                              class="login-now__submit-btn btn btn-default"
                              onClick={this.handleLogin}
                            >
                              Login
                    </button>
                          </div>
                        </div>
                      </div>


                    <div className="floating-header__collapse navbar-collapse for-mobile">
                      <ul className="floating-header__social-media nav navbar-nav">
                        <li className="floating-header__icon"> <a href="https://www.facebook.com/GemmaCeramics"> <i className="fa fa-facebook" /> </a></li>
                        <li className="floating-header__icon"> <a href="https://www.instagram.com/gemmaceramics/"> <i className="fa fa-instagram" /> </a></li>

                        {/* <li className="floating-header__icon"> <a> <i className="fa fa-twitter" /> </a></li>
                    <li className="floating-header__icon"> <a> <i className="fa fa-pinterest" /> </a></li>
                    <li className="floating-header__icon"> <a> <i className="fa fa-instagram" /> </a></li>
                    <li className="floating-header__icon"> <a> <i className="fa fa-youtube" /> </a></li> */}
                      </ul>
                    </div>

                  </div>
                </Menu>

              </div>
            </div>
            {/* /.container-fluid */}
          </nav>

        </section>
        {/* End Section  Header */}
        {/* Javascripts
          ================================================== */}
      </div>
    );
  }
}
export default HeaderMenu;