import React from "react";
import { ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import './sort-bar.css'
import { Form, FormGroup, InputGroup, FormControl, Button, Glyphicon } from 'react-bootstrap'

class sortBar extends React.Component {

  constructor(props, context) {
    super(props, context);
    // this.onSelectPrice = this.onSelectPrice.bind(this)
    this.onSelectSorting = this.onSelectSorting.bind(this)
    this.onSelectResults = this.onSelectResults.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      products: [],
      // priceTitle: "Price(low to high)",
      // nameTitle: "Name (A-Z)",
      sortTitle: "Featured",
      term: ''
    };
  }
  handleSort(eventKey) {
    this.props.onSortClick({ name: 1 })

  }

  onSelectSorting(eventKey, object) {
    if (eventKey.name) {
      if (eventKey["name"] === 1) {
        this.setState({ sortTitle: "A to Z" })
      } else {
        this.setState({ sortTitle: "Z to A" })
      }
    } else {
      if (eventKey["prices.price_1"] === 1) {
        this.setState({ sortTitle: "Price (low - high)" })
      } else {
        this.setState({ sortTitle: "Price (high - low)" })
      }
    }
    this.props.onSortClick(eventKey)
  }
  // onSelectPrice(eventKey, object) {
  //   if(eventKey["prices.price_1"]===1){
  //     this.setState({priceTitle:"Low"})
  //   } else {
  //     this.setState({priceTitle:"High"})
  //   }
  //   this.props.onSortClick(eventKey)
  // }
  onSelectResults(eventKey, object) {
    this.props.onResultsClick(eventKey.Productslimit)
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.onSearch(this.state.term)
  }
  render() {
    const searchBar = (className)=>(
      <Form className={className} onSubmit={this.handleSubmit}>
        <FormGroup >
          <InputGroup>
            <FormControl style={{borderRadius:0}} onChange={(e) => this.setState({ term: e.target.value })} type="text" />
            <InputGroup.Button>
              <Button type="submit">
                <Glyphicon glyph="search" />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </Form>
    )
    return (
      <React.Fragment>
        {this.props.onSearch?(searchBar("phone-search")):('')}        
        <section className="shop-selector">
          <div className="shop-selector__holder header2" id="myHeader2">
            <div className="shop-selector__sort-by">
              <label className="shop-selector__sortby">
                <p>Sort by </p>
              </label>
              {this.props.onSearch?(searchBar("")):('')}
              <ButtonToolbar>
                <DropdownButton onSelect={this.onSelectSorting} className="sort_filter" title={this.state.sortTitle}>
                  <MenuItem eventKey={{ name: 1 }}>A to Z</MenuItem>
                  <MenuItem eventKey={{ name: -1 }}>Z to A</MenuItem>
                  {this.props.noPrices?(''):<MenuItem eventKey={{ minPrice: 1 }} >Price (low - high)</MenuItem>}
                  {this.props.noPrices?(''):<MenuItem eventKey={{ minPrice: -1 }} >Price (high - low)</MenuItem>}
                </DropdownButton>
                <DropdownButton onSelect={this.onSelectResults} className="sort_filter" title={"Results"}>
                  <MenuItem eventKey={{ Productslimit: 12 }} >12</MenuItem>
                  <MenuItem eventKey={{ Productslimit: 24 }} >24</MenuItem>
                  <MenuItem eventKey={{ Productslimit: 48 }} >48</MenuItem>
                </DropdownButton>
              </ButtonToolbar>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default sortBar;
