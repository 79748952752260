import React from "react";
import "./ContactForm.css";
import "../../../css/material-icons.css";
import { postObject } from "../../../services/CommonServices";
import Recaptcha from "react-recaptcha";
import { NotificationManager } from 'react-notifications';

class ContactForm extends React.Component {
  state = {
    name: "",
    address: "",
    postal_code: "",
    city: "",
    country: "",
    email: "",
    telephone: "",
    description: "",
    interests: []
  };
  interests = [];

  handleInputChange = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
   var response= window.grecaptcha.getResponse();
   console.log(response);
   postObject("recaptcha",{response: response})
   .then(result => {
     console.log(result);
   })
   .catch(error => 
     {console.log(error)});

     this.state.interests=[...this.interests];
     postObject("contact",this.state).then(result=>{
         if(result.data.err){
           
          NotificationManager.error(result.data.err); 
         }
         else
        NotificationManager.success("Contact added successfully");
     }).catch(err=>{
        NotificationManager.error(err); 
     });
  };
  handleCheckBox = e => {
    let target = e.target;

    if (target.checked) {
      this.interests.push(target.name);
    } else {
      let index = this.interests.indexOf(target.name);
      this.interests.splice(index, 1);
    }
    console.log(this.interests);
  };

  verifyCaptcha = response => {
    console.log(response);

  };
  render() {
    return (
      <div id="contact_form" className="contact-form">
        <p>
          If you would like to receive information about our latest products or
          a catalogue, make an enquiry, or ask for details of our products, fill
          in the requested information, mark your interest area and send it to
          us
        </p>
        <br />
        <div className="call-gemma">
          <p>
            {" "}
            Alternatively you can{" "}
            <span className="call-gemma__number"> call GEMMA on 16757</span>.
          </p>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row contactinfo-btn">
            <div className="col-md-6 col-sm-6">
              <div className="contact-form__name">
                <p>
                  Name and Surname <sup>*</sup>{" "}
                </p>
                <input
                  className="contact-form__name-btn"
                  style={{width:'100%'}}
                  placeholder="First Name Last Name"
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="contact-form__address">
                <p>Address </p>
                <input
                  className="contact-form__address-btn"
                  placeholder="Bid street. neighboarhood"
                  style={{width:'100%'}}
                  type="text"
                  name="address"
                  onChange={this.handleInputChange}
                  value={this.state.address}
                />
              </div>
              <div className="contact-form__postalcode">
                <p>Postal code </p>
                <input
                  className="contact-form__postal-btn"
                  style={{width:'100%'}}
                  placeholder
                  type="text"
                  name="postal_code"
                  onChange={this.handleInputChange}
                  value={this.state.postal_code}
                />
              </div>
              <div className="contact-form__cityname">
                <p>City </p>
                <input
                  className="contact-form__cityname-btn"
                  style={{width:'100%'}}
                  placeholder="City Name"
                  name="city"
                  type="text"
                  onChange={this.handleInputChange}
                  value={this.state.city}
                />
              </div>
              <div className="contact-form__country">
                <p>Country </p>
                <select
                  className="contact-form__country-select"
                  style={{width:'100%'}}
                  type="text"
                  name="country"
                  onChange={this.handleInputChange}
                  value={this.state.country}
                >
                  <option value="All">All</option>
                  <option value="Alexandria">Alexandria</option>
                  <option value="Cairo">Cairo</option>
                  <option value="Aswan">Aswan</option>
                </select>
              </div>
              <div className="contact-form__email">
                <p>
                  Email <sup>*</sup>{" "}
                </p>
                <input
                  className="contact-form__email-btn"
                  placeholder="example@gmail.com"
                  style={{width:'100%'}}
                  type="text"
                  onChange={this.handleInputChange}
                  name="email"
                  value={this.state.email}
                />
              </div>
              <div className="contact-form__telephone">
                <p>
                  Telephone <sup>*</sup>{" "}
                </p>
                <input
                  className="contact-form__telephone-btn"
                  placeholder="+country code"
                  type="text"
                  style={{width:'100%'}}
                  name="telephone"
                  onChange={this.handleInputChange}
                  value={this.state.telephone}
                />
              </div>
              <div className="contact-form__comments">
                <p>Description</p>
                <textarea
                  className="contact-form__text-area"
                  rows={4}
                  cols={50}
                  style={{width:'100%'}}
                  placeholder="Describe yourself here..."
                  onChange={this.handleInputChange}
                  name="description"
                  value={this.state.description}
                />
              </div>
            </div>
            <div                   
                className="col-md-6 col-sm-6">
              <div className="interested-in control-group"
                style={{width:'100%'}}
                >
                <p>Interested in:</p>
                <label className="interested-in__name control control--radio">
                  Floor &amp; wall tiles
                  <input
                    type="checkbox"
                    name="Floor & wall tiles"
                    onChange={this.handleCheckBox}
                  />
                  <div className="control__indicator" />
                </label>
                <label className="interested-in__name control control--radio">
                  Export sales
                  <input
                    type="checkbox"
                    name="Export sales"
                    onChange={this.handleCheckBox}
                  />
                  <div className="control__indicator" />
                </label>
                <label className="interested-in__name control control--radio">
                  Local sales
                  <input
                    type="checkbox"
                    name="Local sales"
                    onChange={this.handleCheckBox}
                  />
                  <div className="control__indicator" />
                </label>
                <label className="interested-in__name control control--radio">
                  Project sales
                  <input
                    type="checkbox"
                    name="Project sales"
                    onChange={this.handleCheckBox}
                  />
                  <div className="control__indicator" />
                </label>
                <label className="interested-in__name control control--radio">
                  Others
                  <input
                    type="checkbox"
                    name="Others"
                    onChange={this.handleCheckBox}
                  />
                  <div className="control__indicator" />
                </label>
              </div>
              <Recaptcha
                render="explicit"
                sitekey="6Ldg2HMUAAAAAG3vRiKok3zMMNYf3pcYq7YfSoXy"
                verifyCallback={this.verifyCaptcha}
              />

              <p>
                {" "}
                Get announcements, recommendations, and news about GEMMA
                products and services. <br />
                <br />
                By ticking this box, you acknowledge that you have read and
                agree to GEMMA’s terms and conditions of use and privacy policy.
              </p>
            </div>
          </div>
          <div className="contact__button-submit">
            <button
              type="submit"
              className="contact-form__btn-submit btn btn-default"
            >
              Submit Forms
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
