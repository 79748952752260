import React from "react";
import "./RecentlyOrdered.css";
import { getObjectWithToken } from '../../services/CommonServices'
import Spinner from '../Loading/Loading'
import {Link} from 'react-router-dom'
class RecentlyOrdered extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orders: [],
            ready: false
        };
    }

    componentDidMount() {
        var outsideThis = this
        getObjectWithToken("order/users")
            .then(function (response) {
                const orders = response.data.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                outsideThis.setState({ orders: orders, ready: true })
            })
    }
    render() {
        return (
            <div className="col-md-9">
                {
                    !this.state.ready ?
                        (
                            <div style={{ marginTop: 200, marginBottom: 200 }}>
                                <Spinner />
                            </div>
                        ) :
                        (
                            <div className="user-border__right">
                                {
                                    this.state.orders.length === 0 ? (
                                        <h2 className="user-wishlist__no_order">You have not ordered anything yet<br/> <Link to={"/shop"}>Click Here</Link> to go to the shop</h2>
                                    ) : (
                                            <div id="user-wishlist" className="user-wishlist status">
                                                {
                                                    this.state.orders.map((order, key) => (
                                                        order.products.map((product, key) => (
                                                            <div id="user-wishlist__order" className="user-wishlist recent-order">
                                                                <div className="user-wishlist-wrapper col-md-12">
                                                                    <div className="col-md-4 no-padding">
                                                                        <div className="user-wishlist__main-holder">
                                                                            <div className="user-wishlist__main-photo">
                                                                                <img alt="product" src={product.product.images[0]} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="user-wishlist__name">
                                                                            <h4>{product.product.name}</h4>
                                                                        </div>
                                                                        <div className="user-wishlist__state-holder">
                                                                            <h3>  Item Status: {order.status}</h3>
                                                                            <h3> Date Ordered: {order.created_at} </h3>
                                                                            {/* <h3> Due Date: 14/03/2018 </h3> */}
                                                                        </div>
                                                                        <div className="user-wishlist__icons">
                                                                            <div className="user-wishlist__icons-wrapper  row col-md-12">
                                                                                <div className="user-wishlist__icons-menu">
                                                                                    {/* <a className="user-wishlist__icons-link  add-cart" href="#" data-toggle="tooltip" data-placement="bottom" title data-original-title="Hooray!"><i className="user-wishlist__cart-icon fa fa-shopping-cart"> <span> Order again</span></i></a> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row col-md-12">
                                                                            <div className="user-wishlist__id-holder">
                                                                                <p>Product ID: <span className="grid-produc__id"> {product.product.segmentId} </span> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ))
                                                }
                                            </div>
                                        )
                                }
                            </div>
                        )
                }
            </div>
        )
    }
}
export default RecentlyOrdered;