import React from "react";
import './ContactInformation.css'
import '../../../css/material-icons.css'

class ContactInformation extends React.Component {
    render() {
        return (
            <div id="contact__information" className="contact-information">
                <div className="row">
                    <div className="col-md-7">
                        <div className="contact-information__wrapper">
                            <div className="contact-information__title">
                                <p className="contact-information__line"> EXPORT SALES DEPARTMENT </p>
                            </div>
                            <div className="contact-information__telephone">
                                <span className="contact-information__tel">   Tel : +(202) 3377 8857/8/9  |</span>
                                <span className="contact-information__fax">   Fax : +(202) 3377 8852 </span>
                                <div className="contact-information__mail">
                                    <p> E-Mail: export@GEMMA.com.eg  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="local-sale__wrapper">
                            <div className="local-sale__title">
                                <p className="local-sale__line"> LOCAL SALES DEPARTMENTs </p>
                            </div>
                            <div className="local-sale__telephone">
                                <p className="local-sale__tel">   Tel : +(202) 33778853/4 </p>
                                <p className="local-sale__fax">   Fax : +(202) 33778850/2  </p>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                    <div className="col-md-4">
                        <div className="head-quarter__wrapper">
                            <div className="head-quarter__title">
                                <p className="head-quarter__line"> HEADQUARTERS </p>
                            </div>
                            <div className="head-quarter__telephone">
                                <p className="head-quarter__tel"> 25 Cairo/Alexandria Desert
                                  Road, Al Remaya Square,
                                  Al Haram
        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactInformation;