import './HomeBanner.css'

import React from "react";
class HomerBanner extends React.Component {
 
    render() {
      return (
        <div>

          <section className="HomeBanner">
          <div className="container">
          <div class="col-md-10 col-md-offset-1 col-xs-12">
          <a href="/loyalty-program">
         <img src={require("../../img/HomeBanner/GEMMABanner728x90.jpg")} className="img-responsive" />
         </a>
         </div>

         </div>
          </section>

        </div>
      );
    }
}
export default HomerBanner;