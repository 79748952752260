import React from "react";
import './Contact.css'
import '../../../css/material-icons.css'
import { Switch, Route, NavLink } from "react-router-dom";
import Distributors from '../Distributors/Distributors';
import FAQ from '../FAQ/FAQ';
import ShowroomLocator from '../ShowroomLocator/ShowroomLocator';
import ContactForm from '../ContactForm/ContactForm';
import ContactInformation from '../ContactInformation/ContactInformation';
import CustomerServices from '../../CustomerServices/CustomerServices';
import OnlineShopping from "../../OnlineShopping/OnlineShopping";
import LoyaltyFaq from "../../LoyaltyFaq/LoyaltyFaq";
import Installation from "../../Installation/Installation";
import Slider from "react-slick";
import { Collapse } from "react-bootstrap";




class Contact extends React.Component {
    componentDidMount() {
        window.scroll(0, 0)
    }
    state = {
        collapseID: '',
        collapseDistributers: ''
    }
    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));

    toggleCollapseDistributers = collapseDistributers => () =>
        this.setState(prevState => ({ collapseDistributers: (prevState.collapseDistributers !== collapseDistributers ? collapseDistributers : '') }));
    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (

            <section className="contact-carusal">

                <div>
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                    <div className="wishlist__title ">
                        <h2 className="main-line-title">
                            CONTACT US</h2>
                    </div>
                    {/* FOR MOBILE VERSION ONLY */}
                    <Slider {...settings}>
                        <div>
                            <li><NavLink exact activeClassName="activeBold" to="/contact/">CONTACT FORM</NavLink></li>
                        </div>
                        <div>
                            <li><NavLink exact activeClassName="activeBold" to="/contact/contact-information">CONTACT INFO </NavLink></li>
                        </div>
                        <div>
                            {<li> <NavLink exact activeClassName="activeBold" to="/contact/distributors"> Distributors </NavLink></li>}
                            {/* <br /> Worldwide </Link></li> } */}
                        </div>
                        <div>
                            <li><NavLink exact activeClassName="activeBold" to="/contact/showroom-locator">SHOWROOM LOCATOR</NavLink></li>
                        </div>
                        <div>
                            <li><NavLink exact activeClassName="activeBold" to="/contact/faq">FAQ</NavLink></li>
                        </div>
                        <div>
                        </div>
                    </Slider>
                    {/* FOR MOBILE VERSION ONLY */}
                    <div className="container">
                        <div className="col-md-3 col-sm-3">
                            {/* start contact-us-sidebar == */}
                            <div className="contact-us-sidebar">
                                <ul className="menu">
                                    <li><NavLink exact activeClassName="activeBold" to="/contact/">CONTACT FORM</NavLink></li>
                                    <li><NavLink exact activeClassName="activeBold" to="/contact/contact-information">CONTACT INFORMATION </NavLink></li>
                                    <a style={{ cursor: "pointer" }} onClick={this.toggleCollapseDistributers('collapse2')}>DISTRIBUTORS <span>  </span>
                                        <i className={this.state.collapseDistributers === 'collapse2' ? (' fa fa-angle-up') : ('fa fa-angle-down')}></i>
                                    </a>
                                    <div className='contact_Distributors' >
                                        <Collapse id="collapse1" in={this.state.collapseDistributers}>
                                            <div>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/distributors"> Distributors in Egypt</NavLink></li>
                                            </div>
                                        </Collapse>
                                    </div>



                                    <li><NavLink exact activeClassName="activeBold" to="/contact/showroom-locator">SHOWROOM LOCATOR</NavLink></li>



                                    <a style={{ cursor: "pointer" }} onClick={this.toggleCollapse('collapse1')}>FAQ <span>  </span>
                                        <i className={this.state.collapseID === 'collapse1' ? (' fa fa-angle-up') : ('fa fa-angle-down')}></i>
                                    </a>
                                    <div className="contact_FAQ">

                                        <Collapse id="collapse1" in={this.state.collapseID}>
                                            <div>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/customer-services"> Customer Services</NavLink></li>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/faq"> General Questions </NavLink></li>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/online-shopping"> Online Shopping </NavLink></li>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/loyalty-faq"> Loyalty Program </NavLink></li>
                                                <li> <NavLink exact activeClassName="activeBold" to="/contact/installation"> Installation </NavLink></li>
                                            </div>
                                        </Collapse>
                                    </div>
                                </ul>
                                <div className="contact-us__hotline-number">
                                    <p>HOTLINE </p>
                                    <h3>16757 </h3>
                                </div>
                            </div>
                            {/* End contact-us-sidebar == */}
                        </div>
                        <div className="col-md-9 col-sm-9">
                            <div className="contact-us__right">
                                <Switch>
                                    <Route path='/contact/contact-information' component={ContactInformation} />
                                    <Route path='/contact/distributors' component={Distributors} />
                                    <Route path='/contact/showroom-locator' component={ShowroomLocator} />
                                    <Route path='/contact/customer-services' component={CustomerServices} />
                                    <Route path='/contact/online-shopping' component={OnlineShopping} />
                                    <Route path='/contact/installation' component={Installation} />
                                    <Route path='/contact/loyalty-faq' component={LoyaltyFaq} />
                                    <Route path='/contact/faq' component={FAQ} />
                                    <Route path='/contact/' component={ContactForm} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;