import React from "react";
import "./ShippingInformation.css";
import { getObjectWithToken, updateObjectWithToken, toBoxPrice } from '../../../services/CommonServices'
import { NotificationManager } from 'react-notifications';
import { Modal, Button, FormGroup, Image, DropdownButton, MenuItem } from 'react-bootstrap'
import TermsText from "../../Terms/TermsText";
import { toast } from 'react-toastify';
import config from '../../../config';
import { withRouter } from 'react-router-dom';
import TotalPayment from "./TotalPayment";
import Select from 'react-select';

class ShippingInformation extends React.Component {
    constructor(props) {
        super(props)
        this.orderProducts = this.orderProducts.bind(this)
        this.handleShippingChange = this.handleShippingChange.bind(this)
        this.saveShipping = this.saveShipping.bind(this)
        this.getTotalPrice = this.getTotalPrice.bind(this)
        this.handleDeliveryChange = this.handleDeliveryChange.bind(this)
        this.cities = Object.keys(config.shippingCosts).map((city) => ({ label: city, value: city }))
        this.state = {
            phone: "",
            delivery: 'online',
            shipping: {},
            cart: {
                products: []
            },
            accept: "off",
            user: {},
        }
    }

    handleShippingChange(e) {
        let shippingInfo = this.state.shipping;
        shippingInfo[e.target.id] = e.target.value;
        this.setState({ shipping: shippingInfo })
    }
    saveShipping(event) {
        event.preventDefault();
        const requireds = ["address_1", "city", "country", "phone"];
        const names = ["Address Line 1", "City", "Country", "Phone Number"]
        for (let i = 0; i < requireds.length; i++) {
            const required = requireds[i];
            if (!this.state.shipping[required] || this.state.shipping[required] === "") {
                NotificationManager.error(names[i] + ' is required', 'Update Error', 3000)
                return;
            }

        }
        updateObjectWithToken("users/profile", { shipping: this.state.shipping })
            .then((response) => {
                if (!response.data || response.data.message !== "User updated") {
                    NotificationManager.error("Oops! A problem occured", 'Update Error', 3000)
                } else {
                    NotificationManager.success('Successfully updated your account', 'Successful Update!', 3000)
                }
            })
    }

    componentDidMount() {
        getObjectWithToken("users/cart")
            .then((response) => {
                if (response.data.cart.products.length === 0) {
                    this.props.history.push({
                        pathname: '/',
                    })
                    return;
                }
                this.setState({ cart: response.data.cart })
            })
    }
    componentWillMount() {
        const context = this;
        getObjectWithToken("users/profile")
            .then((response) => {
                if (response.data.error) {
                    //TODO: Handle error
                } else {
                    context.setState({
                        shipping: {
                            phone: response.data.phone_number
                        }
                    })
                    if (response.data.shipping) {
                        context.setState({
                            shipping: { ...response.data.shipping }
                        })
                    }
                    if (response.data) {
                        this.setState({
                            user: response.data,
                        })
                    }
                }
            })
    }
    handleDeliveryChange(e) {
        this.setState({ delivery: e })
    }
    getTotalPrice() {
        let products = this.state.cart.products
        let total = 0;
        for (let i = 0; i < products.length; i++) {
            if (products[i].product.prices[products[i].tier]) {
                total += toBoxPrice(products[i].product, products[i].product.prices[products[i].tier]) * products[i].quantity;
            }
        }
        return total;
    }
    orderProducts() {
        // if (this.state.delivery === 'offline') {
        //     let order = this.state.cart;
        //     order.shipping = {
        //         address_1: this.state.shipping.address_1,
        //         address_2: this.state.shipping.address_2,
        //         area: this.state.shipping.area,
        //         city: this.state.shipping.city,
        //         country: this.state.shipping.country,
        //         phone: this.state.shipping.phone,
        //     }
        //     order.orderId = randomString.generate({ length: 10 })
        //     var outsideThis = this
        //     postObjectWithToken("order", order)
        //         .then((response) => {
        //             outsideThis.props.history.push({
        //                 pathname: '/confirmation',
        //                 state: {
        //                     order: response.data.orderId
        //                 }
        //             })
        //             NotificationManager.success('Successfully added your order', 'Success', 3000)
        //         })
        // } else {
        const requireds = ["address_1", "city", "country", "phone"];
        const names = ["Address Line 1", "City", "Country", "Phone Number"]
        for (let i = 0; i < requireds.length; i++) {
            const required = requireds[i];
            if (!this.state.shipping[required] || this.state.shipping[required] === "") {
                NotificationManager.error(names[i] + ' is required', 'Update Error', 3000)
                return;
            }

        }
        if (!this.state.accept || this.state.accept === 'off') {
            NotificationManager.error('You have to accept the terms of service to continue', '', 3000)
            return
        }
        
        updateObjectWithToken("users/profile", { tempShipping: this.state.shipping })
            .then((response) => {
                if (!response.data || response.data.message !== "User updated") {
                    NotificationManager.error("Oops! A problem occured", 'Update Error', 3000)
                } else {
                    getObjectWithToken('auth/hash')
                        .then( (response)  => {
                            setTimeout(
                                ()=> {
                                    window.Checkout.configure({
                                        session: { 
                                            id: response.data.session.id
                                           }
                                    })
                                    window.Checkout.showPaymentPage()
                                }, 1000
                            )
                             //window.location.href = response.data.url
                        })
                        .catch((err) => {
                            toast.error(`SERVER ERROR HERE IS IT ${err.message}\n OR You need to have a verified email to shop online`, config.toastSettings)
                            this.props.history.push({ pathname: 'account-settings' })
                        })
                }
            })
        
    }
    render() {
        return (
            <React.Fragment>
                <Modal
                    className="shipping-modal"
                    onHide={() => { this.setState({ termsModal: false }) }}
                    show={this.state.termsModal}
                    bsSize="large"
                    aria-labelledby="contained-modal-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">Terms and Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TermsText />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({ termsModal: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <form onSubmit={this.saveShipping}>
                    <div>
                        <div className="shop__title ">
                            <h2 className="main-line-title">SHIPPING INFORMATION</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="user-border__right">
                                    <div id="user-address" className="row user-accountــaddress">
                                        <div className="col-md-6 col-md-offset-3">
                                            {/* <h3> SHIPPING ADDRESS </h3> */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>Address Line One <sup>*</sup> </p>
                                                        <input id="address_1" onChange={this.handleShippingChange} value={this.state.shipping.address_1} className="contact-form__line-btn" placeholder="Address Line One" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>Address Line Two </p>
                                                        <input id="address_2" onChange={this.handleShippingChange} value={this.state.shipping.address_2} className="contact-form__line-btn" placeholder="Address Line Two" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>Area</p>
                                                        <input id="area" onChange={this.handleShippingChange} value={this.state.shipping.area} className="contact-form__name-btn" placeholder="Area" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>City <sup>*</sup></p>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Choose your city"
                                                            value={this.cities.find((city)=>city.value==this.state.shipping.city)}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={this.cities}
                                                            onChange={(selected) => this.handleShippingChange({ target: { id: "city", value: selected.value } }) }
                                                        />
                                                        {/* <input id="city" onChange={this.handleShippingChange} value={this.state.shipping.city} className="contact-form__name-btn" placeholder="City" type="text" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>Country <sup>*</sup></p>
                                                        <input id="country" onChange={this.handleShippingChange} value={this.state.shipping.country} className="contact-form__name-btn" placeholder="Egypt" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="user-account__line__checkout">
                                                        <p>Phone <sup>*</sup></p>
                                                        <input id="phone" onChange={this.handleShippingChange} value={this.state.shipping.phone} className="contact-form__name-btn" placeholder="Phone" type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <ButtonToolbar>
                                                <ToggleButtonGroup type="radio" name="delivery" value={this.state.delivery} onChange={this.handleDeliveryChange}>
                                                    <ToggleButton value={'offline'}>Cash on delivery</ToggleButton>
                                                    <ToggleButton value={'online'}>Online Payment</ToggleButton>
                                                </ToggleButtonGroup>
                                            </ButtonToolbar> */}
                                            <br />
                                            {
                                                this.cities.find((city)=>city.value==this.state.shipping.city)?(
                                                    <TotalPayment discount={this.state.user.verified ? 0.1 : 0} city={this.state.shipping.city} subtotal={this.getTotalPrice()} />
                                                ):('')
                                            }
                                            {
                                                this.state.delivery === 'online' ? (
                                                    <div>
                                                        <div>
                                                            <Image style={{ marginRight: 10 }} width={300} height={85} src={require("../../../img/bme.png")} />
                                                            <Image style={{ marginRight: 10 }} width={85} height={25} src={require("../../../img/Visa.gif")} />
                                                            <Image width={85} height={45} src={require("../../../img/MasterCard.png")} />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <FormGroup>
                                                                <label className="shop-main__label control control--checkbox">
                                                                    <input type="checkbox" value={this.state.accept} onChange={(e) => { this.setState({ accept: (e.target.value === "on" ? ('off') : ('on')) }) }}></input>
                                                                    <div className="shop-main__controler control__indicator" />
                                                                </label>
                                                                <p className="terms">
                                                                    I Accept the <span onClick={() => { this.setState({ termsModal: true }) }} className="terms-link">Terms and Conditions</span>
                                                                </p>
                                                                {/* <input type="checkbox" value={this.state.accept} onChange={(e) => { this.setState({ accept: (e.target.value === "on" ? ('off') : ('on')) }) }} className={"shipping-box" + (this.state.accept === "on" ? ('-active') : (''))} /> */}
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                )
                                                    : ('')
                                            }
                                            <div className="user-account__address-btn">
                                                <button type="button" onClick={this.orderProducts} className="user-account__cancel-adress btn btn-default"> Confirm</button>
                                                <button type="submit" className="user-account__save-address btn btn-default">Save Address </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
            </React.Fragment>
        )
    }
}
export default withRouter(ShippingInformation);