import React from "react";
import "./About.css";
import "../../css/bootstrap.css";
import 'react-notifications/lib/notifications.css';
import CountUp from 'react-countup';
import { Parallax } from 'react-parallax';
import { getAllObjects } from "../../services/CommonServices";
class About extends React.Component {
    state = {

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const context = this;
        window.addEventListener('scroll', () => { this.handleScroll(context) });
        getAllObjects("cms")
            .then(res => {
                this.setState({ company_url: res.data[0].company_profile_url, company_policy_url: "https://gemma-static.s3.amazonaws.com/files/English.pdf"});
            });
    }

    handlePolicyClick = () => {
        var win = window.open(this.state.company_policy_url, '_blank');
        win.focus();
    }

    handleViewClick = () => {
        var win = window.open(this.state.company_url, '_blank');
        win.focus();
    }
    handleScroll(context) {
        if (window.screen.width <= 760) {
            if (window.scrollY >= 3600) {
                context.setState({ showCounter: true })
            }
        } else {
            if (window.scrollY >= 3000) {
                context.setState({ showCounter: true })
            }
        }
    }

    render() {
        return (
            <div className="About">
                <Parallax
                    bgImage={require("../../img/About/gemma_about-bg.jpg")}
                    bgImageAlt="404"
                    strength={250}
                >
                    <div style={{ height: '90vh' }} />
                </Parallax>


                <div class="About__title main-title">
                    <h2 class="main-line-title"> Who We Are</h2>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 wow fadeInUp">

                                <div className="About__content">

                                    <p> Established in 1989, ‘GEMMA’ has become the leading manufacturer of high-end Ceramic and Porcelain tiles in Egypt and the Middle East.. A renown name in Ceramics Industry; our tiles are recognized for matching beauty and variety with craftsmanship and durability.. Today, our products enhance the appearance and function of homes and businesses in over 64 countries spanning the globe. <br /> <br />
                                        In 2009, GEMMA expanded its production facilities with the opening of our new plant in Sadat City, an addition that placed us at the forefront of the global ceramics industry.. Sophisticated production methods have allowed us to recreate the textures of stone, wood and sand, everything from metallic sheens to delicate floral arabesques.. Today, with a production capacity of 18 million square meters annually, GEMMA sets design trends and industry bench-marks..
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="about-banner" className="about-banner">
                    <div class="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-1 wow fadeInLeft">
                                <h4>From day one, the company’s ambitions extended far beyond simply making and selling products. It wanted to create ‘The Best’ complete solution – a lifestyle, an aura and a world of sensations that would connect with and grace the lives of customers who expect nothing less than the best </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="About__caption-holder">

                    <div className="container">
                        <div className="row">
                            <div className="About__caption col-md-10 col-md-offset-1">
                                <div className="About__title-compontent col-md-4 wow fadeInLeft">
                                    <h3>ORIGINALITY
                                        BY DESIGN </h3>

                                </div>

                                <div className="About__text-compontent col-md-8 wow fadeInRight">
                                    <p> At the heart of GEMMA for the past quarter of a century has been a flair for original, contemporary, life-enhancing design. The company’s design schemes are versatile and adaptable, sensitive to international trends, yet influenced by the rich decorative traditions of the region and the distinctive textures of the natural world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="About__bg">
                    <div className="container">
                        <div className="row">
                            <div className="About__caption col-md-10 col-md-offset-1 ">

                                <div className="About__text-compontent col-md-8 wow fadeInLeft">
                                    <p> For a quarter of a century, investment in cutting-edge technologies has been the driving force behind GEMMA’s advancements in design, innovation and production capacity. It has made the company the leading manufacturer of high-end ceramic and porcelain tiles in the Middle East – and a respected design pioneer on the world stage.
                                    </p>
                                </div>
                                <div className="About__title-compontent col-md-4 wow fadeInRight">
                                    <h3>INVESTING IN GROWTH - DRIVEN BY TECHNOLOGY</h3>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="About__caption-holder">

                    <div className="container">
                        <div className="row">
                            <div className="About__caption col-md-10 col-md-offset-1">
                                <div className="About__title-compontent col-md-4 wow fadeInLeft">
                                    <h3>BUILDING A
                                    WORLDWIDE
REPUTATION</h3>

                                </div>

                                <div className="About__text-compontent col-md-8 wow fadeInRight">
                                    <p> GEMMA upholds its international profile by exhibiting at the world’s leading interior design exhibitions. Only the world’s foremost producers are invited to attend prestigious trade fairs such as Cersaie in Bologna and Cevisama in Valencia. Here the most influential manufacturers, architects and designers come together to share inspiration and expertise, and to sample the very best in interior design from around the globe.
                                    GEMMA has exhibited at Cersaie since the early nineties, with its presence growing exponentially throughout the years – an expansion that reflects
</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="About__bg">
                    <div className="container">
                        <div className="row">
                            <div className="About__caption col-md-10 col-md-offset-1 ">
                                <div className="About__text-compontent col-md-8 wow fadeInLeft">
                                    <p> GEMMA’s export story began in 1992 with its first-ever overseas shipment taking tiles to Russia, Ukraine and the wider Middle-East. Today, GEMMA is recognized as an internationally important ceramics producer and supplier, with a market penetration that reflects the trust clients have in the quality of its products and the reliability of its delivery services.


</p>
                                </div>
                                <div className="About__title-compontent col-md-4 wow fadeInRight">
                                    <h3>TAKING TILES
                                    TO NEW
                                    TERRITORIES

</h3>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div id="counter" className="counter">
                    <div className="main_counter_area">
                        <div className="overlay p-y-3">
                            <div className="container">
                                <div className="row">
                                    <div className="counter__title">

                                        <h3> GEMMA AT A GLANCE </h3>
                                    </div>
                                    <div className="main_counter_content text-center white-text wow fadeInUp">
                                        <div className="col-md-3">
                                            <div className="single_counter">
                                                <h3>Established </h3>
                                                <div className="count-number wow fadeInUp">


                                                    {
                                                        this.state.showCounter ? (<CountUp duration={5.2}
                                                            useEasing={true}
                                                            useGrouping={true}
                                                            start={0} end={28} />) : ('')
                                                    }


                                                </div>
                                                <p>YEARS AGO</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="single_counter">
                                                <h3>Enhancing spaces in </h3>
                                                <div className="count-number wow fadeInUp">

                                                    {
                                                        this.state.showCounter ? (<CountUp duration={5.2}
                                                            useEasing={true}
                                                            useGrouping={true}
                                                            start={0} end={64} />) : ('')
                                                    }

                                                </div>
                                                <p>COUNTRIES</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="single_counter">
                                                <h3>Annual production of </h3>
                                                <div className="count-number wow fadeInUp">

                                                    {
                                                        this.state.showCounter ? (<CountUp duration={5.2}
                                                            useEasing={true}
                                                            useGrouping={true}
                                                            start={0} end={18} />) : ('')
                                                    }
                                                </div>
                                                <p>MILLION M <sup>2 </sup></p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="single_counter">
                                                <h3>Constantly developed by </h3>
                                                <div className="count-number wow fadeInUp">
                                                    {
                                                        this.state.showCounter ? (<CountUp duration={5.2}
                                                            useEasing={true}
                                                            useGrouping={true}
                                                            start={0} end={1964} />) : ('')
                                                    }
                                                </div>
                                                <p>EMPLOYEES</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="company-profile_button-container">
                    <button
                        onClick={this.handleViewClick}
                        type="button"
                        className="main-button collection__btn btn btn-default"
                        style={{ whiteSpace: "normal","margin-right": "50px"}}
                         type="button" className="main-button collection__btn btn btn-default catalogue-btn">Company Profile</button>
                    
                    <button
                        onClick={this.handlePolicyClick}
                        type="button"
                        className="main-button collection__btn btn btn-default"
                        style={{ whiteSpace: "normal" }} type="button" className="main-button collection__btn btn btn-default catalogue-btn">Company Policy</button>
            
                </div>
            </div>
        );
    }
}


export default About;

