import React from "react";
import { getAllObjects, getObjectWithToken } from '../../../services/CommonServices'
import {  Grid, Row, Col, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import "../LoyaltyProgram/LoyaltyProgram.css";
import "./LoyaltyProducts.css";
import RedemptionModal from "./RedemptionModal";
import { toast } from "react-toastify";
import config from "../../../config";
import {Link} from 'react-router-dom'

class LoyaltyProducts extends React.Component {
    constructor(props) {
        super(props);
        this.filterProducts = this.filterProducts.bind(this)
        this.state = {
            loyaltyproducts: [],
            displayProducts:[],
            categories: []
        }
    }
    componentDidMount() {
        window.scroll(0, 0)
        const outsideThis = this;
       getObjectWithToken('users/profile')
       .then((response)=>{
           this.setState({user:response.data})
       })
        getAllObjects("loyaltyproducts/categories")
            .then( (categoriesResponse)=> {
                getAllObjects("loyaltyproducts")
                .then( (response)=> {
                    outsideThis.setState({categories: categoriesResponse.data, loyaltyproducts: response.data,displayProducts:response.data },()=>this.filterProducts(categoriesResponse.data[0]._id))
                })
            })
    }
    filterProducts(category){
        let originalProducts = this.state.loyaltyproducts.slice(0);
        let filteredProducts = originalProducts.filter((product)=>product.category===category)
        this.setState({displayProducts:filteredProducts})
    }
    handleProductClick = (product)=>{
        if(!this.state.user.email){
            return toast.error(
                <p>
                    You need to have a verified email to use the Loyalty Program <Link to="/account-settings/">Click Here</Link> to add an email
                </p>
                ,config.toastSettings)
        }
        if(!this.state.user.verified){
            return toast.error(
                <p>
                    You need to be verified to use the Loyalty Program <Link to="/account-settings/loyality-points">Click Here</Link> to get verified
                </p>
                ,config.toastSettings)
        }
        if(this.state.user.loyalityPoints < product.points){
            return toast.error("You don't have enough points",config.toastSettings)
        }
        this.setState({selectedProduct:product,show:true})
    }
    render() {
        return (
            <div>
                <div className="loyaltyprogram__title">
                    <h2 className="main-line-title">Loyalty Products</h2>
                </div>
                {
                    this.state.categories[0] ? (
                        <ButtonToolbar className="category-toolbar">
                            <ToggleButtonGroup type="radio" name="options" onChange={this.filterProducts} defaultValue={this.state.categories[0]._id}>
                                {
                                    this.state.categories.map((category) => (
                                        <ToggleButton value={category._id}>{category.name}</ToggleButton>
                                    ))
                                }
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    ) : ('')
                }


                <Grid>
                    <Row className="show-grid">
                        {
                            this.state.displayProducts.map((loyaltyproduct, key) => (
                                <Col xs={12} md={4}>
                                    <div onClick={()=>this.handleProductClick(loyaltyproduct)} className="loyaltyprogram__container-item">
                                        <img style={{ width: "64%" }} src={loyaltyproduct.featured_image ? (loyaltyproduct.featured_image) : (require("../../../img/white.png"))} />
                                        <h4>{loyaltyproduct.name}</h4>
                                        <p>{loyaltyproduct.description}</p>
                                        <p>{"Points : " + loyaltyproduct.points}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Grid>
                <RedemptionModal user={this.state.user} product={this.state.selectedProduct} show={this.state.show} onHide={()=>this.setState({show:false},this.componentDidMount)}/>
            </div>
        )
    }
}


export default LoyaltyProducts;