import React from "react";
import { ButtonToolbar, ToggleButton, ToggleButtonGroup, Grid } from "react-bootstrap";
import { getAllObjects, toCloudinary } from '../../services/CommonServices'
import Lightbox from 'react-images'
import "./Projects.css";
import Pagination from "../shop/pagination/pagination";
import SortBar from "../shop/sort-bar/sortBar";
class Projects extends React.Component {
    constructor() {
        super();
        this.filterProjects = this.filterProjects.bind(this)
        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrev = this.gotoPrev.bind(this);
        this.handleLightClick = this.handleLightClick.bind(this);
        this.clickThumbnail = this.clickThumbnail.bind(this);
        this.state = {
            projects: [],
            displayProjects: [],
            lightboxImages: [
                {
                    src: ''
                }
            ],
            limit: 12,
            lightboxIsOpen: false,
            international:false,
            currentImage: 0,
            currentPage: 0,
            category: 'Hospitality'
        }
    }
    filterProjects(cat,flag) {
        let projects = this.state.projects.slice(0)
        let newProjects = projects.filter((project) => {
            return project.category === cat && project.international == flag;
        })
        this.setState({ displayProjects: newProjects, filter: cat,international:flag ,currentPage: 0 }, () => this.onSortClick(this.state.sorting))
    }
    componentDidMount() {
        getAllObjects("projects")
            .then((response) => {
                this.setState({ projects: response.data }, () => {
                    this.filterProjects("Hospitality",false)
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    onPageChange(page) {
        window.scroll(0, 250)
        this.setState({
            currentPage: page
        })
    }
    handleLightClick(project) {
        let images = []
        project.images.forEach(image => {
            images.push({
                src: image
            })
        });
        this.setState({
            lightboxImages: images,
            lightboxIsOpen: true
        })
    }
    gotoPrev() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    clickThumbnail(index) {
        this.setState({ currentImage: index })
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
            lightboxImages: [
                {
                    src: ''
                }
            ]
        });
    }
    onSortClick(sorting) {
        if (!sorting) return
        if (sorting.name === 1) {
            this.setState({
                displayProjects: this.state.displayProjects.sort((a, b) => {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                })
            })
        } else if (sorting.name === -1) {
            this.setState({
                displayProjects: this.state.displayProjects.sort((a, b) => {
                    if (a.name > b.name) { return -1; }
                    if (a.name < b.name) { return 1; }
                    return 0;
                })
            })
        }
        this.setState({ sorting: sorting })
    }
    onResultsClick(limit) {
        this.setState({ limit: limit })
    }
    render() {
        const categories = ['Hospitality', 'Residential', 'Educational', 'Office Buildings', 'Infrastructure', 'Retail & Entertainment', 'Restaurants', 'Clubs', 'Hospitals','New Administrative Capital']
        const pagedProjects = this.state.displayProjects.slice(this.state.currentPage * this.state.limit, this.state.currentPage * this.state.limit + this.state.limit)
        return (
            <section className="projects">
                <Lightbox
                    backdropClosesModal
                    images={this.state.lightboxImages}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                    onClickPrev={this.gotoPrev}
                    onClickNext={this.gotoNext}
                    preloadNextImage
                    showThumbnails
                    onClickThumbnail={this.clickThumbnail}
                    onClose={this.closeLightbox}
                />
                <div className="wishlist__title ">
                    <h2 className="main-line-title">
                        Projects</h2>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="shop-main__head">
                                <h3>Filter results by</h3>
                            </div>
                            <div className="shop-main__shop-layered">
                                <div className="shop-main__filter-title">
                                    <h4>Region</h4>
                                </div>
                                    <label className="shop-main__label control control--checkbox">
                                        {"International"}
                                        <input id={"International"} name="type" onClick={() => this.filterProjects(this.state.category,(this.state.international===true?(null):(true)))} checked={this.state.international === true} type="checkbox"></input>
                                        <div className="shop-main__controler control__indicator" />
                                    </label>
                                    <label className="shop-main__label control control--checkbox">
                                        {"Local"}
                                        <input id={"Local"} name="type" onClick={() => this.filterProjects(this.state.category,(this.state.international===false?(null):(false)))} checked={this.state.international === false} type="checkbox"></input>
                                        <div className="shop-main__controler control__indicator" />
                                    </label>
                                <div className="shop-main__filter-title">
                                    <h4>Project Type</h4>
                                </div>
                                {categories.map((category) => (
                                    <label className="shop-main__label control control--checkbox">
                                        {category}
                                        <input id={category} name="type" onClick={() => this.filterProjects(category,this.state.international)} checked={this.state.filter === category} type="checkbox"></input>
                                        <div className="shop-main__controler control__indicator" />
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <SortBar
                                onSortClick={this.onSortClick.bind(this)}
                                noPrices
                                onResultsClick={this.onResultsClick.bind(this)}
                            />
                            {
                                this.state.displayProjects.length === 0 ? (
                                    <Grid>
                                        <h1 className="no-project">
                                            No Projects yet for this category
                                </h1>
                                    </Grid>
                                ) : ('')
                            }
                            <div className="row">

                                {
                                    pagedProjects.map((project, key) => (
                                        <div className="grid-product-wrapper__holder col-md-6 col-sm-6 col-lg-6 col-xl-4">
                                            <div className="grid-product-wrapper box">
                                                <div class="grid-product-custome col-md-9">
                                                    <div className="grid-product__main-holder">
                                                        <div className="grid-product__main-photo">
                                                            <img
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                id={"myImage" + key}
                                                                src={
                                                                    project.images[0]
                                                                        ? toCloudinary(project.images[0], 250, 400)
                                                                        : ""
                                                                }
                                                                onError={e => {
                                                                    e.target.src = require("../../img/download.png");
                                                                }}
                                                                onClick={() =>
                                                                    this.handleLightClick(project)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix grid__product__padding col-md-3">
                                                    <div className="grid-product__colors">
                                                        {project.images[0] ? (
                                                            <div className="grid-product__colors-img">
                                                                <img
                                                                    className="thumb-img"
                                                                    src={toCloudinary(project.images[0], 250, 400)}
                                                                    onError={e => {
                                                                        e.target.src = require("../../img/download.png");
                                                                    }}
                                                                    onClick={() => {
                                                                        document.getElementById(
                                                                            "myImage" + key
                                                                        ).src = toCloudinary(project.images[0], 250, 400);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                                ""
                                                            )}
                                                        {project.images[1] ? (
                                                            <div className="grid-product__colors-img">
                                                                <img
                                                                    className="thumb-img-no-padding-right"
                                                                    src={toCloudinary(project.images[1], 250, 400)}
                                                                    onError={e => {
                                                                        e.target.src = require("../../img/download.png");
                                                                    }}
                                                                    onClick={() => {
                                                                        document.getElementById(
                                                                            "myImage" + key
                                                                        ).src = toCloudinary(project.images[1], 250, 400);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                                ""
                                                            )}
                                                        {project.images[2] ? (
                                                            <div
                                                                style={{ marginBottom: 0 }}
                                                                className="grid-product__colors-img"
                                                            >
                                                                <img
                                                                    className="thumb-img-no-padding-right"
                                                                    src={toCloudinary(project.images[2], 250, 400)}
                                                                    onError={e => {
                                                                        e.target.src = require("../../img/download.png");
                                                                    }}
                                                                    onClick={() => {
                                                                        document.getElementById(
                                                                            "myImage" + key
                                                                        ).src = toCloudinary(project.images[2], 250, 400);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                                ""
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <hr className="grid-product__hr" />
                                                        <div className="grid-product__info_container">
                                                            <div className="grid-product__info col-md-9 col-xs-9">
                                                                <div className="grid-product__name">
                                                                    <h4> {project.name} </h4>
                                                                </div>

                                                                <div className="grid-product__range-holder" />
                                                            </div>
                                                            <div className="col-md-3 col-xs-3 no-padding">
                                                                <div className="grid-product__icons">
                                                                    <div className="grid-product__icons-wrapper">
                                                                        <div className="grid-product__icons-menu">
                                                                            <a
                                                                                onClick={() => this.handleLightClick(project)}
                                                                                className="grid-product__icons-link"
                                                                                data-toggle="tooltip"
                                                                                data-placement="bottom"
                                                                                title
                                                                                data-original-title="Hooray!"
                                                                            >
                                                                                <i
                                                                                    class="material-icons-new grid-product__fav-icon sharp-photo_library icon-white"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                </i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {project.newCollection ? (
                                                    <div class="ribbon list">
                                                        <span>New</span>
                                                    </div>
                                                ) : (
                                                        ""
                                                    )}
                                            </div>

                                        </div>
                                    ))
                                }
                                <div className="col-md-12">
                                    <Pagination
                                        onPageChange={this.onPageChange.bind(this)}
                                        currentPage={this.state.currentPage}
                                        modelsLength={this.state.displayProjects.length}
                                        Productslimit={this.state.limit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Projects;