import React from "react";
import { postObjectWithToken} from '../../services/CommonServices'
import Spinner from '../Loading/Loading'
import Axios from "axios";


class ProcessOrder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "Processing your order"
        }
    }
    async componentDidMount() {

        //const payment = 

        if (window.location.search !== "") {
            const querystring = require('querystring');
            let queryObject = querystring.parse(window.location.search.substring(1));
            console.log(queryObject.orderId)
            if (queryObject.orderId) {
                let hash = queryObject.vpc_SecureHash
                delete queryObject.Title;
                delete queryObject.vpc_SecureHash;
                delete queryObject.vpc_SecureHashType;
                postObjectWithToken("order/verify-payment", {
                    orderId : queryObject.orderId
                    
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.history.push({
                            pathname: '/confirmation',
                            state: {
                                order: queryObject.orderId,
                                receipt: response.data.ReceiptNo
                            }
                        })
                        // NotificationManager.success('Successfully added your order', 'Success', 3000)
                    }
                })
                .catch((err) => {
                    console.log("in catch error !!!!!")
                    this.setState({ message: "Your order was not completed successfully.", message2: "Redirecting..." })
                     setTimeout(() => {
                         this.props.history.push({
                            pathname: '/cart'
                         })
                     }, 3000)
                })
            } else {
                console.log("in else statement !!!!")
                this.setState({ message: "Your order was not completed successfully.", message2: "Redirecting..." })
                setTimeout(() => {
                    this.props.history.push({
                       pathname: '/cart'
                    })
                }, 3000)
            }
        } else {
            this.props.history.push({
                pathname: '/'
            })
        }
    }

    render() {
        return (
            <div style={{ marginTop: 200, marginBottom: 200 }}>
                <h1 style={{ textAlign: "center" }}>{this.state.message}</h1>
                <h2 style={{ textAlign: "center" }}>{this.state.message2}</h2>
                <Spinner />
            </div>
        )
    }
}
export default ProcessOrder;