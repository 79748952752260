import React from 'react';

class CustomerServices extends React.Component {
    state = {  }
    products= {
        product1 :"(Group BIb) Glazed Porcelain Tile with water absorption 0.5 % < Ev ≤ 1 %.",
        product2:"(Group BIIa) Floor ceramic Tile with water absorption 3 % < Ev ≤ 6 %.",
        product3:"(Group BIII) Wall Ceramic Tile Tiles with water absorption, Ev > 10 %."
    }
    render() { 
        return (
            <React.Fragment>
                <h2>General related questions </h2>
            <div id="fa_q" className="faq" data-background="#3498db">
                <div className="question_answer">
                    <h3 className="question">What does Dry-pressed tile mean?</h3>
                    <p className="answer"> A tile formed from a milled body mixture and shaped in molds under high pressure, and finally is designated as Group B in the international standards specifications. </p>
                    <h3 className="question"> What are ceramic tile?</h3>
                    <p className="answer"> Ceramic tiles are generally thin slab made from different types of clays, organic materials and/or other inorganic raw materials.  </p>
                    <h3 className="question">What is glaze?</h3>
                    <p className="answer"> Vitrified covering material that is impermeable. </p>
                    <h3 className="question">What is polished surface?</h3>
                    <p className="answer">  Surface of ceramic tile which has been given a glossy finish through mechanical polishing as last stage of manufacture.
                   </p>
                    <h3 className="question">How does porcelain tile differ from ceramic tile?</h3>
                    <p className="answer">Ceramic tiles are red clay tiles, shaped in molds under high pressure and fired at 1150 ⁰C. 
                    Porcelain tiles are specific type of ceramic tiles made from ball clay, shaped in molds under ultra-high pressure and fired at 1200 ⁰C, which gives it a low absorbing water percentage. Porcelain tile is the hardest and strongest available manufactured tile. </p>
                    <h3 className="question">What are the benefits of Vitrified tile?</h3>
                    <p className="answer"> <ul> <li > • Stain resistance. </li>
                                            <li>  • Resistant to wear and high temperatures. </li>
                                            <li>  • Easy to clean and maintain. </li>
                                            <li>  • Available in an incredible choice of shapes, designs, sizes and colors.</li>
                        <li>  • Durable—it lasts for years and years. </li>
                       </ul> 
                     </p>
                     <h3 className="question">What is a Rectified tile? </h3>
                     <p className="answer">Rectified tile is the tile that after firing is subjected to a precise mechanical grinding.</p>
                     <h2>Installation Related Questions</h2>
                    <h3 className="question"> What is tonality? </h3> 
                     <p className="answer"> Tonality is the variation in color which appears during firing process. </p> 
                     <h3 className="question"> What is Shade?  </h3> 
                     <p className="answer"> The shade of manufactured tiles varies depending on the firing process, because of the physiochemical reactions taking place under the kiln conditions.At GEMMA, glazed tiles are controlled in up to 7 shades. Shade N is the original tone,
 In addition, there are three lighter versions L1, L2 and L3, and also three darker ones, that are D1, D2 and D3. 
Recently, GEMMA creates a tile models that can have several faces to simulate the natural effect.  </p> 
                     <h3 className="question"> What does the caliber mean?   </h3> 
                     <p className="answer"> The caliber parameter applies only on floor tiles because, in the process of firing these tiles under high temperatures, shrinkage of the tile mass occurs. As a result, the tiles coming out of the kiln have slightly different dimensions for the same size.  </p> 
                     <h3 className="question">  How should we clean and maintain tile floors?  </h3> 
                     <p className="answer"> Maintaining tile floors is easy, usually only requiring normal cleaning such as Sweep or vacuum periodically. Use a damp cloth or sponge to clean spills. </p> 
                     <h3 className="question"> What types of tile surfaces available?  </h3> 
                     <p className="answer">GEMMA offers many types of surfaces, to fulfill customer needs. Currently, we offer: matte, glossy, structural, semi-polished, and finally polished tiles. </p> 
                     <h3 className="question"> What types of products does GEMMA offer? </h3> 
                     <p className="answer"><ul><li>{this.products.product1}</li>
                     <li>{this.products.product2}</li> <li>{this.products.product3}</li></ul> </p> 
                     <h3 className="question"> What is the working size? </h3> 
                     <p className="answer"> The size of a tile determined by the manufacturer to which the actual size shall conform
within specified permissible deviations</p>

                     <h2>Technical Terms</h2>

                     <h3 className="question"> Water absorption? </h3> 
                     <p className="answer">The percentage of water impregnating a tile, measured in accordance with International
Standard ISO 10545-3. Water absorption is used to determine which groups the tiles belong to. </p> 
                     <h3 className="question">PEI parameter?   </h3> 
                     <p className="answer">This is a parameter that determines the abrasion resistance class of tiles in accordance with International Standard ISO 10545-7.PEI class gives a guide to install the tiles according to its correct usage.
 </p> 
                     <h3 className="question"> What is the resistance to staining of ceramic tiles?  </h3> 
                     <p className="answer">The resistance to staining of ceramic tiles is tested in accordance with the 
ISO 10545-14 standard. Resistance to stain varies according to class which depends on how it is cleaned. </p>
                   </div>
                   </div>
          </React.Fragment>
        )
            
        }
    }

 
export default CustomerServices;