import React, { Component } from 'react';

class OnlineShopping extends Component {
    state = {  }
    render() { 
        return (<React.Fragment>
            <h3>Help with my orders.</h3>
            <h2 style={{"text-decoration":"underline"}}>Payment</h2>     
            <div id="fa_q" className="faq" data-background="#3498db">
            <div className="question_answer">
            <h3 className="question">What are the Accepted Payment Methods? </h3>
            <p className="answer"> GEMMA accepts payment by credit and debit cards (Visa and MasterCard).</p>
            <h3 className="question">What should I do if My Credit Card was wrongly charged? </h3>
            <p className="answer"> Kindly contact our Hotline 16757 with the order number and our team will handle your complaint.</p>
            <h3 className="question">How to View My Orders? </h3>
            <p className="answer">To view your orders: <ul><li> 1. Log in to gemma.com.eg account using your registered email address and password.
                </li><li>2.In your account settings click on <a href='/account-settings/order-history'>Order status& history  </a> tab By default, all orders you have placed will be displayed.
                    </li></ul> </p>

             <h2 style={{"text-decoration":"underline"}}>Shipping</h2>         
            <h3 className="question">What about the shipping charges? </h3>
            <p className="answer">For Online shopping through gemma.com.eg, there will be free shipping to orders within Cairo, Giza & Alexandria. For other Areas, charges will be calculated case by case. Please make sure to check shipping charges at the checkout page before completing your purchase. </p>
            <h3 className="question">Where is My Order? </h3>
            <p className="answer">Your order will be delivered by the expected date specified on your order. The expected delivery date is the date on or before which you can reasonably expect your order to arrive at your provided shipping address. </p>
            <h3 className="question">How to Track My Order? </h3>
            <p className="answer"> Tracking your shipment is possible through order page on gemma.com.eg or through our Hotline 16757.</p>
            <h3 className="question">What if there are Missing Items from My Order? </h3>
            <p className="answer">If you received an incomplete product or order with missing items or quantity, please write down the missing item or product or part of product on the order slip, and hand it out to the delivery driver, also you can do the same through our website gemma.com.eg or Hotline 16757. </p>
            <h3 className="question">What if I received broken Items in My Order? </h3>
            <p className="answer">If you received broken items within your order, the broken item or product or part of product should be written down on the order slip and handed out to the driver of the delivery truck, and another delivery date would be set to replace broken quantities. </p>
            <h3 className="question">How to Cancel an Order? </h3>
            <p className="answer">You can cancel your order/item on the order page or through our Hotline 16757. You will be entitled to receive a full refund of the price paid for the products only before it enters the shipping phase. If the products were shipped the shipping charges would be deducted even if the shipment was within the “Free shipping areas”. </p>
            <h3 className="question">What does the Return Policy entail? </h3>
            <p className="answer">Sold goods are subject to be returned or replaced <b>(before installation) </b> within 14 days, in case of manufacturing defect, or incompliance to the Egyptian Standards. After our assessment and validation process, you will be entitled to receive a full refund of the price paid for the product.<br/>
            <b>Note: All items will be assessed and validated before they are accepted for return and refund. If the stated issue is not found in the product, your claim for a refund will be rejected.</b> </p>
            <h3 className="question">How will I Get My Refund? </h3>
            <p className="answer">Your method of refund depends on the payment method you used when you purchased the item. If you paid through electronic means (Credit or Debit card) the paid amount would be refunded to your card. The amount paid may take up to a month to be reflected in your card statement, depending on the processing of your bank. </p>
             
             </div>
             </div>

              <div id="fa_q" className="faq" data-background="#3498db">
            <div className="question_answer">
            <h3>المساعدة بشأن طلبي</h3>
            <h2 style={{"text-decoration":"underline"}}>الدفع</h2>
            <h3 className="question">ما هي طرق الدفع المقبولة؟</h3>
            <p className="answer">تقبل الجوهرة الدفع ببطاقات الإئتمان و بطاقات الدين : ڤيزا و ماستر كارد</p>
            <h3 className="question">ماذا أفعل في حال (حيال) خصم تكاليف بشكل خاطئ من بطاقتي الإئتمانية؟</h3>
            <p className="answer"> يُرجى التواصل مع خطنا الساخن ١٦٧٥٧ برقم طلبك وسوف يقوم فريقنا بالاهتمام بشكواك</p>
            <h3 className="question">كيف أقوم بعرض طلبي؟</h3>
            <p className="answer">لعرض طلبك <ul><li>1)قم بتسجيل الدخول الي الموقع باستخدام بريدك الاكتروني وكلمة السر</li> <li>2). إضغط على زر <a href='/account-settings/order-history'> تاريخ و حالة الطلب </a> في خانة اعدادات الحساب الشخصي ستظهر لك تلقائياً كل طلباتك التي قمت بطلبها. </li></ul></p>
            <h2 style={{"text-decoration":"underline"}}>الشحن</h2>
            <h3 className="question">ماذا عن مصاريف (تكاليف) الشحن؟</h3>
            <p className="answer">للتسوق عبر الإنترنت من خلال الموقع سوف يكون الشحن مجاني للطلبات المتواجدة بالقاهرة والجيزة والاسكندرية. أما بالنسبة للمناطق الأخرى فسوف يتم حساب تكاليف الشحن بكل حالة على حِدة. من فضلك تأكد بمراجعة تكاليف الشحن بصفحة الدفع (الحساب) قبل إنهائك لعملية الشراء.  </p>
            <h3 className="question"> أين هو طلبي؟</h3>
            <p className="answer"> سيتم توصيل طلبك بحلول التاريخ المتوقع الذي سبق تحديده لطلبك. علماً بأن التاريخ المتوقع للتوصيل هو التاريخ الذي بإمكانك توقُع وصول طلبك فيه أو قبله وذلك إلى عنوان الشحن الذي قمت بتحديده.</p>
            <h3 className="question">كيف أقوم بتتبع طلبي؟</h3>
            <p className="answer">بإمكانك تتبع طلبك إما عن طريق صفحة الطلب على الموقع أو من خلال خطنا الساخن ١٦٧٥٧</p>
            <h3 className="question">ماذا لو أن هناك بعض العناصر المفقودة في طلبي؟</h3>
            <p className="answer"> في حال استلامك لمنتج أو طلب به عناصر أو كميات مفقودة، من فضلك قم بكتابة العنصر أو المنتج أو الجزء من المنتج الذي وجدته مفقوداً على إذن تسليم البضاعة وقم بإعطائها لسائق التوصيل، كذلك يمكنك فعل المثل من خلال موقعنا الالكتروني أو من خلال خطنا الساخن ١٦٧٥٧</p>
            <h3 className="question">ماذا لو قمت باستلام عناصر مكسورة في طلبي؟</h3>
            <p className="answer">في حال استلامك لعناصر مكسورة في طلبك، فإنه يتم كتابة العنصر أو المنتج أو الجزء من المنتج الذي وجدته مكسوراً على إذن تسليم البضاعة وإعطائها لسائق الشاحنة، وسوف يتم تحديد تاريخ توصيل آخر لاستبدال الكميات المكسورة</p>   
            <h3 className="question">كيف تقوم بإلغاء طلب؟!</h3>
            <p className="answer">بإمكانك إلغاء طلبك على صفحة الطلب أو من خلال خطنا الساخن ١٦٧٥٧. وسوف تكون مخولاً لاستلام كامل المبلغ الذي قمت بدفعه لقاء الحصول على المتنجات فقط قبل دخوله مرحلة الشحن، وفي حال ما إذا تم شحن المنتج؛ فإنه سيتم خصم تكاليف الشحن حتى وإن كان الشحن في نطاق مناطق الشحن المجاني</p>
            <h3 className="question">ما الذي تتضمنه سياسة الاسترجاع؟</h3>
            <p className="answer">تكون البضائع المباعة عُرضة للاستبدال أو الاسترجاع (قبل التركيب) خلال 14 يوماً، وذلك في حالة عيوب التصنيع أو عدم مقابلة المنتج للمواصفات المصرية.

بعد عملية المعاينة والتأكد من سلامة البضاعة. سوف تكون مخولاً لاستلام كامل المبلغ الذي قمت بدفعه لقاء الحصول على المتنج.
<br />
ملاحظة: جميع المنتجات سيتم معاينتها والتأكيد عليها قبل قبولها للارتجاع ورد المبلغ. وإذا لم تتواجد المشكلة المحددة؛ فإن طلبك باسترداد القيمة المدفوعة سوف يتم رفضه.</p>
            <h3 className="question">كيف سأقوم باسترداد أموالي؟</h3>
            <p className="answer">تعتمد طريقة استردادك لأموالك على طريقة الدفع التي قمت باستخدامها عند شرائك للمنتج. فإذا كنت قد دفعت عن طريقة الوسائل الالكترونية (بطاقة الإئتمان أو الدين) فإن القيمة المدفوعة سوف يتم ردها إلى بطاقتك. والقيمة المدفوعة ستستغرق مدة قد تصل إلى شهر لكي تظهر في كشف بطاقتك، وذلك اعتماداً على سرعة العمليات بالبنك الذي تتعامل معه.</p>
            
           </div>
           </div>
        </React.Fragment>  );
    }
}
 
export default OnlineShopping;