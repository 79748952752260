import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./css/bootstrap.css";
import "./css/style.css";
import "./App.css";
import Footer from "./Components/shop/Footer/Footer";
import FloatingHeader from "./Components/shop/FloatingHeader/FloatingHeader";
import HeaderMenu from "./Components/shop/HeaderMenu/HeaderMenu";
import { NotificationContainer } from "react-notifications";
import { getObjectWithToken } from "./services/CommonServices";
import { ToastContainer, toast } from "react-toastify";
import Banner from "./Banner";
import AppRoutes from "./AppRoutes";
import ReactGA from "react-ga";
import "./css/responsive.css";
import * as Sentry from "@sentry/browser";
ReactGA.initialize("UA-129806252-1");

class App extends Component {
  constructor(props) {
    super(props);
    Sentry.init({
      dsn: "https://f644d691999d474fae4b005fe5cc79eb@sentry.io/1334760"
    });
    this.onChangeCart = this.onChangeCart.bind(this);
    let isOpera =
      (!!window.opr && !!window.opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(" OPR/") >= 0;
    // Firefox 1.0+
    let isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window.safari !== "undefined" &&
            window.safari.pushNotification)
      );

    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    let isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    let isChrome = !!window.chrome && !!window.chrome.webstore;
    this.state = {
      cartLength: 0,
      showBanner: isEdge || isIE,
      error: null,
      user: {},
    };
  }
  onChangeCart(length) {
    if (length === 0) {
      this.setState({ cartLength: 0 });
    } else {
      this.componentDidMount();
    }
  }
  componentDidMount() {
    var outsideThis = this;
    if (localStorage.getItem("token")) {
      getObjectWithToken("users/cart").then(function(response) {
        if (response.data) {
          outsideThis.setState({
            cartLength: response.data.cart.products.length
          });
        } else {
          outsideThis.setState({ cartLength: 0 });
        }
      });
      getObjectWithToken("users/profile")
      .then((response) => {
          if (response.data) {
              outsideThis.setState({ user: response.data })
          }
      })
    } else {
      outsideThis.setState({ cartLength: 0 });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {

    console.log(this.state.user)
    if (this.state.error) {
      //render fallback UI
      return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>;
    } else {
      return (
        <div className="App">
          <Banner show={this.state.showBanner} />
          <FloatingHeader
            onChangeCart={this.onChangeCart}
            cartLength={this.state.cartLength}
            verified={this.state.user.verified}
          />
          <NotificationContainer />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
          <HeaderMenu />
          <div className="app__content">
            <AppRoutes onChangeCart={this.onChangeCart} />
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default App;
