import React from "react";
import './ShowroomLocator.css'
import { getAllObjects } from '../../../services/CommonServices'
import ShowMap from "../ShowMap/ShowMap";
import MapContainer from "../MapContainer/MapContainer";
import '../../../css/material-icons.css'

class ShowroomLocator extends React.Component {
    constructor(props) {
        super(props)
        this.changeView = this.changeView.bind(this)
        this.state = {
            showrooms: [],
            contact: [],
            list: false

        }
    }
    componentDidMount() {
        window.scroll(0, 0)
        const outsideThis = this;
        getAllObjects("showrooms", {})
            .then(function (response) {
                outsideThis.setState({ showrooms: response.data })
            })
    }
    changeView(value) {
        this.setState({
            list: value
        })
    }
    render() {
        return (
            <div id="showroom__locator" className="showroom-locator">
             <div className="viewselector__holder">
                    <ShowMap changeView={this.changeView} />
                </div>
                {
                    !this.state.list?(
                <div style={{paddingTop:80}} className="row">
                    {
                        this.state.showrooms.map((showroom, key) => (
                            <div className="col-md-4 col-sm-6">
                                <div className="showroom-locator__holder">
                                    <div className="showroom-locator__branch-name">
                                        <p>{showroom.name}</p>
                                    </div>
                                    <div className="showroom-locator__address">
                                        <p>Address: {showroom.address}</p>
                                    </div>
                                    {
                                        showroom.contact.map((contact, key) => (
                                            <div className="showroom-locator__phone">
                                                <p>{contact.contactType+":"}<span className="showroom-locator__phone-number">{contact.contactValue}</span> </p>
                                            </div>
                                        ))
                                        }
                                </div>
                            </div>
                        ))
                    }
                </div>
                    ):(<div className="map-holder">
                    <MapContainer objecttype={"showrooms"}/>
                    </div>)
                }
                {/* <div>
                    <Link to={{pathname: '/map', state: {objecttype:"showrooms"} }}><button type="button">Show map</button></Link>
                </div> */}
            </div>
        );
    }
}

export default ShowroomLocator;