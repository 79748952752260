import React from "react";
import "../../css/bootstrap.css";
import "./CompanyProfile.css";
import { Col, Row, Grid } from "react-bootstrap";
import { Link } from "react-router-dom";
import InvestorRelationsModal from "./InvestorRelations-Modal";

const reportsMap = [
  {
    name: "Stock Overview",
    topic: "stockoverview",
  },
  {
    name: "Announcements",
    topic: "announcements",
  },
  {
    name: "News",
    topic: "news",
  },
  {
    name: "Board of Directors",
    topic: "boardofdirectors",
  },
  {
    name: "Corporate Actions",
    topic: "corporateactions",
  },
  {
    name: "Disclosures",
    topic: "disclosures",
  },
  {
    name: "Governance",
    topic: "governance",
  },
  {
    name: "Calculator",
    topic: "calculator",
  },
  {
    name: "Company Profile",
    topic: "companyprofile",
  },
  {
    name: "Insider Transactions",
    topic: "insidertransactions",
  },
  {
    name: "IR Contacts",
    topic: "ircontacts",
  },
  {
    name: "Financial Statements",
    topic: "financialstatement",
  },
];

class CompanyProfile extends React.Component {
  state = {
    company_url: "",
    modal: false,
    modalName: "",
    topic: "",
  };
  componentDidMount = () => {
    window.scroll(0, 0);
  };

  render() {
    return (
      <div className="company-profile">
        <div className="wishlist__title ">
          <h2 className="main-line-title">Investor Relations</h2>
        </div>
        <div className="company-profile-bg">
          <Grid>
            <div className="company-profile__content">
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td className="left_en"></td>
                  </tr>
                </tbody>
              </table>
              <p>
                Al Ezz for Ceramics and Porcelain (‘‘GEMMA") (CASE: ECAP) is a
                Leading Ceramic producer in the Middle East and North Africa,
                with a total production capacity of 18 million square meters
                annually, encompassing a vast range of single-fired ceramic
                tiles. <br />
                <br />
                With nearly half of our production reaching the international
                market, GEMMA is transforming interiors on a global scale. Our
                distribution network, extending throughout the Middle-East, to
                Europe, North America and Asia, is admired for its reach and
                efficiency.
                <br />
                <br />
                Our Latest manufacturing facility established in 2009 placed us
                at the forefront of the global ceramics industry, enabling the
                production of unconventionally formatted tiles of exceptional
                durability. The Investor Relations section of this website
                contains financials, and announcements regarding GEMMA.
              </p>
              <h1 className="h1_en_ar">
                To Contact Investor Relations Department:
              </h1>
              <p>
                <a href="mailto:treasury@gemma.com.eg">treasury@gemma.com.eg</a>
              </p>
              <p></p>
              <table width="80%">
                <tbody>
                  <tr>
                    <td width="30%">
                      <b>GEMMA:</b>
                    </td>
                    <td width="30%">
                      <b>Tel:</b>
                    </td>
                    <td>
                      <b>Mobile:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Hatem Shaheen</td>
                    <td>+2 (02) 33778 868/9</td>
                    <td>01064666586</td>
                  </tr>
                  <tr>
                    <td>Ayman A. Nada</td>
                    <td>+2 (02) 33778 868/9</td>
                    <td>01001636056</td>
                  </tr>
                </tbody>
              </table>
              <p />
              <h1 className="h1_en_ar">Head Office</h1>
              <p>
                25 Cairo-Alexandria Dessert Road Remaya Square <br />
                Cairo
                <br />
                Egypt
                <br />
                Tel: + (202) 33778 868/9
                <br />
                Fax: +(202) 33778 850
                <br />
              </p>
              <Grid style={{ marginTop: 20 }}>
                <Row>
                  {reportsMap.map((report) => (
                    <Col md={3}>
                      <div className="investor__btn">
                        <button
                          style={{ whiteSpace: "normal" }}
                          type="button"
                          className="main-button collection__btn btn btn-default catalogue-btn"
                          onClick={() => {
                            this.setState({
                              modal: true,
                              modalName: report.name,
                              topic: report.topic,
                            });
                          }}
                        >
                          {report.name}
                        </button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Grid>
            </div>
          </Grid>
        </div>
        <InvestorRelationsModal
          showModal={this.state.modal}
          onModalHide={() => {
            this.setState({ modal: false });
          }}
          name={this.state.modalName}
          topic={this.state.topic}
        />
      </div>
    );
  }
}
export default CompanyProfile;
