import React from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-accessible-accordion/dist/minimal-example.css';
import './Relation.css'
import { withRouter, Redirect } from 'react-router-dom'
import { Grid, Col, PanelGroup, Panel, Row } from "react-bootstrap";
import get from 'lodash.get';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import config from '../../config'
import { groupBy } from 'lodash';
import { getAllObjects } from '../../services/CommonServices';
class FinancialReports extends React.Component {
    state = {
        reports: [],
        filteredReports: {}
    }
    componentDidMount() {
        window.scroll(0, 0)
        getAllObjects('reports', { type: this.props.match.params.id }, console.log(this.props.match.params.id))
            .then(async (response) => {
                this.setState({ reports: response.data })

                const filteredReports = groupBy(response.data, "year")
                await this.setState({ filteredReports: filteredReports })
            })






    }
    render() {
        function keyToCount(key) {
            if (key === 1) return "1st"
            if (key === 2) return "2nd"
            if (key === 3) return "3rd"
            return key + "th"
        }
        return (
            <div className="FinancialReports">
                <Grid>
                    <div className="wishlist__title ">
                        <h2 className="main-line-title">
                            {get(this.props.match, "params.id", "Coming Soon")}</h2>
                    </div>
                </Grid>
                <div className="Financial-Reports-bg">
                    <Grid>
                        <div className="Financial-Reports__content">
                            {
                                !config.relations[get(this.props.match, "params.id", undefined)] ? (
                                    <Redirect to="/investor-relations" />
                                ) : (
                                        <Accordion>
                                            <Row>
                                                {/* {config.relations[get(this.props.match, "params.id", undefined)].map((report) => (
                                                <Col className="financial-col" md={4}>

                                                    <AccordionItem>
                                                        <AccordionItemTitle>
                                                            <h3>{report.year}</h3>
                                                        </AccordionItemTitle>
                                                        <AccordionItemBody>
                                                            <PanelGroup accordion id="accordion-example">
                                                                {
                                                                    report.links.map((link, key) => (
                                                                        <Panel eventKey={key}>
                                                                            <Panel.Heading>
                                                                                <Panel.Title>{<a href={"/download/" + link.link} download>{link.label}</a>}</Panel.Title>
                                                                            </Panel.Heading>
                                                                        </Panel>
                                                                    ))
                                                                }
                                                            </PanelGroup>
                                                        </AccordionItemBody>
                                                    </AccordionItem>
                                                </Col>
                                            ))} */}
                                                {Object.keys(this.state.filteredReports).map((year, index) => (
                                                    <Col className="financial-col" md={4}>

                                                        <AccordionItem>
                                                            <AccordionItemTitle>
                                                                <h3>{year}</h3>
                                                            </AccordionItemTitle>
                                                            <AccordionItemBody>
                                                                {this.state.filteredReports[year].map((report, i) => (
                                                                    <PanelGroup accordion id="accordion-example">
                                                                        <Panel eventKey={0}>
                                                                            <Panel.Heading>
                                                                                <AccordionItem>
                                                                                    <p style={{fontSize:'21px', fontWeight:'bold'}}>{report.quarter ? (keyToCount(report.quarter) + " Quarter") : ''}</p>
                                                                                </AccordionItem>
                                                                                <Panel.Title>{<a href={report.file} download> {report.name}</a>}</Panel.Title>
                                                                            </Panel.Heading>
                                                                        </Panel>
                                                                    </PanelGroup>

                                                                ))}
                                                            </AccordionItemBody>
                                                        </AccordionItem>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Accordion>
                                    )
                            }
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}
export default withRouter(FinancialReports);
