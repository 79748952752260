import React, { Component } from 'react';
import MainShop from './Components/shop/MainShop/MainShop'
import Footer from './Components/shop/Footer/Footer'
import FloatingHeader from './Components/shop/FloatingHeader/FloatingHeader'
import HeaderMenu from './Components/shop/HeaderMenu/HeaderMenu'
import SingleProduct from './Components/shop/single-product/singleProduct'
import { Switch, Route,withRouter,Redirect } from 'react-router-dom';
import gridProduct from './Components/shop/single-product-grid-component/gridProduct';
import Home from './Components/shop/Home/Home';
import DesignYourOwn from './Components/shop/DesignYourOwn/DesignYourOwn';
import Cart from './Components/shop/Cart/Cart';
import ShowRooms from './Components/shop/ShowRooms/ShowRooms';
import Contact from './Components/shop/Contact/Contact';
import Distributors from './Components/shop/Distributors/Distributors';
import FAQ from './Components/shop/FAQ/FAQ';
import CustomerServices from './Components/CustomerServices/CustomerServices';
import Installation from './Components/Installation/Installation';
import OnlineShopping from './Components/OnlineShopping/OnlineShopping';
import LoyaltyFaq from './Components/LoyaltyFaq/LoyaltyFaq';
import ShowroomLocator from './Components/shop/ShowroomLocator/ShowroomLocator';
import ContactForm from './Components/shop/ContactForm/ContactForm';
import ContactInformation from './Components/shop/ContactInformation/ContactInformation';
import Login from './Components/Login/Login'
import MyAddresses from './Components/MyAddresses/MyAddresses'
import PersonalInformation from './Components/PersonalInformation/PersonalInformation'
import OrderHistory from './Components/OrderHistory/OrderHistory'
import RecentlyOrdered from './Components/RecentlyOrdered/RecentlyOrdered'
import NewsLetter from './Components/NewsLetter/NewsLetter'
import LoyalityPoints from './Components/LoyalityPoints/LoyalityPoints'
import AccountSettings from './Components/AccountSettings/AccountSettings'
import About from './Components/About/About'
import ProcessOrder from './Components/ProcessOrder/ProcessOrder'
import WishlistMain from './Components/shop/WishlistMain/WishlistMain'
import { NotificationContainer } from 'react-notifications';
import { getObjectWithToken } from './services/CommonServices'
import ShippingInformation from './Components/shop/ShippingInformation/ShippingInformation';
import Confirmation from './Components/shop/Confirmation/Confirmation';
import LoyaltyProgram from './Components/shop/LoyaltyProgram/LoyaltyProgram';
import Projects from './Components/Projects/Projects';
import CompanyProfile from './Components/CompanyProfile/CompanyProfile';
import Relation from './Components/Relation/Relation';
import Catalogues from './Components/Catalogues/Catalogues';
import MapContainer from './Components/shop/MapContainer/MapContainer'
import Terms from './Components/Terms/Terms'
import ForgetPass from './Components/ForgetPass/ForgetPass'
import Banner from './Banner'
import AppRoutes from './AppRoutes'
import ReactGA from 'react-ga'
class App extends Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps) {
        this.onRouteChanged();
    }

    onRouteChanged() {
        ReactGA.pageview(window.location.pathname + window.location.search);        
    }
    render() {
        return (
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/register' render={(props) => <Login {...props} onChangeCart={this.props.onChangeCart} />} />
                <Route path='/single-product/:id' render={(props) => <SingleProduct {...props} onChangeCart={this.props.onChangeCart} />} />
                <Route path='/grid-product' component={gridProduct} />
                <Route path='/shop' component={MainShop} />
                <Route path='/design-your-own' component={DesignYourOwn} />
                <Route path='/show-rooms' component={ShowRooms} />
                <Route path='/contact' component={Contact} />
                <Route path='/faq' component={FAQ} />
                <Route path='/customer-services' component={CustomerServices} />
                <Route path='/installation' component={Installation} />
                <Route path="/online-shopping" component={OnlineShopping} />
                <Route path="/loyalty-faq" component={LoyaltyFaq} />
                <Route path='/process-order' component={ProcessOrder} />
                <Route path='/showroom-locator' component={ShowroomLocator} />
                <Route path='/map' component={MapContainer} />
                <Route path='/distributors' component={Distributors} />
                <Route path='/contact-form' component={ContactForm} />
                <Route path='/contact-information' component={ContactInformation} />
                <Route path='/my-addresses' component={MyAddresses} />
                <Route path='/personal-information' component={PersonalInformation} />
                <Route path='/order-history' component={OrderHistory} />
                <Route path='/newsletter' component={NewsLetter} />
                <Route path='/loyality-points' component={LoyalityPoints} />
                <Route path='/recently-ordered' component={RecentlyOrdered} />
                <Route path='/account-settings' component={AccountSettings} />
                <Route path='/cart' render={(props) => <Cart onChangeCart={this.props.onChangeCart} {...props} />} />
                <Route path='/about' component={About} />
                <Route path='/wishlist-main' component={WishlistMain} />
                <Route path='/shipping-information' component={ShippingInformation} />
                <Route path='/confirmation' render={(props) => <Confirmation {...props} onChangeCart={this.props.onChangeCart} />} />
                <Route path='/loyalty-program' component={LoyaltyProgram} />
                <Route path='/projects' component={Projects} />
                <Route path='/investor-relations' component={CompanyProfile} />
                <Route path='/relation/:id' component={Relation} />
                <Route path='/catalogues' component={Catalogues} />
                <Route path='/terms' component={Terms} />
                <Route path='/forget-password' component={ForgetPass} />
                <Redirect to="/"/>
            </Switch>
        );
    }
}

export default withRouter(App);