import React from 'react';
import { toPrice, getDesign, toBoxPrice, updateObjectWithToken } from '../../../services/CommonServices'
import { Modal, Grid, Col, Row } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import { toast, Flip } from 'react-toastify';
class ProductModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            boxNumber: 1
        };
    }

    changeValue(price) {
        this.setState({ selectedPrice: price })
    }
    handleChangeBox(e) {
        this.setState({
            boxNumber: Math.ceil(e.target.value * 1),
            area: e.target.value * this.props.selectedProduct.boxSizeMeter,

        });
    }
    handleChangeArea(e) {
        this.setState({
            area: e.target.value * 1,
            boxNumber: Math.ceil(e.target.value / this.props.selectedProduct.boxSizeMeter)
        });
    }
    handleChange(e){
        this.setState({
            boxNumber: Math.ceil(e.target.value * 1),
        })
    }
    handleSubmit(e) {
        let bodyPro
        let body = {
            products: [{
                product: this.props.selectedProduct._id,
                quantity: this.state.boxNumber,
                tier: this.state.selectedPrice
            }],
            noParse: true
        }
        updateObjectWithToken('users/cart', body)
            .then((response) => {

                toast(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    transition: Flip,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    className: "sub-toast"
                });
                this.props.onHide()
            })
            .catch((err) => {
                toast('Something went wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    transition: Flip,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    className: "sub-toast"
                });
                this.props.onHide()
            })
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.selectedProduct) {
            return
        }
        let defaultPrice = ""


        if (nextProps.selectedProduct.prices.price_N) {
            defaultPrice = "price_N"
        }
        if (nextProps.selectedProduct.prices.price_1) {
            defaultPrice = "price_1"
        }
        if (nextProps.selectedProduct.prices.price_2) {
            defaultPrice = "price_2"
        }
        if (nextProps.selectedProduct.prices.price_3) {
            defaultPrice = "price_3"
        }

        let modalImages = []
        nextProps.selectedProduct.images.map((image, key) => {
            modalImages.push(
                {
                    original: image,
                    thumbnail: image,
                }
            )
        })
        this.setState({ selectedPrice: defaultPrice, modalImages: modalImages, area: Math.ceil(1 / nextProps.selectedProduct.boxSizeMeter) })
    }
    render() {
        if (!this.props.selectedProduct) {
            return ('')
        }
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <h3>{this.props.selectedProduct.name + " " + (this.props.selectedProduct.segmentId ? (getDesign(this.props.selectedProduct.segmentId)) : (''))}</h3>
                    <h5>Different faces for the same tile</h5>
                </Modal.Header>
                <Modal.Body>
                    <Grid className="modal-price__holder">
                        <Row>
                            <Col xs={12} md={8}>
                                <ImageGallery
                                    items={this.state.modalImages}
                                    showPlayButton={false}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="single-product__type secondry-font">
                                            <h3> choice </h3>
                                            <div className="single-product__form form-group">
                                                <div className="radio-button">
                                                    {this.props.selectedProduct.prices.price_3 ? (
                                                        <React.Fragment>
                                                            <input checked={this.state.selectedPrice === "price_3" ? (true) : (false)} onClick={(e) => { this.changeValue("price_3") }} type="radio" id="radio01" name="radio" />
                                                            <label for="radio01"><span></span>1</label>
                                                        </React.Fragment>) : ("")}

                                                    {this.props.selectedProduct.prices.price_2 ? (
                                                        <React.Fragment>
                                                            <input checked={this.state.selectedPrice === "price_2" ? (true) : (false)} onClick={(e) => { this.changeValue("price_2") }} type="radio" id="radio02" name="radio" />
                                                            <label for="radio02"><span></span>2</label>
                                                        </React.Fragment>) : ("")}

                                                    {this.props.selectedProduct.prices.price_1 ? (
                                                        <React.Fragment>
                                                            <input checked={this.state.selectedPrice === "price_1" ? (true) : (false)} onClick={(e) => { this.changeValue("price_1") }} type="radio" id="radio03" name="radio" />
                                                            <label for="radio03"><span></span>3</label>
                                                        </React.Fragment>) : ("")}

                                                    {this.props.selectedProduct.prices.price_N ? (
                                                        <React.Fragment>
                                                            <input checked={this.state.selectedPrice === "price_N" ? (true) : (false)} onClick={(e) => { this.changeValue("price_N") }} type="radio" id="radio04" name="radio" />
                                                            <label for="radio04"><span></span>N</label>
                                                        </React.Fragment>) : ("")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.selectedProduct.boxSizeMeter ? (
                                            <React.Fragment>
                                                <div className="col-md-6 col-xs-12">
                                                    <div className="single-product__type secondry-font">
                                                        <h3> box </h3>
                                                        <div className="single-product__qty-changer">
                                                            <input value={this.state.boxNumber} onChange={(e) => this.handleChangeBox(e)} className="single-product__btn form-group" type="number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xs-12">
                                                    <div className="single-product__type secondry-font">
                                                        <h3> area(m<sup style={{ fontSize: 16 }}>2</sup>)</h3>
                                                        <div className="single-product__qty-changer">
                                                            <input value={this.state.area} onChange={(e) => this.handleChangeArea(e)} className="single-product__btn form-group" type="number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="single-product__type secondry-font">
                                                        <h3> box </h3>
                                                        <div className="single-product__qty-changer">
                                                            <input value={this.state.boxNumber} onChange={(e) => this.handleChange(e)} className="single-product__btn form-group" type="number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                    <div className="col-md-12 col-xs-12">
                                        <div className="single-product__info">
                                            <p> Each box contains {this.props.selectedProduct.boxSize} tiles </p>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <div className="single-product__name price-number">
                                            <h3> Total: </h3>
                                            <h3> {toPrice(this.state.boxNumber * toBoxPrice(this.props.selectedProduct, this.props.selectedProduct.prices[this.state.selectedPrice])) + "EGP"} </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <button onClick={this.handleSubmit} className="single-product__icons-link  add-cart" href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hooray!"><i className="single-product__cart-icon material-icons list-product__compare-icon">add_shopping_cart <span> Add to Shopping Cart</span></i>  </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    }
}
export default ProductModal