import React from "react";
import "./MyAddresses.css";
import { getObjectWithToken, updateObjectWithToken } from '../../services/CommonServices'
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import config from "../../config";
class MyAddresses extends React.Component {
    constructor(props) {
        super(props)
        this.handleBillingChange = this.handleBillingChange.bind(this)
        this.handleShippingChange = this.handleShippingChange.bind(this)
        this.saveShipping = this.saveShipping.bind(this)
        this.saveBilling = this.saveBilling.bind(this)
        this.cities = Object.keys(config.shippingCosts).map((city) => ({ label: city, value: city }))
        this.state = {
            phone: "",
            billing: {},
            shipping: {}
        }
    }
    handleBillingChange(e) {
        let billingInfo = this.state.billing;
        billingInfo[e.target.id] = e.target.value;
        this.setState({ billing: billingInfo })
    }
    handleShippingChange(e) {
        let shippingInfo = this.state.shipping;
        shippingInfo[e.target.id] = e.target.value;
        this.setState({ shipping: shippingInfo })
    }
    saveShipping(event) {
        event.preventDefault();
        const requireds = ["address_1", "city", "country", "phone"];
        const names = ["Address Line 1", "City", "Country", "Phone Number"]
        for (let i = 0; i < requireds.length; i++) {
            const required = requireds[i];
            if (!this.state.shipping[required] || this.state.shipping[required] === "") {
                NotificationManager.error(names[i] + ' is required', 'Update Error', 3000)
                return;
            }

        }
        updateObjectWithToken("users/profile", { shipping: this.state.shipping })
            .then((response) => {
                if (!response.data || response.data.message !== "User updated") {
                    NotificationManager.error("Oops! A problem occured", 'Update Error', 3000)
                } else {
                    NotificationManager.success('Successfully updated your account', 'Successful Update!', 3000)
                }
            })
    }
    saveBilling(event) {
        event.preventDefault();
        const requireds = ["address_1", "city", "country", "phone"];
        const names = ["Address Line 1", "City", "Country", "Phone Number"]
        for (let i = 0; i < requireds.length; i++) {
            const required = requireds[i];
            if (!this.state.billing[required] || this.state.billing[required] === "") {
                NotificationManager.error(names[i] + ' is required', 'Update Error', 3000)
                return;
            }

        }
        updateObjectWithToken("users/profile", { billing: this.state.billing })
            .then((response) => {
                if (!response.data || response.data.message !== "User updated") {
                    NotificationManager.error("Oops! A problem occured", 'Update Error', 3000)
                } else {
                    NotificationManager.success('Successfully updated your account', 'Successful Update!', 3000)
                }
            })
    }
    componentWillMount() {
        const context = this;
        getObjectWithToken("users/profile")
            .then((response) => {
                if (response.data.error) {
                    //TODO: Handle error
                } else {
                    context.setState({
                        billing: {
                            phone: response.data.phone_number
                        },
                        shipping: {
                            phone: response.data.phone_number
                        }
                    })
                    if (response.data.billing) {
                        context.setState({
                            billing: { ...response.data.billing }
                        })
                    }
                    if (response.data.shipping) {
                        context.setState({
                            shipping: { ...response.data.shipping }
                        })
                    }
                }
            })
    }
    render() {
        return (
            <div className="col-md-9">
                <div className="user-border__right">
                    <div id="user-address" className="row user-accountــaddress">
                        <form onSubmit={this.saveShipping}>
                            <div className="col-md-6">
                                <h3> SHIPPING ADDRESS </h3>
                                <div className="user-account__line">
                                    <p>Address Line One <sup>*</sup> </p>
                                    <input id="address_1" onChange={this.handleShippingChange} value={this.state.shipping.address_1} className="contact-form__line-btn" placeholder="Address Line One" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Address Line Two </p>
                                    <input id="address_2" onChange={this.handleShippingChange} value={this.state.shipping.address_2} className="contact-form__line-btn" placeholder="Address Line Two" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Area</p>
                                    <input id="area" onChange={this.handleShippingChange} value={this.state.shipping.area} className="contact-form__name-btn" placeholder="Area" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>City <sup>*</sup></p>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Choose your city"
                                        value={this.cities.find((city) => city.value == this.state.shipping.city)}
                                        isClearable={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.cities}
                                        onChange={(selected) => this.handleShippingChange({ target: { id: "city", value: selected.value } })}
                                    />
                                </div>
                                <div className="user-account__line">
                                    <p>Country <sup>*</sup></p>
                                    <input id="country" onChange={this.handleShippingChange} value={this.state.shipping.country} className="contact-form__name-btn" placeholder="Egypt" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Phone <sup>*</sup></p>
                                    <input id="phone" onChange={this.handleShippingChange} value={this.state.shipping.phone} className="contact-form__name-btn" placeholder="Phone" type="text" />
                                </div>
                                <div className="user-account__address-btn">
                                    <button type="button" className="user-account__cancel-adress btn btn-default"> Cancel</button>
                                    <button type="submit" className="user-account__save-address btn btn-default">Save Address </button>
                                </div>
                            </div>
                        </form>
                        <hr className="hr-login" />
                        <form onSubmit={this.saveBilling}>
                            <div className="col-md-6">
                                <h3> BILLING ADDRESS </h3>
                                <div className="user-account__line">
                                    <p>Address Line One <sup>*</sup> </p>
                                    <input id="address_1" onChange={this.handleBillingChange} value={this.state.billing.address_1} className="contact-form__line-btn" placeholder="Address Line One" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Address Line Two </p>
                                    <input id="address_2" onChange={this.handleBillingChange} value={this.state.billing.address_2} className="contact-form__line-btn" placeholder="Address Line Two" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Area</p>
                                    <input id="area" onChange={this.handleBillingChange} value={this.state.billing.area} className="contact-form__name-btn" placeholder="Area" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>City <sup>*</sup></p>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Choose your city"
                                        value={this.cities.find((city) => city.value == this.state.billing.city)}
                                        isClearable={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.cities}
                                        onChange={(selected) => this.handleBillingChange({ target: { id: "city", value: selected.value } })}
                                    />
                                </div>
                                <div className="user-account__line">
                                    <p>Country <sup>*</sup></p>
                                    <input id="country" onChange={this.handleBillingChange} value={this.state.billing.country} className="contact-form__name-btn" placeholder="Egypt" type="text" />
                                </div>
                                <div className="user-account__line">
                                    <p>Phone <sup>*</sup></p>
                                    <input id="phone" onChange={this.handleBillingChange} value={this.state.billing.phone} className="contact-form__name-btn" placeholder="Phone" type="text" />
                                </div>
                                <div className="user-account__address-btn">
                                    <button type="button" className="user-account__cancel-adress btn btn-default"> Cancel</button>
                                    <button type="submit" className="user-account__save-address btn btn-default">Save Address </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default MyAddresses;