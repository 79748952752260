import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { toPrice } from '../../../services/CommonServices';
import config from '../../../config';
class TotalPayment extends React.PureComponent {
    state = {}
    render() {
        const rightSpanStyle = {
            float:'right'
        }
        const {
            subtotal,
            city,
            discount = 0,
        } = this.props

        const totalDiscount = (subtotal * discount)

        const subtotalAfterDiscount = subtotal - totalDiscount
        const totalWithShipping = subtotalAfterDiscount+config.shippingCosts[city]
        return (<Row>
            <Col md={12}>
                <h4>
                    <span>Subtotal</span><span style={rightSpanStyle}>{toPrice(subtotal)} EGP</span>
                </h4>
                <hr />
                {discount > 0 && (
                    <React.Fragment>
                        <h4>
                            <span>Loyalty Program Discount</span><span style={{ ...rightSpanStyle, color: 'rgb(212, 19, 13)' }}>-{toPrice(totalDiscount)} EGP</span>
                        </h4>
                        <hr />
                    </React.Fragment>
                )}
                <h4>
                    <span>Shipping</span><span style={rightSpanStyle}>{config.shippingCosts[city]===0?('Free'):(toPrice(config.shippingCosts[city])+" EGP")}</span>
                </h4>
                <hr />
                <h3>
                    <span>Total</span><span style={rightSpanStyle}>{toPrice(totalWithShipping)} EGP</span>
                </h3>
            </Col>
        </Row>);
    }
}

export default TotalPayment;