import React from "react";
import "./LoyalityPoints.css";
import { Link } from "react-router-dom"
import { getObjectWithToken, postObjectWithToken } from '../../services/CommonServices'
import Dropzone from 'react-dropzone'
import { NotificationManager } from 'react-notifications';
import Spinner from '../Loading/Loading'
import { Glyphicon } from "react-bootstrap";

class LoyalityPoints extends React.Component {
    constructor(props) {
        super(props)
        this.onDrop = this.onDrop.bind(this)
        this.state = {
            user: {

            }
        }
    }
    componentDidMount() {
        const outsideThis = this;
        getObjectWithToken("users/profile")
            .then((response) => {
                if (response.data) {
                    outsideThis.setState({ user: response.data })
                }
            })
    }
    onProgress(event) {
        console.log(event)
    }
    onDrop(acceptedFiles) {
        this.setState({uploading:true})
        let formData = new FormData()
        acceptedFiles.forEach(file => {
            formData.append("files", file)
        });
        postObjectWithToken("users/docs", formData, this.onProgress)
            .then((response) => {
                this.setState({uploading:false})
                NotificationManager.success('Files uploaded successfully', 'Success', 3000)
            })
            .catch((err) => {
                this.setState({uploading:false})
                NotificationManager.error('Oops something went wrong', 'Error', 3000)
            })
    }
    render() {
        return (
            <div>
                <div className="col-md-9">
                    <div className="user-border__right">
                        <div id="user-point" className="user-point">
                            <div style= {{display:'flex', flexDirection:'row'}} >
                                <p style={{marginRight: 10, fontFamily:'Montserrat-Medium', fontSize:28}}> Verified </p>
                                {console.log(this.state.user)||this.state.user.verified?
                                <Glyphicon glyph="ok-circle" style={{color:'green', fontSize: 28, lineHeight:1.4}}/>
                                :
                                <Glyphicon glyph="remove-circle" style={{color:'red', fontSize: 28, lineHeight:1.4}}/>
                                }
                            </div>
                            <div className="user-point__caption">
                                <p> You currenty have </p>
                                <h3>{this.state.user.loyalityPoints} </h3>
                            </div>
                            <div className="user-point__code">
                                <p>you can keep them for later or use them now by <br />
                                    entering your unique code in the checkout page
                                </p>
                                <h3>{this.state.user.loyalityId}</h3>
                            </div>
                            <div className="user-point__btn-holder">
                                <Link to="/loyalty-program">  <button type="button" className="user-point__info-btn btn btn-default"> Loyalty Program Information</button></Link>
                            </div>
                            <Dropzone
                                className="loyalty-drop"
                                onDrop={this.onDrop}
                            >
                                <h5>Drag and Drop your documents here or click here and CTRL + click on multiple files</h5>
                            </Dropzone>
                            {
                                this.state.uploading?(
                                    <Spinner />
                                ):('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoyalityPoints;