import React from "react";
import "./AccountSettings.css";
import "../../css/bootstrap.css";
import { Switch, Route, NavLink } from "react-router-dom";
import OrderHistory from '../OrderHistory/OrderHistory'
import NewsLetter from '../NewsLetter/NewsLetter'
import PersonalInformation from '../PersonalInformation/PersonalInformation'
import MyAddresses from '../MyAddresses/MyAddresses'
import LoyalityPoints from '../LoyalityPoints/LoyalityPoints'
import RecentlyOrdered from '../RecentlyOrdered/RecentlyOrdered'
import Slider from "react-slick";
class AccountSettings extends React.Component {
    componentWillMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push({
                pathname: '/register',
            })
        }
    }
    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <section className="user-account customize">
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <div className="contact-us__title col-md-12">
                    <h2 className="main-line-title">ACCOUNT </h2>
                </div>
                <div className="container">
                    <div className="row">
                        <Slider {...settings}>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings">ACCOUNT SETTINGS <br /> Personal info</NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings/my-addresses">ACCOUNT SETTINGS <br />  My Addresses  </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings/order-history">ORDER STATUS &amp; HISTORY</NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings/recently-ordered">RECENTLY ORDERED </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings/newsletter">Newsletter</NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink exact activeClassName="activeBold" to="/account-settings/loyality-points">LOYALTY PROGRAM</NavLink>
                                </li>
                            </div>
                        </Slider>
                        <div className="col-md-3">
                            {/* start contact-us-sidebar == */}
                            <div className="user-account__sidebar">
                                <ul className="menu">
                                    <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/">Personal information</NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/my-addresses"> My Addresses </NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/order-history">ORDER STATUS &amp; HISTORY</NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/recently-ordered">RECENTLY ORDERED </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/newsletter">Newsletter</NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink exact activeClassName="activeBold" to="/account-settings/loyality-points">LOYALTY PROGRAM</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Switch>
                            <Route path='/account-settings/my-addresses' component={MyAddresses} />
                            <Route path='/account-settings/order-history' component={OrderHistory} />
                            <Route path='/account-settings/recently-ordered' component={RecentlyOrdered} />
                            <Route path='/account-settings/newsletter' component={NewsLetter} />
                            <Route path='/account-settings/loyality-points' component={LoyalityPoints} />
                            <Route path='/account-settings/' component={PersonalInformation} />
                        </Switch>
                    </div>
                </div>
            </section >
        )
    }
}
export default AccountSettings;