import React from "react";
import GridProduct from '../single-product-grid-component/gridProduct'
import ListProduct from '../Single-product-list-component/ListProduct'
import SortBar from '../sort-bar/sortBar'
import Pagination from '../pagination/pagination'
import Sidebar from '../sidebar/sidebar'
import {
    getAllObjects,
    updateObjectWithToken,
    getObjectWithToken
} from '../../../services/CommonServices'
import Spinner from '../../Loading/Loading'
import './shop.css'
import Lightbox from 'react-images'
import ViewSelector from "../view_selector/viewSelector";
import queryString from 'querystring'

class MainShop extends React.Component {
    constructor() {
        super();
        this.onPageChange = this.onPageChange.bind(this)
        this.onSortClick = this.onSortClick.bind(this)
        this.onTypeChange = this.onTypeChange.bind(this)
        this.onColorChange = this.onColorChange.bind(this)
        this.resetClick = this.resetClick.bind(this)
        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrev = this.gotoPrev.bind(this);
        this.containsModel = this.containsModel.bind(this)
        this.onRoomChange = this.onRoomChange.bind(this)
        this.onResultsClick = this.onResultsClick.bind(this)
        this.onSizeChange = this.onSizeChange.bind(this)
        this.changeView = this.changeView.bind(this)
        this.onCollectionChange = this.onCollectionChange.bind(this)
        this.addModelToWishlist = this.addModelToWishlist.bind(this)
        this.setLightboxImages = this.setLightboxImages.bind(this)
        this.clickThumbnail = this.clickThumbnail.bind(this)
        this.onMaterialChange = this.onMaterialChange.bind(this)
        this.onStyleChange = this.onStyleChange.bind(this)
        this.onFinishChange = this.onFinishChange.bind(this)
        this.getModels = this.getModels.bind(this)
        this.getFilters = this.getFilters.bind(this)
        this.state = {
            currentPage: 0,
            models: [],
            type: [],
            rooms: [],
            size: [],
            ready: true,
            list: false,
            filtersReady: true,
            starColor: [],
            wishlist: {
                models: []
            },
            lightboxIsOpen: false,
            currentImage: 0,
            lightboxImages: [],
            allMaterials: [],
            materials: [],
            allStyles: [],
            allTypes: [],
            allColors: [],
            allSizes: [],
            styles: [],
            allFinishes: [],
            allRooms: [],
            finishes: [],
            Productslimit: 12,
            newCollection: queryString.parse(window.location.search.substring(1)).latest
        }
    }
    clickThumbnail(index) {
        this.setState({
            currentImage: index
        })
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrev() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    setLightboxImages(images) {
        let lightboxImages = []
        for (let i = images.length - 1; i >= 0; i--) {
            lightboxImages.push({
                src: images[i]
            })
        }
        this.setState({
            lightboxImages: lightboxImages
        }, () => {
            this.setState({
                lightboxIsOpen: true
            })
        })
    }
    onSortClick(sortBy) {
        this.setState({
            currentPage: 0,
            sortBy: sortBy
        }, this.getModels)
    }
    onResultsClick(Productslimit) {
        this.setState({
            Productslimit: Productslimit
        }, () => {
            this.onPageChange(this.state.currentPage)
        })
    }
    onPageChange(page) {
        window.scroll(0, 250)
        this.setState({
            currentPage: page
        }, this.getModels)
    }
    containsModel(wishlistModels, modelId) {
        for (let i = 0; i < wishlistModels.length; i++) {
            if (wishlistModels[i].model._id+"" === modelId+"") {
                return i;
            }
        }
        return -1;
    }
    addModelToWishlist(e, modelId, key) {
        e.preventDefault()
        if (!localStorage.getItem("token")) {
            this.props.history.push({
                pathname: '/register',
            })
            return;
        }
        let starArray = this.state.starColor
        let wishlistModels = this.state.wishlist.models //TODO: Need to check
        let modelIndex = this.containsModel(wishlistModels, modelId)
        if (modelIndex > -1) {
            starArray[key] = "#fff"
            wishlistModels.splice(modelIndex,1)
        } else {
            starArray[key] = "#ffcd18"
            wishlistModels.push({
                model: {_id:modelId}
            })
        }
        updateObjectWithToken('users', {
            wishlist: {
                models: wishlistModels
            }

        }).then(() => {
            this.setState({
                wishlist: {
                    models: wishlistModels
                },
                starColor: starArray
            })
        })
    }

    getModels() {
        let querySearch = queryString.parse(window.location.search.substring(1))
        if (!this.state.ready) {
            return;
        }
        this.setState({
            ready: false,
            newCollection: querySearch.latest
        })
        var outsideThis = this
        let query = {
            sortBy: this.state.sortBy,
            type: querySearch.type,
            color: querySearch.color,
            rooms: querySearch.room,
            materials: querySearch.material,
            styles: querySearch.style,
            finishes: querySearch.finish,
            size: querySearch.size,
            newCollection: querySearch.latest,
            search: querySearch.term?(querySearch.term.trim()):(querySearch.term),
            skip: this.state.currentPage,
            limit: this.state.Productslimit
        }
        getAllObjects("model/shop", query)
            .then(function (response) {
                outsideThis.setState({
                    models: response.data,
                    ready: true
                }, () => {
                    getObjectWithToken("users/wishlist")
                        .then(function (response) {
                            let wishlistModels = response.data.wishlist.models
                            let starArray = []
                            for (let i = 0; i < outsideThis.state.models.length; i++) {
                                let modelIndex = outsideThis.containsModel(wishlistModels, outsideThis.state.models[i]._id)
                                if (modelIndex > -1) {
                                    starArray[i] = "#ffcd18"
                                }
                            }
                            outsideThis.setState({
                                starColor: starArray,
                                wishlist: response.data.wishlist
                            })
                        })
                })
            })
    }
    getFilters() {
        let querySearch = queryString.parse(window.location.search.substring(1))
        if (!this.state.filtersReady) {
            return;
        }
        this.setState({
            filtersReady: false,
            newCollection: querySearch.latest
        })
        let query = {
            sortBy: this.state.sortBy,
            type: querySearch.type,
            color: querySearch.color,
            rooms: querySearch.room,
            materials: querySearch.material,
            styles: querySearch.style,
            finishes: querySearch.finish,
            size: querySearch.size,
            newCollection: querySearch.latest,
            skip: this.state.currentPage,
            limit: this.state.Productslimit,
            search: querySearch.term?(querySearch.term.trim()):(querySearch.term)
        }
        getAllObjects("model/filters", query)
            .then((response) => {
                this.setState({
                    allStyles: response.data.styles.sort(this.sortByCount),
                    allMaterials: response.data.materials.sort(this.sortByCount),
                    allFinishes: response.data.finishes.sort(this.sortByCount),
                    allTypes: response.data.types,
                    allRooms: response.data.rooms.sort(this.sortByCount),
                    allColors: response.data.colors.sort(this.sortByCount),
                    allSizes: response.data.sizes,
                    modelsLength: response.data.modelsLength,
                    filtersReady: true
                })
            })
    }
    sortByCount(a, b) {
        return b.count - a.count
    }
    componentDidMount(dontScroll) {
        if (!dontScroll) {
            window.scroll(0, 250)
        }
        this.getModels()
        this.getFilters()
    }
    onCollectionChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        if (!querySearch.latest) {
            querySearch.latest = true
        } else {
            delete querySearch.latest
        }
        this.props.history.push({
            pathname: "/shop",
            search: queryString.stringify(querySearch)
        })
    }
    onSizeChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentSize = querySearch.size
        if (currentSize === event.target.value) {
            delete querySearch.size;
            this.props.history.push({
                path: "shop",
                search: queryString.stringify(querySearch)
            })
            return
        }
        querySearch.size = event.target.value;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onTypeChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentTypes = querySearch.type ? (querySearch.type) : ([])
        let index;
        if (!(currentTypes instanceof Array)) {
            currentTypes = [currentTypes]
        }
        let found = currentTypes.find((item, i) => {
            index = i;
            return item == event.target.id
        });
        if (found) {
            currentTypes.splice(index, 1);
        } else {
            currentTypes.push(event.target.id);
        }
        querySearch.type = currentTypes;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onRoomChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentRooms = querySearch.room ? (querySearch.room) : ([])
        let index;
        if (!(currentRooms instanceof Array)) {
            currentRooms = [currentRooms]
        }
        let found = currentRooms.find((item, i) => {
            index = i;
            return item === event.target.id
        });
        if (found) {
            currentRooms.splice(index, 1);
        } else {
            currentRooms.push(event.target.id);
        }
        querySearch.room = currentRooms;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onMaterialChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentMaterials = querySearch.material ? (querySearch.material) : ([])
        let index;
        if (!(currentMaterials instanceof Array)) {
            currentMaterials = [currentMaterials]
        }
        let found = currentMaterials.find((item, i) => {
            index = i;
            return item === event.target.id
        });
        if (found) {
            currentMaterials.splice(index, 1);
        } else {
            currentMaterials.push(event.target.id);
        }
        querySearch.material = currentMaterials;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onStyleChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentStyles = querySearch.style ? (querySearch.style) : ([])
        let index;
        if (!(currentStyles instanceof Array)) {
            currentStyles = [currentStyles]
        }
        let found = currentStyles.find((item, i) => {
            index = i;
            return item === event.target.id
        });
        if (found) {
            currentStyles.splice(index, 1);
        } else {
            currentStyles.push(event.target.id);
        }
        querySearch.style = currentStyles;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onFinishChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentFinishes = querySearch.finish ? (querySearch.finish) : ([])
        let index;
        if (!(currentFinishes instanceof Array)) {
            currentFinishes = [currentFinishes]
        }
        let found = currentFinishes.find((item, i) => {
            index = i;
            return item === event.target.id
        });
        if (found) {
            currentFinishes.splice(index, 1);
        } else {
            currentFinishes.push(event.target.id);
        }
        querySearch.finish = currentFinishes;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    onColorChange(event) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        let currentColor = querySearch.color
        if (currentColor === event.target.id) {
            delete querySearch.color;
            this.props.history.push({
                path: "shop",
                search: queryString.stringify(querySearch)
            })
            return
        }
        querySearch.color = event.target.id;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            currentPage: 0
        }, () => {
            this.componentDidMount(false);
        })
    }
    resetClick() {
        const context = this;
        this.props.history.push({
            path: "shop",
            search: ''
        })
    }
    changeView(value) {
        this.setState({
            list: value
        })
    }
    handleSearch(term) {
        let querySearch = queryString.parse(window.location.search.substring(1))
        querySearch.term = term;
        this.props.history.push({
            path: "shop",
            search: queryString.stringify(querySearch)
        })
    }
    render() {
        return (
            <div style={{paddingBottom:30}} className="container-fluid no-padding__mobile">
                <div className="shop__title ">
                    <h2 className="main-line-title">PRODUCTS</h2>
                </div>
                <div className="viewselector__holder row">
                    <ViewSelector changeView={this.changeView} />
                </div>
                <div className="row">
                    <Sidebar
                        {
                        ...queryString.parse(window.location.search.substring(1))
                        }
                        onMaterialChange={this.onMaterialChange}
                        onStyleChange={this.onStyleChange}
                        onCollectionChange={this.onCollectionChange}
                        onFinishChange={this.onFinishChange}
                        materials={this.state.allMaterials}
                        styles={this.state.allStyles}
                        types={this.state.allTypes}
                        colors={this.state.allColors}
                        sizes={this.state.allSizes}
                        finishes={this.state.allFinishes}
                        rooms={this.state.allRooms}
                        models={this.state.models}
                        newCollection={this.state.newCollection}
                        selectedColor={this.state.color}
                        resetClick={this.resetClick}
                        onTypeChange={this.onTypeChange}
                        onSizeChange={this.onSizeChange}
                        onColorChange={this.onColorChange}
                        ready={this.state.filtersReady}
                        onRoomChange={this.onRoomChange} />
                    <div style={{zIndex:0}} className="col-md-9 col-sm-12 content">
                        <SortBar 
                            onSortClick={this.onSortClick} 
                            onResultsClick={this.onResultsClick} 
                            onSearch={this.handleSearch.bind(this)}
                        />
                        <Lightbox
                            backdropClosesModal
                            images={this.state.lightboxImages}
                            currentImage={this.state.currentImage}
                            isOpen={this.state.lightboxIsOpen}
                            onClickPrev={this.gotoPrev}
                            onClickNext={this.gotoNext}
                            preloadNextImage
                            showThumbnails
                            onClickThumbnail={this.clickThumbnail}
                            onClose={this.closeLightbox}
                        />
                        {!this.state.ready ?
                            (
                                <div style={{ marginTop: 200, marginBottom: 200 }}>
                                    <Spinner />
                                </div>
                            ) :
                            (
                                this.state.models.length === 0 ? (<h1 style={{ "color": "#333333", "font-family": "Baskerville",textAlign:'center' ,margin: "100px auto", width: "fit-content" }}>NO RESULTS FOUND</h1>) : (
                                    this.state.list ?
                                        (
                                            <ListProduct
                                                errorImage={require("../../../img/download.png")}
                                                addModelToWishlist={this.addModelToWishlist}
                                                containsModel={this.containsModel}
                                                history={this.props.history}
                                                wishlist={this.state.wishlist}
                                                starColor={this.state.starColor}
                                                models={this.state.models}
                                                page={this.state.currentPage}
                                                setLightboxImages={this.setLightboxImages} />
                                        ) : (
                                            <GridProduct
                                                errorImage={require("../../../img/download.png")}
                                                addModelToWishlist={this.addModelToWishlist}
                                                containsModel={this.containsModel}
                                                history={this.props.history}
                                                wishlist={this.state.wishlist}
                                                starColor={this.state.starColor}
                                                models={this.state.models}
                                                page={this.state.currentPage}
                                                setLightboxImages={this.setLightboxImages} />
                                        )
                                )
                            )
                        }
                        <Pagination
                            onPageChange={this.onPageChange}
                            currentPage={this.state.currentPage}
                            modelsLength={this.state.modelsLength}
                            page={this.state.currentPage}
                            Productslimit={this.state.Productslimit}
                        />

                    </div>

                </div>
            </div>
        );
    }
}

export default MainShop;