import React from "react"
import { getAllObjects, getWithQuery } from "../../../services/CommonServices"
import { Carousel, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./LoyaltyProgram.css"
import LoyaltyProducts from "../LoyaltyProducts/LoyaltyProducts"

class LoyaltyProgram extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
          <div>
                  <div className="loyaltyprogram__MainSlide">
                    <img
                      src={require("../../../img/loyalty/Salerno80x80Porclain2.jpg")}
                      className="img-responsive"
                    />
                  </div>
          </div>
        <section className="loyaltyprogram container">
       
          <div className="container">
            <Row>
              <div className="col-md-10 col-md-offset-1 col-xs-12">
               
                <div className="loyaltyWelecomeImage">
                  <div className="loyaltyprogram__title">
                    <h2 className="main-line-title">
                      Welcome to GEMMA Community
                    </h2>
                  </div>
                  <Row>
                    <div className="col-md-6 col-xs-12 col-sm-6">
                      <img
                        src={require("../../../img/loyalty/Amelia60x120.jpg")}
                        className="img-responsive"
                      />
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-6">
                      <div className="loyaltyprogram__Content">
                        <p>
                          GEMMA community is a loyalty program exclusive to the
                          design Community. Product discounts, special offers,
                          ideas and inspiration, and more. And best of all… it’s
                          FREE to join.
                        </p>
                      </div>
                    </div>
                  </Row>

                  <Row className="Reverse">
                    <div className="col-md-6 col-xs-12 col-sm-6">
                      <div className="loyaltyprogram__Content">
                        <p>
                        Whether
                   you’re a design studio / Architect  working
                  in the fields  of Architecture , Interior Design , Landscape, 
                  and turn-key projects, GEMMA s’ new program  is designed to  help
                  you provide your clients with complete de-signs solutions using 
                  only the best materials .
                  
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-6">
                      <img
                        src={require("../../../img/loyalty/Toscana60x120-1.jpg")}
                        className="img-responsive"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          </div>

          <div className="container">
            <div className="col-md-10 col-md-offset-1 col-xs-12">
              <Row>
                <div className="loyaltyprogram__title">
                  <h2 className="main-line-title">Benefits and rewards</h2>
                </div>
              </Row>
              <div className="loyaltyprogram__Slide">
                <Row>
                  <Row>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <img
                        src={require("../../../img/loyalty/Toscana 60x120-4.jpg")}
                        className="img-responsive"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <img
                        src={require("../../../img/loyalty/Salerno80x80 Porclain1.jpg")}
                        className="img-responsive"
                      />
                    </div>
                  </Row>
                </Row>
              </div>

              <div className="col-md-10 col-md-offset-1 col-xs-12">
                <div className="loyaltyreward">
                  <p>
                  
                    As a GEMMA Community member you have access to a number of
                    exciting member benefits, from discounted prices on GEMMA
                    products as well as valuable gifts and offers.
                  </p>
                </div>
              </div>

              <Row>
                <div className="col-md-8 col-md-offset-2 col-xs-12">
                  <div className="loyaltyIcons">
                    <Row>
                      <div className="col-md-6 col-sm-6 col-xs-6">
                        <img
                         
                          src={require("../../../img/loyalty/discount.svg")}
                          className="img-responsive discount-image"
                        />
                        <h3> Member Discount</h3>
                        <p> Because you deserv more </p>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-6">
                        <img
                          src={require("../../../img/loyalty/award.png")}
                          className="img-responsive"
                        />
                        <h3> Thank you gift</h3>
                        <p> We reward your loyalty </p>
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>

              <div className="row">
                <div className="loyaltyprogram__title col-md-12">
                  <h2 className="main-line-title">LOYALTY PROGRAM</h2>
                </div>
                <div className="loyaltyprogram__caption">
                  <p>
                    GEMMA appreciates its customers and that’s why we happily
                    offer you a loyalty program that will help you save on your
                    purchases. Read the details below.
                  </p>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="loyaltyprogram__holder">
                    <h5> Step 1 :</h5>
                    <h3>ADD PURCHASES</h3>
                    <p>
                    
                      Click on Register/Log in to create an account, save your
                      Gemma Community ID after receiving it, and add your purchase
                      invoice to the loyalty page in your account.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="loyaltyprogram__holder">
                    <h5> Step 2 :</h5>
                    <h3>VIEW YOUR POINTS</h3>
                    <p>
                      Go to your account and click on Loyalty Program to check
                      how many points you’ve collected
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="loyaltyprogram__holder">
                    <h5> Step 3 :</h5>
                    <h3>REDEEM AND SAVE</h3>
                    <p>
                      Once you have 2000 points or more, enter your Gemma
                      Community ID to exchange your points with a gift, the gift’s
                      value is deducted from the total number of your points.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="loyaltyprogram__btn">
                    <Link to="/account-settings/loyality-points">
                      <button
                        type="button"
                        className="loyaltyprogram__btn-goaccount btn btn-default"
                      >
                        Go to My Account
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="loyaltyprogram__caption">
                            <p>
                                For each 100 EGP you spend, you will receive 1 point. Each 1 point lets you save 10 EGP on your next purchases. Point can be combined together from different invoices, however, points cannot apply with other promocodes or discounts.
                                Nullam quis risus eget urna mollis ornare vel eu leo. Donec ullamcorper nulla non metus auctor fringilla.
                        </p>
                        </div> */}
            </div>
            <div className="loyaltyrewards">
              <Row>
                <div className="loyaltyprogram__title">
                  <h2 className="main-line-title">Gemma Community</h2>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <Col md={3}>
                    <div className="loyaltyrewards-title">
                      <h3>
                      
                        Gemma <br /> Community
                      </h3>
                    </div>
                  </Col>
                  <Col md={9}>
                    <p className="loyaltyrewards__content">
                    
                      Welcome to GEMMA Community – the exclusive GEMMA Loyalty
                      Program tailored solely for the design community. GEMMA Community gives you the
                      opportunity to get rewarded for what you spend with GEMMA,
                      offering you a wide range of valuable gifts to choose
                      from. The more you spend, the more points you get...the
                      greater the value of your gifts!
                      <br />
                      <b>
                        The Program Offers A Unique Set Of Rewards To 
                        <b>GEMMA’s </b> Community Customers:
                      </b>
                    </p>
                    <ul class="loyaltyrewards__menu">
                      <li>
                        1. Join <b>GEMMA</b>’s Community now by registering your personal data on the loyalty program at <a href="https://www.gemma.com.eg/register"> www.gemma.com.eg/register </a>. <b>GEMMA</b> will then send you an email, verifying your registration and notifying you with your <b>GEMMA</b> Community ID. Upon your first purchase from any of <b>GEMMA</b>’s showrooms, you should upload the invoice along with your Syndicate/ ID number. You’ll then get an email, with the current status of your points.
                      </li>
                      <li>
                      
                        2. The More you spend, the more you earn You will earn
                        1 Reward Point for every purchase value of 40 EGP of 1st
                        Choice and 80 EGP of 2nd Choice for example if you spend
                        4000 EGP on 1st choice tiles, you will get 100 points on
                        <b>GEMMA</b> Loyalty Card. Points are calculated within
                        14 days from the date of the last purchase. You will
                        receive an email with your total number of points.
                      </li>
                      <li>
                      
                        3. Once your points exceed 2000 points you can then
                        start redeeming the points by choosing one of our gifts
                        depending on your own needs.
                      </li>
                      <li>
                      
                        4. <b>GEMMA</b> Community card expires if not used within
                        24 months, calculated from the date of the first
                        purchase.
                      </li>
                      <li>
                      
                        5. The value of the gift is determined by the total
                        number of accumalted points Gifts include mobile
                        phones, laptops, and home appliances, among other types
                        of gifts.
                      </li>
                      <li>
                      
                        6. Once you redeem a gift, the gift's value is deducted
                        from the total number of your points.
                      </li>
                      <li>
                      
                        7. At the end of every Calendar Month, we calculate your
                        total points of the month.. Points are calculated after
                        fourteen days from the date of the last purchase. An
                        email or SMS will be sent with your total number of
                        points
                      </li>
                    </ul>
                    <hr />
                    <div className="loyalty-terms">
                      <p> Eligibility </p>
                      <h5>Gemma's Community card is offered only to:</h5>
                      <ul class="loyaltyterms__menu">
                        <li>
                        
                          1. An engineer (civil or architectural) actively
                          enrolled in the Egyptian Engineers Syndicate.
                        </li>
                        <li>
                        
                          2. An interior designer actively enrolled in the
                          Applied Arts Designers Syndicate.
                        </li>
                        <li>
                        
                          3. A fine art designer actively enrolled in the
                          Syndicate of Egyptian Fine Artists.
                        </li>
                        <li>
                        
                          4. An interior design office or contractor with a
                          commercial registration form and tax ID card.
                        </li>
                        <li>
                        
                          5. An Interior finishing contractor with ID.
                        </li>
                      </ul>
                    </div>
                    <div className="loyalty-terms">
                      <p> Terms and Conditions </p>
                      <ul class="loyaltyterms__menu">
                        <li>
                        
                          1. <b>GEMMA </b> Community card expires if not used
                          within 24 months, Calculated from the date of the last
                          purchase.
                        </li>
                        <li>
                        
                          2. Accumulated points cannot be redeemed in exchange
                          for cash.
                        </li>
                        <li>
                        
                          3. <b>GEMMA </b> has the right to withdraw or change
                          the terms of the <b>GEMMA </b> Community scheme at any
                          time and without prior notice.
                        </li>
                        <li>
                        
                          4. <b>GEMMA </b> sets the criteria on which the points
                          are calculated and accumulated and can change them
                          without prior notification to the members
                        </li>
                        <li> 5. Gifts are subject to availability.</li>
                        <li>
                        
                          6. <b>GEMMA </b> has the right to introduce
                          supplementary rules, new promotions and benefits to
                          the program without prior notification to the members.
                        </li>
                        <li>
                        
                          7. <b>GEMMA </b> has the right to change the value of
                          the gifts for all or any of the different accumulated
                          points without prior notification to the members.
                        </li>
                        <li>
                        
                          8. All benefits resulting from the <b>GEMMA </b>
                          Community scheme cannot be combined with any other sale
                          discounts or offers given at <b>GEMMA </b> showrooms,
                          Including premium card benefits.
                        </li>
                        <li>
                        
                          9. <b>GEMMA</b> will notify you with any changes to
                          your Gemma Community account via email.
                        </li>
                        <li>
                        
                          10. The above terms and conditions can be altered,
                          withdrawn or cancelled by <b>GEMMA</b> without any
                          prior notification to the members.
                        </li>
                      </ul>
                      <hr />
                      <div className="loyaltyrward-calls">
                        <h4>
                          <i class="material-icons">phone</i> For more
                          information
                        </h4>
                        kindly call Mr. Mohamed Said, Mobile:
                        <b>
                        
                          <a href="tel:0100 345 7074"> 0100 345 7074 </a>
                        </b>
                        <br />
                        For inquiries and suggestion, kindly call
                        <b>
                        
                          <a href="tel:16757">16757 </a>
                        </b>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </div>
          <LoyaltyProducts />
          <a href="#" className="scroll-to-top"></a>
          <div id="chartdiv"></div>
        </section>
      </React.Fragment>
    )
  }
}

export default LoyaltyProgram
