import React from "react";
import "./OrderHistory.css";
import { getObjectWithToken, toPrice } from '../../services/CommonServices'
import Spinner from '../Loading/Loading'
import { Link } from 'react-router-dom'
import { Panel, PanelGroup } from 'react-bootstrap'
class OrderHistory extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            Orders: [],
            ready: false
        };
    }

    componentDidMount() {
        var outsideThis = this
        getObjectWithToken("order/users")
            .then(function (response) {
                outsideThis.setState({ Orders: response.data, ready: true })
            })
    }
    render() {
        const statusToString = {
            0: "Ready",
            1: "Packed",
            2: "Shipped",
            3: "Completed"
        }
        return (
            <div className="col-md-9">
                <div className="user-border__right">
                    <div className="cart">
                        <div className="main-title">
                        </div>
                        {
                            !this.state.ready ?
                                (
                                    <div style={{ marginTop: 200, marginBottom: 200 }}>
                                        <Spinner />
                                    </div>
                                ) :
                                (

                                    <div className="tableorder">
                                        <div className="order_container">

                                            {
                                                this.state.Orders.length === 0 ? (
                                                    <h2 className="user-wishlist__no_order">You have not ordered anything yet<br /> <Link to={"/shop"}>Click Here</Link> to go to the shop</h2>
                                                ) : (
                                                        <div className="cart__table">

                                                            <PanelGroup accordion id="accordion-example">
                                                                {
                                                                    this.state.Orders.map((order, key) => (
                                                                        <Panel eventKey={key}>
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle><span className="order-table__id">Order ID: {order.orderId}</span><span className="order-table__date">{new Date(order.created_at).toDateString()}</span></Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="cart__color">Products</th>
                                                                                            <th className="cart__quantiy-box">Status</th>
                                                                                            <th className="cart__price">Total</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div className="cart__item-holder">
                                                                                                    {order.products.map((product, key) => (
                                                                                                        <h3>{product.product.name}
                                                                                                            <span>{" " +
                                                                                                                "(" + product.quantity + ")"}</span>
                                                                                                        </h3>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="cart__img-holder">
                                                                                                    <h3>{statusToString[Number(order.status)]}</h3>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="cart__quantity-holder">
                                                                                                    <h3>{toPrice(order.total) + " EGP"}</h3>
                                                                                                </div>
                                                                                            </td>
                                                                                            <hr />
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </Panel.Body>
                                                                        </Panel>
                                                                    ))
                                                                }
                                                            </PanelGroup>

                                                        </div>

                                                    )
                                            }
                                        </div>

                                    </div>
                                )
                        }
                    </div>

                </div>
            </div>
        )
    }
}
export default OrderHistory;