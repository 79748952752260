import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import './collection-product.css'
import '../../shop/single-product/single-product.css'
import { toPrice, getDesign, toBoxPrice,toCloudinary } from '../../../services/CommonServices'
import { Modal, Grid, Col, Row } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import Carousel from 'nuka-carousel';
import Slider from "react-slick";

class collectionProduct extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onModalHide = this.onModalHide.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            items: [],
            showModal: false,
            loop: false,
            nav: false,
            rewind: true,
            autoplay: true,
            dots: false,
            modalImages: [],
            boxNumber: 1,
            selectedPrice: "price_3",
            selectedProduct: {
                size: {
                    length: 0,
                    width: 0
                },
                prices: {
                }
            }
        };
    }
    // getStyle(length, width) {
    //     // if (length >= width) {
    //     //     let style = { marginTop: (100 - (200 * (width / length) / 2)), height: 200 * (width / length), width: 200 }
    //     //     return style
    //     // }
    //     let style = { marginTop: (100 - (200 * (width / length) / 2)), height: 200 * (width / length), width: 200 }
    //     return style
    // }
    componentWillReceiveProps(nextProps) {
        let items = []
        let modalImages = []
        if(nextProps.products.length === 0){
            return;
        }
        nextProps.products.map((product, key) => (
            (product.prices.price_1 || product.prices.price_2 || product.prices.price_3 || product.prices.price_N) ? (

                items.push(
                    <div key={key} className="collection-product__holder">
                        <span className="collection-product__item  item" onClick={() =>
                            this.props.onClickProduct(product)}>
                            <a className="collection-product__link">
                                <div className="collection-product__image">
                                    <img alt="product" onError={(e) => { e.target.src = require("../../../img/download.png") }} src={ (toCloudinary(product.images[0],null,150)) || (require("../../../img/download.png"))} />
                                </div>
                                <div className="collection-product__name">
                                    <p>{product.name + " " + getDesign(product.segmentId)} </p>
                                </div>
                                <div className="collection-product__size">
                                    <p>{product.size.length} x {product.size.width} </p>
                                </div>
                            </a>
                        </span>
                    </div>)
            ) : ('')
        ))
        this.setState({ modalImages: modalImages, items: items })
    }

    showModal(product, key) {
        let defaultPrice = ""


        if (product.prices.price_N) {
            defaultPrice = "price_N"
        }
        if (product.prices.price_1) {
            defaultPrice = "price_1"
        }
        if (product.prices.price_2) {
            defaultPrice = "price_2"
        }
        if (product.prices.price_3) {
            defaultPrice = "price_3"
        }

        let modalImages = []
        product.images.map((image, key) => {
            modalImages.push(
                {
                    original: image,
                    thumbnail: image,
                }
            )
        })
        this.setState({ selectedProduct: product, selectedPrice: defaultPrice, productIndex: key, modalImages: modalImages }, () => {
            this.setState({ showModal: true })
        })
    }

    changeValue(price) {
        this.setState({ selectedPrice: price })
    }
    handleChange(e) {
        this.setState({ boxNumber: e.target.value });
    }
    onModalHide() {
        this.setState({ showModal: false })
    }
    handleSubmit(e) {
        this.props.changeCount({ target: { value: this.state.boxNumber } }, this.state.productIndex)
        this.props.changePrice({ target: { value: this.state.selectedPrice } }, this.state.productIndex)
        this.props.handleSubmit(e)
        this.setState({ showModal: false })
    }
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: isMobile()?(Math.min(this.state.items.length,2)):Math.min(this.state.items.length,8),
            variableWidth:true,
            slidesToScroll: 1
          };
          function isMobile() {
            return window.screen.width <= 760;
          }
        return (
            <div className="collection-product">
                <div class="collection-product__title">
                    <h3>  Products in this collection </h3>
                </div>
                <div>
                    <div>
                        {this.state.items.length>0?(
                        <Slider {...settings}>
                            {this.state.items}
                        </Slider>
                        ):('')}
                        
                    </div>
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={this.onModalHide}
                >
                    <Modal.Header closeButton>
                        <h3>{this.state.selectedProduct.name + " " + (this.state.selectedProduct.segmentId ? (getDesign(this.state.selectedProduct.segmentId)) : (''))}</h3>
                        <h5>Different faces for the same tile</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid className="modal-price__holder">
                            <Row>
                                <Col xs={12} md={8}>
                                    <ImageGallery
                                        items={this.state.modalImages}
                                        showPlayButton={false}
                                    //  autoPlay
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-product__type secondry-font">
                                                <h3> choice </h3>
                                                <div className="single-product__form form-group">
                                                    <div className="radio-button">
                                                        {this.state.selectedProduct.prices.price_3 ? (
                                                            <React.Fragment>
                                                                <input checked={this.state.selectedPrice === "price_3" ? (true) : (false)} onClick={(e) => { this.changeValue("price_3") }} type="radio" id="radio01" name="radio" />
                                                                <label for="radio01"><span></span>1</label>
                                                            </React.Fragment>) : ("")}

                                                        {this.state.selectedProduct.prices.price_2 ? (
                                                            <React.Fragment>
                                                                <input checked={this.state.selectedPrice === "price_2" ? (true) : (false)} onClick={(e) => { this.changeValue("price_2") }} type="radio" id="radio02" name="radio" />
                                                                <label for="radio02"><span></span>2</label>
                                                            </React.Fragment>) : ("")}

                                                        {this.state.selectedProduct.prices.price_1 ? (
                                                            <React.Fragment>
                                                                <input checked={this.state.selectedPrice === "price_1" ? (true) : (false)} onClick={(e) => { this.changeValue("price_1") }} type="radio" id="radio03" name="radio" />
                                                                <label for="radio03"><span></span>3</label>
                                                            </React.Fragment>) : ("")}

                                                        {this.state.selectedProduct.prices.price_N ? (
                                                            <React.Fragment>
                                                                <input checked={this.state.selectedPrice === "price_N" ? (true) : (false)} onClick={(e) => { this.changeValue("price_N") }} type="radio" id="radio04" name="radio" />
                                                                <label for="radio04"><span></span>N</label>
                                                            </React.Fragment>) : ("")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12">
                                            <div className="single-product__type secondry-font">
                                                <h3> box </h3>
                                                <div className="single-product__qty-changer">
                                                    <input value={this.state.boxNumber} onChange={(e) => this.handleChange(e)} className="single-product__btn form-group" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12">
                                            <div className="single-product__name price-number">
                                                <p> Price </p>
                                                <h3> {toPrice(this.state.boxNumber * toBoxPrice(this.state.selectedProduct, this.state.selectedProduct.prices[this.state.selectedPrice])) + "EGP"} </h3>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12">
                                            <button onClick={this.handleSubmit} className="single-product__icons-link  add-cart" href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hooray!"><i className="single-product__cart-icon material-icons list-product__compare-icon">add_shopping_cart <span> Add to Shopping Cart</span></i>  </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default collectionProduct