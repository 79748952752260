import React from "react";
import './DesignYourOwn.css'
import '../../../css/material-icons.css'
import config from "../../../config"
class DesignYourOwn extends React.PureComponent {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
    render() {
      return (
        <div>
          {/* Start Section design-your-own  */} 
          <section className="container design-your-own ">
            <div className="design-your-own__wrapper row">
              <div className="col-md-10 col-md-offset-1">
                <div className="col-md-4">
                  <div className="design-your-own__title secondry-title">
                    <h2>Design <br/> Your Own </h2>
                  </div>
                  <div className="design-your-own__caption">
                    <p> Design Your Own, is a tool that offers you simple and innovative design program, that enables you to personalize your settings and make the most of GEMMA’s designs, textures, reliefs, colors and formats.</p>
                  </div>
                  <div className="design-your-own__btn large_screen">
                    <button type="button" onClick={()=>window.location.href=config.dyo+"?token="+localStorage.getItem("token")}className="design__btn btn btn-default">try it now</button>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className id="design-your-own__carousel-holder">
                    <div className="design-your-own__carousel carousel slide" id="myCarousel">
                      <div className="design-your-own__carousel-inner carousel-inner">
                        <div className="design-your-own__item active item" data-slide-number={0}>
                          <img src={require("../../../img/design-your-own/Catalogue 1.jpg")} className="img-responsive" />
                        </div>
                        <div className="design-your-own__item item" data-slide-number={1}>
                          <img src={require("../../../img/design-your-own/Divina 50x150 - Dark Grey Geometric II.jpg")} className="img-responsive" />
                        </div>
                        <div className="design-your-own__item item" data-slide-number={2}>
                          <img src={require("../../../img/design-your-own/Mia 20x60 - Anthracite Geometric Flower 4.jpg")} className="img-responsive" />
                        </div>
                        <div className="design-your-own__item item" data-slide-number={3}>
                          <img src={require("../../../img/design-your-own/Mystery 50x150 - Dark Blue.jpg")} className="img-responsive" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="row">
                    <div className="design-your-own__holder">
                      <ul className="design-your-own__menu">
                        <li className="design-your-own__item-active col-md-3 active col-xs-3">
                          <a id="carousel-selector-0" data-target="#myCarousel" data-slide-to={0}>
                            <img src={require("../../../img/design-your-own/Catalogue 1.jpg")} className="img-responsive" />
                          </a>
                        </li>
                        <li className="design-your-own__item col-md-3 col-xs-3">
                          <a className="design-your-own__ selector1" data-target="#myCarousel" data-slide-to={1}>
                            <img src={require("../../../img/design-your-own/Divina 50x150 - Dark Grey Geometric II.jpg")} className="img-responsive" />
                          </a>
                        </li>
                        <li className="design-your-own__item col-md-3 col-xs-3">
                          <a className="design-your-own__ selector2" data-target="#myCarousel" data-slide-to={2}>
                            <img src={require("../../../img/design-your-own/Mia 20x60 - Anthracite Geometric Flower 4.jpg")} className="img-responsive" />
                          </a>
                        </li>
                        <li className="design-your-own__item col-md-3 col-xs-3">
                          <a className="design-your-own__ selector3 carousel-selector-3" data-target="#myCarousel" data-slide-to={3}>
                            <img src={require("../../../img/design-your-own/Mystery 50x150 - Dark Blue.jpg")} className="img-responsive" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="design-your-own__btn for-mobile">
                    <button type="button" onClick={()=>window.location.href=config.dyo+"?token="+localStorage.getItem("token")} className="design__btn btn btn-default">try it now</button>
                  </div>

                  </div>
                </div>
              </div>
            </div></section>
          {/* End Section design-your-own  */} 
          {/* Javascripts
          ================================================== */}  
        </div>
      );
    }
  }
  export default DesignYourOwn;