import React from "react";
import './Loading.css'
import Spinner from 'react-spinkit'
// import {
//   ChasingDots,
//   Circle,
//   CubeGrid,
//   DoubleBounce,
//   FadingCircle,
//   FoldingCube,
//   Pulse,
//   RotatingPlane,
//   ThreeBounce,
//   WanderingCubes,
//   Wave
// } from 'better-react-spinkit'
class Loading extends React.Component {
  render() {
    return (
      // <FoldingCube className="loading" color="#B9B9B9" size={100} />
      <Spinner name='line-scale' fadeIn="none"  color="#b9b9b9" />      
    );
  }
}
export default Loading;