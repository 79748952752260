import React from "react";
import { Modal, Grid, Col, Row, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";

class InvestorRelationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "en",
    };
  }
  render() {
    return (
      <Row>
        <Col md={6} mdOffset={2}>
          <Modal
            show={this.props.showModal}
            onHide={this.props.onModalHide}
          >
            <Modal.Header closeButton>
              <h3>{this.props.name}</h3>
            </Modal.Header>
            <Modal.Body>
              <Grid className="modal-price__holder">
                <Row>
                  <Col md={12}>
                    <ButtonToolbar className="category-toolbar">
                      <ToggleButtonGroup
                        type="radio"
                        name="lang"
                        onChange={(e) => {
                          this.setState({ lang: e });
                        }}
                        defaultValue={this.state.lang}
                      >
                          <ToggleButton value="en">
                            English
                          </ToggleButton>
                          <ToggleButton value="ar">
                            عربي
                          </ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                    <iframe
                      height="600"
                      width={"100%"}
                      src={`https://ir.egidegypt.com/${this.state.lang}/${this.props.topic}/PghTEDhKP0Am_htAT_VzGnEFwvQGQcZEd1yzyLlIyMQ!`}
                    ></iframe>
                  </Col>
                </Row>
              </Grid>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    );
  }
}
export default InvestorRelationsModal;
