import React, { Component } from 'react';
import { Modal, Button, Grid, Col, Row } from 'react-bootstrap';
import {updateObjectWithToken} from '../../../services/CommonServices';
import { toast } from 'react-toastify';
import config from '../../../config';
import get from 'lodash.get';
class RedemptionModal extends Component {
    state = {}
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.show === false){
            return {
                show:false
            }
        }
    }
    handleRedeem = ()=>{
        updateObjectWithToken('users/redeem',{product:this.props.product._id})
        .then((response)=>{
            toast.success('Your purchase is successfull',config.toastSettings)
        })
        .catch((err)=>{
            toast.error(get(err,"response.data.message","Something went wrong"),config.toastSettings)
        })
        this.props.onHide()
    }
    render() {
        const rightSpanStyle = {
            float: 'right'
        }
        if (!this.props.product || !this.props.user) {
            return ''
        }
        const product = this.props.product
        return (
            <Modal {...this.props} bsSize="large" className="redeem">
                <Modal.Header closeButton>
                    <Modal.Title>Redeem Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid>
                        <Row>
                            <Col md={8}>
                                <div className="redeem-image__holder">
                                    <img src={product.featured_image} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="redeem-details">
                                    <div className="redeem-details__name">
                                        <h3>{product.name}</h3>
                                    </div>
                                    <div className="redeem-details__description">
                                        <p>{product.description}</p>
                                    </div>
                                    <div className="redeem-details__total">
                                        <h5>
                                            <span>Your Points</span><span style={rightSpanStyle}>{this.props.user.loyalityPoints} Points</span>
                                        </h5>
                                        <h5>
                                            <span>Cost</span><span style={rightSpanStyle}>-{product.points} Points</span>
                                        </h5>
                                        <hr />
                                        <h4>
                                            <span>New Points</span><span style={rightSpanStyle}>{this.props.user.loyalityPoints - product.points}  Points</span>
                                        </h4>
                                    </div>
                                    <Row>
                                        <Col md={5} mdOffset={2}>
                                            <Button onClick={this.props.onHide} className="grey-btn" block>
                                                Cancel
                                        </Button>
                                        </Col>
                                        <Col md={5}>
                                            <Button onClick={() => this.setState({ show: true })} className="black-btn" block>
                                                Redeem
                                        </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Modal {...this.props} onHide={() => this.setState({ show: false })} show={this.state.show} className="confirm-redeem">
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4>Are you sure you want to redeem {product.points} Points?</h4>
                            </Modal.Body>
                            <Modal.Footer>
                                <Row>
                                    <Col md={3} mdOffset={6}>
                                        <Button onClick={()=>this.setState({ show: false })} className="grey-btn" block>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col md={3}>
                                        <Button onClick={this.handleRedeem} className="black-btn" block>
                                            Confirm
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Modal>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RedemptionModal;