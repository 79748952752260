import React from "react";
import './FAQ.css'
import '../../../css/material-icons.css';

class FAQ extends React.Component {
    render() {
        return (

            <div id="fa_q" className="faq" data-background="#3498db">
                <div className="question_answer">
                    <h3 className="question"> When was GEMMA established?</h3>
                    <p className="answer"> GEMMA was established in 1989, as a subsidiary of AL Ezz Industrial Group, an experienced manufacturing group of building materials </p>
                    <h3 className="question"> Does GEMMA offer delivery service?</h3>
                    <p className="answer"> GEMMA provides delivery service showrooms to any place inside Egypt. Delivery fees are subject to the Quantity purchased and the Location of delivery. Kindly refer to our call centre to know the exact fees of delivering your purchases </p>
                    <h3 className="question"> Where is GEMMA factory located?</h3>
                    <p className="answer"> Gemma has two factories operating at ; Sadat City, Industrial zone # 1, Cairo Alexandria Road Sadat City, Industrial zone # 7, Cairo Alexandria Road </p>
                    <h3 className="question"> Does GEMMA export to other countries?</h3>
                    <p className="answer"> Gemma is continuously working on expanding it's distribution network as it exports 40 % of it's production on yearly basis ranging between the Middle East, Europe and North America.
  </p>
                    <h3 className="question">Is GEMMA a publicly traded company?</h3>
                    <p className="answer">GEMMA is publically listed on the Cairo and Alexandria Stock exchange (CASE) Trading symbol: ECAP </p>
                    <h3 className="question">How many employees does GEMMA have?</h3>
                    <p className="answer">GEMMA currently employees 1,842 employees offering them the best working environment. </p>
               </div>
            </div>

        );
    }
}

export default FAQ;