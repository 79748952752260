import React, { Component } from 'react';
import './sidebar.css'
import Tooltip from "react-simple-tooltip"
import { Panel, Button } from 'react-bootstrap'
import SortBar from '../sort-bar/sortBar'
import Spinner from '../../Loading/Loading'
import queryString from 'querystring'
class sidebar extends Component {

  constructor(props, context) {
    super(props, context);
    this.onSelectType = this.onSelectType.bind(this)
    this.onSelectColor = this.onSelectColor.bind(this)
    this.onSelectRoom = this.onSelectRoom.bind(this)
    this.onResetClick = this.onResetClick.bind(this)
    this.onSelectSize = this.onSelectSize.bind(this)
    this.showMore = this.showMore.bind(this)
    this.showMoreColor = this.showMoreColor.bind(this)
    this.showMoreRoom = this.showMoreRoom.bind(this)
    this.showMoreMaterial = this.showMoreMaterial.bind(this)
    this.showMoreStyle = this.showMoreStyle.bind(this)
    this.showMoreFinish = this.showMoreFinish.bind(this)
    this.onSelectMaterial = this.onSelectMaterial.bind(this)
    this.onSelectStyle = this.onSelectStyle.bind(this)
    this.onSelectFinish = this.onSelectFinish.bind(this)
    this.onSelectCollection = this.onSelectCollection.bind(this)

    this.state = {
      checked: [],
      checkedType: [],
      checkedMaterial: [],
      checkedStyle: [],
      checkedFinish: [],
      checkedRoom: [],
      checkedLatest: false,
      typeDisplay: "none",
      colorDisplay: "none",
      roomDisplay: "none",
      styleDisplay: "none",
      finishDisplay: "none",
      materialDisplay: "none",

    };
  }
  showMore(e) {
    e.preventDefault()
    this.setState({ typeDisplay: this.state.typeDisplay === "none" ? ("block") : ("none") })
  }
  showMoreColor(e) {
    e.preventDefault()
    this.setState({ colorDisplay: this.state.colorDisplay === "none" ? ("block") : ("none") })
  }
  showMoreStyle(e) {
    e.preventDefault()
    this.setState({ styleDisplay: this.state.styleDisplay === "none" ? ("block") : ("none") })
  }
  showMoreRoom(e) {
    e.preventDefault()
    this.setState({ roomDisplay: this.state.roomDisplay === "none" ? ("block") : ("none") })
  }
  showMoreMaterial(e) {
    e.preventDefault()
    this.setState({ materialDisplay: this.state.materialDisplay === "none" ? ("block") : ("none") })
  }
  showMoreFinish(e) {
    e.preventDefault()
    this.setState({ finishDisplay: this.state.finishDisplay === "none" ? ("block") : ("none") })
  }

  onSelectType(eventKey, key) {
    let checkedType = this.state.checkedType
    checkedType[key] = !checkedType[key]
    this.setState({ checkedType: checkedType })
    this.props.onTypeChange(eventKey)
  }
  onSelectSize(eventKey) {
    this.props.onSizeChange(eventKey)
  }
  onSelectMaterial(eventKey, key) {
    let checkedMaterial = this.state.checkedMaterial
    checkedMaterial[key] = !checkedMaterial[key]
    this.setState({ checkedMaterial: checkedMaterial })
    this.props.onMaterialChange(eventKey)
  }
  onSelectStyle(eventKey, key) {
    let checkedStyle = this.state.checkedStyle
    checkedStyle[key] = !checkedStyle[key]
    this.setState({ checkedStyle: checkedStyle })
    this.props.onStyleChange(eventKey)
  }
  onSelectFinish(eventKey, key) {
    let checkedFinish = this.state.checkedFinish
    checkedFinish[key] = !checkedFinish[key]
    this.setState({ checkedFinish: checkedFinish })
    this.props.onFinishChange(eventKey)
  }
  onSelectColor(eventKey) {
    this.props.onColorChange(eventKey)
  }
  onSelectRoom(eventKey, key) {
    let checkedRoom = this.state.checkedRoom
    checkedRoom[key] = !checkedRoom[key]
    this.setState({ checkedRoom: checkedRoom })
    this.props.onRoomChange(eventKey)
  }
  onSelectCollection(eventKey) {
    let checkedCollection = this.state.checkedLatest
    checkedCollection = !checkedCollection
    this.setState({ checkedLatest: checkedCollection })
    this.props.onCollectionChange(eventKey)
  }

  onResetClick() {
    this.setState({
      checked: [],
      checkedType: [],
      checkedStyle: [],
      checkedFinish: [],
      checkedMaterial: [],
      checkedRoom: []
    })
    this.props.resetClick()
  }

  checkFilters(objectType, identifier) {
    let filter = queryString.parse(window.location.search.substring(1))[objectType]
    if (filter instanceof Array) {
      const found = filter.find((object) => {
        return object === identifier
      })
      if (found) {
        return true;
      }
      return false;
    } else {
      return filter === identifier
    }
  }
  render() {

    return (
      <div className="col-md-3 col-sm-4">
        <div className="sidbar_mobile">

          <Button className="sidebar__toggle" onClick={() => this.setState({ open: !this.state.open })}>
            Filter/Sort
            <a class="single-collapse__btn btn-expand-collapse m-responsive"><span class={"glyphicon glyphicon-menu-" + (this.state.open ? ("right") : ("left"))}></span></a>

          </Button>

          <Panel id="collapsible-panel-example-1" expanded={this.state.open}>
            <Panel.Collapse>
              <Panel.Body>
                <div className="shop-main for-mobile">

                  <div className="shop-main__shop-left">
                    <div className="shop-main__head">
                      <h3> Filter </h3>
                    </div>
                    <div className="shop-main__shop-layered">
                      <div className="shop-main__filter-title">
                        <h4>Collection</h4>
                      </div>
                      <div className="shop-main__group">
                        <label className="shop-main__label control control--checkbox">
                          Latest
                          <input id="collection" checked={this.props.newCollection} onChange={(e) => { this.onSelectCollection(e) }} name="collection" type="checkbox"></input>
                          <div className="shop-main__controler control__indicator" />
                        </label>
                      </div>
                      <div className="shop-menu__select-holder">
                        <div className="shop-main__filter-title">
                          <h4>Size</h4>
                        </div>
                        <div className="shop-menu__select select">
                          <select onChange={this.onSelectSize}>
                            <option value={""}> Size ( in cm )</option>
                            {
                              this.props.sizes.map((size, key) => (
                                <option value={size._id}> {size.length + "x" + size.width}</option>
                              ))
                            }
                          </select>
                          <div className="select__arrow" />
                        </div>
                      </div>
                      <div className="shop-main__filter-title">
                        <h4> Product Type</h4>
                      </div>
                      <div className="shop-main__group">
                        {
                          this.props.types.map((type, key) => {
                            if (!type.count) {
                              return ('')
                            }
                            return (
                              <label style={{ display: key > 4 ? (this.state.typeDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                                {type.name}
                                <input id={type._id} name="type" checked={this.state.checkedType[type._id]} onChange={(e) => { this.onSelectType(e, type._id) }} type="checkbox"></input>
                                {/* <input id={type._id} name="type" onClick={this.onSelect}type="radio"></input> */}
                                {/* <span className="shop-menu__count"> 6 </span> */}
                                <div className="shop-main__controler control__indicator" />
                                <div className="shop-count">
                                  {type.count}
                                </div>
                              </label>
                            )
                          })
                        }
                        <button className="sidebar-button_more btn-layout eff-1" onClick={this.showMore}>Show {this.state.typeDisplay === "none" ? ("More") : ("Less")}</button>
                      </div>
                      {/* RESPONSIVE ROOM */}
                      <div className="shop-main__filter-title">
                        <h4> APPLICATION/ROOM</h4>
                      </div>
                      <div className="shop-main__group">
                        {
                          this.props.rooms.map((room, key) => (
                            <label style={{ display: key > 4 ? ("none") : ("block") }} className="shop-main__label control control--checkbox">
                              {room.name}
                              <input id={room._id} checked={this.state.checkedRoom[room._id]} name="room" onChange={(e) => { this.onSelectRoom(e, room._id) }} type="checkbox"></input>
                              <div className="shop-main__controler control__indicator" />
                              <div className="shop-count">
                                {room.count}
                              </div>
                            </label>
                          ))
                        }
                        {
                          this.props.rooms.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreRoom}>Show {this.state.roomDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                        }
                      </div>
                      <div className="shop-main__filter-title">
                        <h4>MATERIAL</h4>
                      </div>
                      <div className="shop-main__group">
                        {
                          this.props.materials.map((material, key) => (
                            <label style={{ display: key > 4 ? (this.state.materialDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                              {material.name}
                              <input id={material.name} onChange={(e) => { this.onSelectMaterial(e, material.name) }} checked={this.state.checkedMaterial[material.name]} name="material" type="checkbox"></input>
                              <div className="shop-main__controler control__indicator" />
                              <div className="shop-count">
                                {material.count}
                              </div>
                            </label>
                          ))
                        }
                        {
                          this.props.materials.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreMaterial}>Show {this.state.materialDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                        }
                      </div>
                      <div className="shop-main__filter-title">
                        <h4>STYLE</h4>
                      </div>
                      <div className="shop-main__group">
                        {
                          this.props.styles.map((style, key) => (
                            <label style={{ display: key > 4 ? (this.state.styleDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                              {style.name}
                              <input id={style.name} onChange={(e) => { this.onSelectStyle(e, style.name) }} checked={this.state.checkedStyle[style.name]} name="style" type="checkbox"></input>
                              <div className="shop-main__controler control__indicator" />
                              <div className="shop-count">
                                {style.count}
                              </div>
                            </label>
                          ))
                        }
                        {
                          this.props.styles.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreStyle}>Show {this.state.styleDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                        }
                      </div>
                      <div className="shop-main__filter-title">
                        <h4>FINISH</h4>
                      </div>
                      <div className="shop-main__group">
                        {
                          this.props.finishes.map((finish, key) => (
                            <label style={{ display: key > 4 ? (this.state.finishDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                              {finish.name}
                              <input id={finish.name} onChange={(e) => { this.onSelectFinish(e, finish.name) }} checked={this.state.checkedFinish[finish.name]} name="finish" type="checkbox"></input>
                              <div className="shop-main__controler control__indicator" />
                              <div className="shop-count">
                                {finish.count}
                              </div>
                            </label>
                          ))
                        }
                        {
                          this.props.finishes.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreFinish}>Show {this.state.finishDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                        }
                      </div>
                      <div className="shop-main__filter-title">
                        <h4>COLOR</h4>
                      </div>
                      <div className="row">
                        {
                          this.props.colors.map((color, key) => (
                            <div style={{ display: key > 19 ? (this.state.colorDisplay) : ("block") }} className="shop-menu__item  col-xs-3">
                              <div className="shop-menu__item-color" >
                                <Tooltip
                                  arrow={15}
                                  background="#000"
                                  border="#000"
                                  color="#fff"
                                  content={color.name}
                                  fadeDuration={400}
                                  fadeEasing="linear"
                                  fixed={false}
                                  fontFamily="inherit"
                                  fontSize="inherit"
                                  offset={0}
                                  padding={10}
                                  placement="top"
                                  radius={0}
                                  zIndex={1}
                                >
                                  <img className="shop-menu__color3" checked={this.state.checked[key]} id={color._id} onClick={this.onSelectColor} src={color.featured_image} />
                                </Tooltip>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreColor}>Show {this.state.colorDisplay === "none" ? ("More") : ("Less")}</button>
                      <div className="show-result">
                        <button onClick={() => { this.setState({ open: false }) }} type="button" class="showroom__btn-btn btn btn-default">Show Result</button>
                      </div>
                      <button onClick={this.onResetClick} className="sidebar-button_more btn-layout eff-1" >Reset</button>
                    </div>
                  </div>
                </div>

              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
        <div className="shop-main for-web header">
          <div className="shop-main__shop-left">
            <div className="shop-main__head">
              <h3>Filter results by</h3>
            </div>
            <div className="shop-main__shop-layered">

              {!this.props.ready ?
                (
                  <div style={{ marginTop: 200, marginBottom: 200 }}>
                    <Spinner />
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="shop-main__filter-title">
                      <h4>Collection</h4>
                    </div>
                    <div className="shop-main__group">
                      <label className="shop-main__label control control--checkbox">
                        Latest
                          <input id="collection" checked={this.props.newCollection} onChange={(e) => { this.onSelectCollection(e) }} name="collection" type="checkbox"></input>
                        <div className="shop-main__controler control__indicator" />
                      </label>
                    </div>
                    <div className="shop-menu__select-holder">
                      <div className="shop-main__filter-title">
                        <h4>Size</h4>
                      </div>
                      <div className="shop-menu__select select">
                        <select onChange={this.onSelectSize}>
                          <option value={""}> Size ( in cm )</option>
                          {
                            this.props.sizes.map((size, key) => (
                              <option value={size._id}> {size.length + "x" + size.width}</option>
                            ))
                          }
                        </select>

                        <div className="select__arrow" />
                      </div>
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>Product Type</h4>
                    </div>
                    <div className="shop-main__group">
                      {
                        this.props.types.map((type, key) => {
                          if (!type.count) {
                            return ('')
                          }
                          return (
                            <label style={{ display: key > 4 ? (this.state.typeDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                              {type.name}
                              <input id={type._id} name="type" checked={this.checkFilters('type', type._id)} onChange={(e) => { this.onSelectType(e, type._id) }} type="checkbox"></input>
                              {/* <input id={type._id} name="type" onClick={this.onSelect}type="radio"></input> */}
                              {/* <span className="shop-menu__count"> 6 </span> */}
                              <div className="shop-main__controler control__indicator" />
                              <div className="shop-count">
                                {type.count}
                              </div>
                            </label>
                          )
                        })
                      }
                      {
                        this.props.types.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMore}>Show {this.state.typeDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                      }
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>APPLICATION/ROOM</h4>
                    </div>
                    <div className="shop-main__group">
                      {
                        this.props.rooms.map((room, key) => (
                          <label style={{ display: key > 4 ? (this.state.roomDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                            {room.name}
                            <input id={room._id} checked={this.checkFilters('room', room._id)} name="room" onChange={(e) => { this.onSelectRoom(e, room._id) }} type="checkbox"></input>
                            <div className="shop-main__controler control__indicator" />
                            <div className="shop-count">
                              {room.count}
                            </div>
                          </label>
                        ))
                      }
                      {
                        this.props.rooms.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreRoom}>Show {this.state.roomDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                      }
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>MATERIAL</h4>
                    </div>
                    <div className="shop-main__group">
                      {
                        this.props.materials.map((material, key) => (
                          <label style={{ display: key > 4 ? (this.state.materialDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                            {material.name}
                            <input id={material.name} onChange={(e) => { this.onSelectMaterial(e, material.name) }} checked={this.checkFilters('material', material.name)} name="material" type="checkbox"></input>
                            <div className="shop-main__controler control__indicator" />
                            <div className="shop-count">
                              {material.count}
                            </div>
                          </label>
                        ))
                      }
                      {
                        this.props.materials.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreMaterial}>Show {this.state.materialDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                      }
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>STYLE</h4>
                    </div>
                    <div className="shop-main__group">
                      {
                        this.props.styles.map((style, key) => (
                          <label style={{ display: key > 4 ? (this.state.styleDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                            {style.name}
                            <input id={style.name} onChange={(e) => { this.onSelectStyle(e, style.name) }} checked={this.checkFilters('style', style.name)} name="style" type="checkbox"></input>
                            <div className="shop-main__controler control__indicator" />
                            <div className="shop-count">
                              {style.count}
                            </div>
                          </label>
                        ))
                      }
                      {
                        this.props.styles.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreStyle}>Show {this.state.styleDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                      }
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>FINISH</h4>
                    </div>
                    <div className="shop-main__group">
                      {
                        this.props.finishes.map((finish, key) => (
                          <label style={{ display: key > 4 ? (this.state.finishDisplay) : ("block") }} className="shop-main__label control control--checkbox">
                            {finish.name}
                            <input id={finish.name} onChange={(e) => { this.onSelectFinish(e, finish.name) }} checked={this.checkFilters('finish', finish.name)} name="finish" type="checkbox"></input>
                            <div className="shop-main__controler control__indicator" />
                            <div className="shop-count">
                              {finish.count}
                            </div>
                          </label>
                        ))
                      }
                      {
                        this.props.finishes.length > 5 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreFinish}>Show {this.state.finishDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                      }
                    </div>
                    <div className="shop-main__filter-title">
                      <h4>color</h4>
                    </div>
                    <div className="row">
                      {
                        this.props.colors.map((color, key) => (
                          <div style={{ display: key > 19 ? (this.state.colorDisplay) : ("block") }} className="shop-menu__item col-md-3">
                            <div className="shop-menu__item-color" >
                              <Tooltip
                                arrow={15}
                                background="#000"
                                border="#000"
                                color="#fff"
                                content={color.name}
                                fadeDuration={400}
                                fadeEasing="linear"
                                fixed={false}
                                fontFamily="inherit"
                                fontSize="inherit"
                                offset={0}
                                padding={10}
                                placement="top"
                                radius={0}
                                zIndex={1}
                              >
                                <img className={this.props.color === color._id ? ("shop-menu__item-color-active") : ('')} id={color._id} onClick={this.onSelectColor} src={color.featured_image} />
                              </Tooltip>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    {
                      this.props.colors.length > 20 ? (<button className="sidebar-button_more btn-layout eff-1" onClick={this.showMoreColor}>Show {this.state.colorDisplay === "none" ? ("More") : ("Less")}</button>) : ('')
                    }
                    <button onClick={this.onResetClick} className="sidebar-button_more btn-layout eff-1" >Reset</button>

                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default sidebar;