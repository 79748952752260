import React from "react";
import image from'./Gemma.png';
import image2 from'./Gemma2.png';
import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";

class Installation extends React.Component {
  state = {};
  render() {
    return(   <React.Fragment>
         <h3>Installation instructions </h3>
         <Player
             playsInline
             poster={image2}
             src="https://s3-eu-west-1.amazonaws.com/gemma-static/6-GEMMA+Installation+instructions.mp4"
             />
             <br />
             <h3>Installing large format tiles </h3>
             <Player
               playsInline
               poster={image}
               src="https://s3-eu-west-1.amazonaws.com/gemma-static/5-GEMMA+-+Installing+Large+Format+Tiles+(50x150cm).mp4"
               /> 
               <br />
    </React.Fragment>
            
   )
  }
}

export default Installation;
