import React from "react";
import './single-product.css'
import './calculator.css'
import CollectionProduct from '../collection-product/collectionProduct'
import { FacebookShareButton } from 'react-share';
import { Modal, Button, DropdownButton, MenuItem } from 'react-bootstrap'
import { toCloudinary, toPrice, toBoxPrice, getSingleObject, updateObjectWithToken, getObjectWithToken, getDesign } from '../../../services/CommonServices'
import { Redirect } from 'react-router'
import config from '../../../config'
import ProductModal from './productModal'
class singleProduct extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.changeCount = this.changeCount.bind(this);
        this.changePrice = this.changePrice.bind(this);
        this.addProductToCart = this.addProductToCart.bind(this)
        this.addModelToWishlist = this.addModelToWishlist.bind(this)
        if (!this.props.match.params.id) {
            this.state = {
                redirect: true,
            }
            return;
        }
        let model_id = this.props.match.params.id
        this.state = {
            products: [],
            model_id: model_id,
            model: {
                name:'',
                size: {length:'',width:''},
                products: [],
                images: []
            },
            displayPrices: [],
            starColor: "white",
            tiers: [],
        };
    }
    getCount(key) {
        if (!this.state.model.products[key].quantity) {
            this.state.model.products[key].quantity = 0;
        }
        return this.state.model.products[key].quantity;
    }
    changeCount(e, key) {
        if (e.target.value < 0) {
            return;
        }
        let newModel = this.state.model;
        newModel.products[key].quantity = e.target.value
        this.setState({ model: newModel })
    }
    changePrice(e, key) {
        let prices = this.state.displayPrices;
        if (e.target.value === '') {
            prices[key] = ''
            this.setState({ displayPrices: prices })
            return
        }
        let tiers = this.state.tiers
        tiers[key] = e.target.value
        prices[key] = (this.state.model.products[key].prices[e.target.value])
        let newModel = this.state.model;
        newModel.products[key].tier = e.target.value
        this.setState({ displayPrices: prices, tiers: tiers, model: newModel })
    }
    containsProduct(cartProducts, productId, tier) {
        for (let i = 0; i < cartProducts.length; i++) {
            if (cartProducts[i].product._id === productId && cartProducts[i].tier === tier) {
                return i;
            }
        }
        return -1;
    }
    containsModel(wishlistModels, modelId) {
        for (let i = 0; i < wishlistModels.length; i++) {
            if (wishlistModels[i].model._id+"" === modelId+"") {
                return i;
            }
        }
        return -1;
    }
    addProductToCart(e) {
        e.preventDefault();
        if (!localStorage.getItem("token")) {
            this.props.history.push({
                pathname: '/register',
            })
            return;
        }
        let cartProducts = this.state.cart.products;
        let products = this.state.model.products;
        for (let i = 0; i < products.length; i++) {
            if (products[i].quantity < 1 || !products[i].quantity || products[i].quantity === "" || products[i].quantity === 0 || products[i].tier === "") {
                continue;
            }
            let productIndex = this.containsProduct(cartProducts, products[i]._id, products[i].tier)
            if (productIndex > -1) {
                cartProducts[productIndex].quantity = parseInt(cartProducts[productIndex].quantity) + parseInt(products[i].quantity);
            } else {
                cartProducts.push({ product: products[i]._id, quantity: products[i].quantity, tier: products[i].tier })
            }
        }
        const context = this;
        updateObjectWithToken('users', {
            cart: {
                products: cartProducts
            }

        }).then(() => {
            if (this.props.onChangeCart) {
                this.props.onChangeCart()
            }
            context.componentDidMount(false);
        })
    }


    addModelToWishlist(e, modelId, key) {
        e.preventDefault()
        if (!localStorage.getItem("token")) {
            this.props.history.push({
                pathname: '/register',
            })
            return;
        }
        let starColor = ''
        let wishlistModels = this.state.wishlist.models //TODO: Need to check
        let modelIndex = this.containsModel(wishlistModels, this.state.model_id)
        if (modelIndex > -1) {
            starColor = "#fff"
            wishlistModels.splice(modelIndex,1)
        } else {
            starColor = "#ffcd18"
            wishlistModels.push({
                model: {_id:this.state.model._id}
            })
        }
        updateObjectWithToken('users', {
            wishlist: {
                models: wishlistModels
            }

        }).then(() => {
            this.setState({
                wishlist: {
                    models: wishlistModels
                },
                starColor: starColor
            })
        })
    }
    componentDidMount(dontScroll) {
        if (!dontScroll) {
            window.scroll(0, 0)
        }
        var outsideThis = this
        getObjectWithToken("users/cart")
            .then(function (response) {
                outsideThis.setState({ cart: response.data.cart })
            })
        getObjectWithToken("users/wishlist")
            .then(function (response) {
                let wishlistModels = response.data.wishlist.models
                let modelIndex = outsideThis.containsModel(wishlistModels, outsideThis.state.model_id)
                if (modelIndex > -1) {
                    outsideThis.setState({ starColor: "#ffcd18" })
                }
                outsideThis.setState({ wishlist: response.data.wishlist })
            })
        getSingleObject("model", this.state.model_id)
            .then(function (response) {
                if (response.data) {
                    for (let i = 0; i < outsideThis.state.tiers.length; i++) {
                        response.data.products[i].tier = outsideThis.state.tiers[i]
                    }
                    let displayPrices = [];
                    for (let i = 0; i < response.data.products.length; i++) {
                        if (response.data.products[i].prices.price_3) {
                            displayPrices[i] = response.data.products[i].prices.price_3;
                            response.data.products[i].tier = "price_3";
                        } else if (response.data.products[i].prices.price_2) {
                            displayPrices[i] = response.data.products[i].prices.price_2;
                            response.data.products[i].tier = "price_2";
                        }
                        else if (response.data.products[i].prices.price_1) {
                            displayPrices[i] = response.data.products[i].prices.price_1;
                            response.data.products[i].tier = "price_1";
                        }
                        else if (response.data.products[i].prices.price_N) {
                            displayPrices[i] = response.data.products[i].prices.price_N;
                            response.data.products[i].tier = "price_N";
                        }
                    }
                    outsideThis.setState({ model: response.data, displayPrices: displayPrices })
                }
            })
    }
    render() {
        if (this.state.redirect) {
            return (<Redirect to='/shop' />)
        }
        const model = this.state.model;
        return (
            <div className="single-product">
                <div className="container-fluid">
                        <div style={{position:'relative'}}>
                            <div id="carousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="item active" data-thumb="0">
                                        <img
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            id={"myImage"}
                                            onError={(e) => { e.target.src = require("../../../img/white.png") }}
                                            src={model.images[0] ? (toCloudinary(model.images[0],1440)) : ('')} />
                                    </div>
                                    <div className="item" data-thumb="0">
                                        <img src={require("../../../img/shop/15.png")} />
                                    </div>
                                    <div className="item" data-thumb="0">
                                        <img src={require("../../../img/shop/15.png")} />
                                    </div>
                                    <div className="item" data-thumb="0">
                                        <img src={require("../../../img/shop/15.png")} />
                                    </div>
                                </div>

                            </div>
                            <div id="single-modal__thumb-carousel thumbcarousel" className="carousel slide" data-interval="false">
                                <div className="single-modal__carousel-inner carousel-inner">
                                    <div className="single-modal__thumb-active  item active">
                                        {
                                            this.state.model.images.map((image, key) => (
                                                <div data-target="#carousel" data-slide-to="0" className="single-modal__thumb thumb">
                                                    <img className="single-modal__thumb-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={image ? (image) : (require("../../../img/white.png"))}
                                                        onClick={() => { document.getElementById('myImage').src = (image) }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="single-collapse">
                                <a onClick={(e) => { e.preventDefault(); window.toggleSide(e) }} href="#" className="single-collapse__btn btn-expand-collapse"><span className="glyphicon glyphicon-menu-left"></span></a>
                                <div className="single-product__details">
                                    <div className="text-center">

                                        <div className="single-product__details-holder">
                                            <div className="single-product__name">
                                                <h2>{model.name}</h2>
                                            </div>
                                            <div className="single-product__id-holder">
                                                    <p className="single-product__id">ID: {model.name + " " + model.size.length + "x" + model.size.width}</p>
                                                </div>
                                                <div className="row">
                                                    <div className="single-product__range-holder">
                                                        <div className="col-md-6 col-xs-6">
                                                            <div className="single-product__range secondry-font">
                                                                <div className="single-product__range-price secondry-font single-custom">
                                                                    <p> {
                                                                        model.minPrice
                                                                    } </p>
                                                                </div>
                                                                <div className="single-product__range-currency secondry-font">
                                                                    <p> egp per box</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="single-product__range-holder">
                                                        <div className="col-md-6">
                                                            <div className="single-product__range secondry-font">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* <div className="single-product__icons">
                                                
                                            </div> */}
                                            <div className="single-product__description">
                                                <p className="first__descripton"> {model.description ? (model.description) : ('No description')}</p>
                                                <div className="single-product__star for-mobile">
                                                    <div className="single-product__icons-wrapper col-md-12">
                                                        <div className="single-product__icons-menu">
                                                            <div className="row">
                                                                <a onClick={this.addModelToWishlist}
                                                                    className="single-product__icons-link my-favicon" href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hooray!">
                                                                    <i style={{ color: this.state.starColor }} className="material-icons single-product__fav-icon ">star_rate</i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="single-product__choose-tiles">
                                                <p>Choose your tiles </p>
                                            </div>
                                            <div className="single-product_quantity">
                                                {
                                                    this.state.model.products.map((product, key) => (
                                                        (product.prices.price_1 || product.prices.price_2 || product.prices.price_3 || product.prices.price_N) ? (<div className="row">
                                                            <div className="col-xs-4">
                                                                <div className="single-product__name">
                                                                    <h3> {product.name + " " + getDesign(product.segmentId)} </h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-2">
                                                                <div className="single-product__type secondry-font">
                                                                    <h3> choice </h3>
                                                                    <div className="single-product__form form-group">
                                                                        <select value={this.state.tiers[key]} onChange={(e) => this.changePrice(e, key)} className="dropdown">
                                                                            {product.prices.price_3 ? (<option value="price_3" >1</option>) : ('')}
                                                                            {product.prices.price_2 ? (<option value="price_2" >2</option>) : ('')}
                                                                            {product.prices.price_1 ? (<option value="price_1" >3</option>) : ('')}
                                                                            {product.prices.price_N ? (<option value="price_N" >N</option>) : ('')}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-2">
                                                                <div className="single-product__type secondry-font">
                                                                    <h3> box </h3>
                                                                    <div className="single-product__qty-changer">
                                                                        <input className="single-product__btn form-group" onChange={(e) => this.changeCount(e, key)} type="number" value={this.getCount(key)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-4">
                                                                <div className="single-product__name price-number">
                                                                    <p> Price </p>
                                                                    <h3> {this.state.displayPrices[key] ? (toPrice(toBoxPrice(this.state.model.products[key], this.state.displayPrices[key]) * this.state.model.products[key].quantity) + " EGP") : ('')}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ) : ('')
                                                    ))
                                                }
                                                <div className="col-md-12">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginLeft:55,marginTop:20,position:'absolute',bottom:0}} className="single-product__icons">
                                    <div className="single-product__icons-wrapper col-md-12">
                                        <div className="single-product__icons-menu">
                                            <div className="row">
                                                <a onClick={this.addModelToWishlist}
                                                    className="single-product__icons-link my-favicon" href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hooray!">
                                                    <i style={{ color: this.state.starColor }} className="material-icons single-product__fav-icon ">star_rate</i>
                                                </a>
                                                <a onClick={(e) => this.addProductToCart(e)} className="single-product__icons-link  add-cart" href="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hooray!"><i className="single-product__cart-icon material-icons list-product__compare-icon">add_shopping_cart <span> Add to Shopping Cart</span></i>  </a>
                                                <FacebookShareButton style={{ display: 'inline-flex', marginLeft: 10 }} quote={"Check out this model: " + model.name} url={config.SHARING_URL + "model/render/" + this.props.match.params.id} > <a className="single-product__facebook-btn  single-product-social__btn"></a> </FacebookShareButton>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="single-product__social-holder"> */}
                                    {/* <div className="single-product__social-media"> */}
                                    {/* <a className="single-product__twitter-btn single-product-social__btn" href="#" ></a> */}
                                    {/* <a className="single-product__pinterest-btn single-product-social__btn" href="#" ></a> */}
                                    {/* <a className="single-product__instagram-btn single-product-social__btn" href="#" ></a> */}
                                    {/* <a className="single-product__mail-btn single-product-social__btn" href="#" ></a> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                            <CollectionProduct
                                history={this.props.history}
                                changeCount={this.changeCount}
                                changePrice={this.changePrice}
                                onClickProduct={(product) => { this.setState({ selectedProduct: product, showModal: true }) }}
                                products={this.state.model.products}
                                handleSubmit={this.addProductToCart}
                            />
                        </div>
                    </div>
                <ProductModal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false }, this.props.onChangeCart)}
                    selectedProduct={this.state.selectedProduct}
                />
            </div>
        );
    }
}


export default singleProduct;
