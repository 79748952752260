import React from "react";
import './Terms.css'
import TermsText from './TermsText'
class Terms extends React.PureComponent {
    componentDidMount(){
        window.scroll(0,0)
    }
    render() {
        return (
            <div className="terms">
                <div className="contact-us__title ">
                    <h2 className="main-line-title">Terms & Conditions</h2>
                </div>
                <div className="terms__content">
                    <TermsText/>
                </div>
            </div>
        );
    }
}


export default Terms;

