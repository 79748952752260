import React from "react";
import  './FloatingHeader.css'
import {Link} from 'react-router-dom'
import NotificationBadge from 'react-notification-badge';
class FloatingHeader extends React.Component {
  constructor(props){
    super(props)
    this.state={
      cart:[]
    }
  }
    render() {
      const loginButton = localStorage.getItem("token")?(<li onClick={()=>{localStorage.removeItem("token");this.props.onChangeCart(0)}} className="floating-header__icon-signup"><Link to="/register">Logout</Link></li>):(<li className="floating-header__icon-signup"><Link to="/register">register/login</Link></li>)
      return (
        <div>
          <section className="floating-header">
          <div className="collapsible_navbar">
                       
                        <div className="collapse navbar-collapse custom_size" id="myNavbar">

            <nav className="floatin-header__navbar navbar navbar-default" role="navigation">
              <div className="floating-header__holder container-fluid">
              
                <div className="floating-header__collapse navbar-collapse">
                  <ul className="floating-header__social-media nav navbar-nav">
                    <li className="floating-header__icon"> <a href="https://www.facebook.com/GemmaCeramics"> <i className="fa fa-facebook" /> </a></li>
                    {/* <li className="floating-header__icon"> <a href="#"> <i className="fa fa-twitter" /> </a></li> */}
                    {/* <li className="floating-header__icon"> <a href="#"> <i className="fa fa-pinterest" /> </a></li> */}
                    <li className="floating-header__icon"> <a href="https://www.instagram.com/gemmaceramics/"> <i className="fa fa-instagram" /> </a></li>
                    {/* <li className="floating-header__icon"> <a href="#"> <i className="fa fa-youtube" /> </a></li> */}
                  </ul>
                  <ul className="floating-header__important-links nav navbar-nav navbar-right">
                    <li><Link to ="/contact/showroom-locator">showroom locator</Link></li>
                    <li className="floating-header__icon-favicon"> <Link to ="/wishlist-main"><i className="fa fa-star"></i></Link></li>                                                                           
                    <li className="floating-header__icon-favicon"> <Link to ="/account-settings"><i className="fa fa-user"></i></Link></li>
                    <li className="floating-header__icon-buyicon">
                      <NotificationBadge className="cart_badge" duration={0.7} style={{marginRight:2}} count={this.props.cartLength} effect={[null, null, {top:'-4px',zIndex:1,padding:0}, {top:'0px',zIndex:1,padding:0}]}/>
                      <Link to ="/cart">
                       <i className="fa fa-shopping-cart">
                       </i>
                      </Link>
                    </li>
                    {
                      loginButton
                    }          
                  </ul>                                           
                </div>
              </div>
            </nav>
            </div>
            </div>
          {this.props.verified && (
            <div style={{
              background: '#000',
              color: '#fff',
              position: 'absolute',
              bottom: '-30px',
              width: '100%',
              textAlign: 'center',
              fontFamily: 'Montserrat-regular',
              paddingTop: '7px',
            }}>
              <p>As a verified Gemma Community member you are eligible for an extra 10% discount at checkout. Redeem it now</p>
            </div>
          )}
          </section>
          {/* End  Section  floating Header */}
          {/* Javascripts
          ================================================== */}  
        </div>
        
      );
    }
}

export default FloatingHeader;