import React from "react";
import "./WishlistSidebar.css";
class WishlistSidebar extends React.PureComponent {
  render() {
    return (
        <div>
          <div className="wishlist-sidebar">
            <div className="col-md-3">
              <div className="wishlist__panel panel-group" id="accordion">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#wishlist">
                        <span className="caret" /> Your Wishlists
                      </a>
                    </div>
                  </div>
                  <div id="wishlist" className="panel-collapse collapse in">
                    <div className="panel-body">
                      <div className="wishlist__main-title">
                        <h4> <a>  Default wishlist  <span className="number"> (5) </span></a> </h4>
                      </div>
                      <p>shared with a friend </p>
                      <h4> <a>   Design Your Own 1 <span className="number"> (3) </span></a><a> </a></h4><a>
                      </a><h4><a></a><a>  Design Your Own 2 <span className="number"> (3) </span></a> </h4>
                      <h4> <a>  Sahle Home list <span className="number"> (5) </span> </a> </h4>
                    </div>
                  </div>
                  <div className="wishlist-sidebar__search">
                    <p> Search for an item in this list </p>
                    <form id="wishlist-sidebar__form">
                      <div className="wishlist-sidebar__input-holder">
                        <input type="text" className="wishlist-sidebar__btn" placeholder="Enter search term here" />
                      </div>
                      <button type="button" className="wishlist-sidebar__btn-serch btn btn-default">Search</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Javascripts
          ================================================== */}  
        </div>
      );
    }
    }
    export default WishlistSidebar;