import React from "react";
import "./Wishlist.css";
import {  getObjectWithToken, updateObjectWithToken, toCloudinary} from '../../../services/CommonServices'
import { Link } from 'react-router-dom'
import {Glyphicon} from 'react-bootstrap';

class Wishlist extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.minPrice = this.minPrice.bind(this)
    this.state = {
      wishlist: {
        models: [

        ]
      }
    }
  }
  componentDidMount() {
    getObjectWithToken("users/wishlist")
      .then((response) => {
        this.setState({ wishlist: response.data.wishlist })
      })
  }
  minPrice(model) {
    if (!model || !model.products) {
      return "No price found"
    }
    let minPrice = model.products[0].prices.price_1;
    for (let i = 1; i < model.products.length; i++) {
      if (!model.products[i].prices || !model.products[i].prices.price_1)
        continue;
      if (model.products[i].prices.price_1 < minPrice) {
        minPrice = model.products[i].prices.price_1;
      }
    }
    return minPrice
  }

  removeFromWishlist(key) {
    let wishlist = this.state.wishlist
    wishlist.models.splice(key, 1);
    this.setState({ wishlist: wishlist })
    updateObjectWithToken('users', {
      wishlist: {
        models: wishlist.models
      }
    })
  }
  render() {
    if (this.state.wishlist.models.length === 0) {
      return (
        <h3>YOUR WISHLIST IS EMPTY</h3>
      )
    }
    return (
      <div className="col-md-12">
        {
          this.state.wishlist.models.map((model, key) => (
            <div className="wishlist__item-holder">
              <div id="wishlist" className="wishlist">

                <div className="col-md-4 no-padding">
                  <div className="wishlist__main-holder">
                    <div className="wishlist__main-photo">
                    <Link className="wishlist__icons-link  add-cart" to={"/single-product/" + model.model._id}>
                      <img data-toggle="modal" data-target="#myModal" style={{ cursor: 'pointer' }} id="myImage" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={toCloudinary(model.model.images[0],null,400)} />
                    </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 wishlist__model-info">
                  <div className="wishlist__name">
                    <h4> {model.model.name} </h4>
                  </div>
                  <div className="wishlist__desc">
                    <h4> {model.model.description} </h4>
                  </div>

                  <div className="wishlist__range-holder">
                    <div className="col-md-6">

                      {/* <div className="wishlist__range secondry-font">
                        <p>from </p>
                        <div className="wishlist__range-price secondry-font">
                          <p> {model.model.products.prices && model.model.products.prices.price_1?(model.model.products.prices.price_1):("Unknown Price")} </p>
                          <p> {model.minPrice}</p>
                        </div>
                        <div className="wishlist__range-currency secondry-font">
                          <p> egp per box </p>
                        </div>
                      </div> */}

                    </div>
                  </div>
                  {/* <div className="wishlist__range-holder">
                  <div className="col-md-6">
                    <div className="wishlist__range secondry-font">
                      <p>from </p>
                      <div className="wishlist__range-price secondry-font">
                        <p> 867 </p>
                      </div>
                      <div className="wishlist__range-currency secondry-font">
                        <p> egp per box </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div className="wishlist__icons">
                    <div className="wishlist__icons-wrapper">
                      <div className="wishlist__icons-menu">
                        <Link className="wishlist__icons-link  add-cart" to={"/single-product/" + model.model._id}><i className="wishlist__cart-icon fa fa-shopping-cart"> <span>VIEW IN SHOP</span></i></Link>
                      </div>
                    </div>
                  </div>
                  <div className="wishlist__bottom-info">
                    <div className="wishlist__id-holder">
                      <p>Model ID: <span className="grid-produc__id">{model.model.name + " " + model.model.size.length + "x" + model.model.size.width} </span> </p>
                    </div>
                  </div>
                </div>
                <div onClick={()=>this.removeFromWishlist(key)} className="wishlist__item-icon col-md-2 col-md-offset-1">
                  <Glyphicon glyph="remove"></Glyphicon>
                </div>
              </div>
              {/* start  modal Slider  */}
              <div className="list-modal container">
                <div className="list-modal-wrapper modal fade" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                  <div className="list-modal__dialog modal-dialog" role="document">
                    <div className="list-modal__content modal-content">
                      <div className="list-modalــheader modal-header">
                        <button type="button" className="list-modal__close close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"> <img className="list-modal__close" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop-close.png")} /> </span></button>
                      </div>
                      <div className="list-modal__body modal-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <div id="carousel" className="carousel slide" data-ride="carousel">
                              <div className="list-modal__carousel carousel-inner">
                                <div className="list-modal__item item active">
                                  <img onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/1.png")} />
                                </div>
                                <div className="list-modal__item item">
                                  <img className="ist-modal__item-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/6.png")} />
                                </div>
                                <div className="list-modal__item item">
                                  <img className="ist-modal__item-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/7.png")} />
                                </div>
                                <div className="list-modal__item item">
                                  <img className="ist-modal__item-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/8.png")} />
                                </div>
                              </div>
                              <a className="list-modal__control-left carousel-control left" href="#carousel" data-slide="prev"><img className="list-modal__left-arrow arrow left-arrow" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/left-shop-arrow.png")} /></a>
                              <a className="list-modal__control-right carousel-control right" href="#carousel" data-slide="next"><img className="list-modal__right-arrow arrow right-arrow" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/right-shop-arrow.png")} /></a>
                            </div>
                            <div className="clearfix">
                              <div id="list-modal__thumb-carousel thumbcarousel" className="carousel slide" data-interval="false">
                                <div className="list-modal__carousel-inner carousel-inner">
                                  <div className="list-modal__thumb-active  item active">
                                    <div data-target="#carousel" data-slide-to={0} className="list-modal__thumb thumb">
                                      <img className="list-modal__thumb-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/1.png")} />
                                    </div>
                                    <div data-target="#carousel" data-slide-to={1} className="list-modal__thumb thumb">
                                      <img className="list-modal__thumb-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/6.png")} />
                                    </div>
                                    <div data-target="#carousel" data-slide-to={2} className="list-modal__thumb thumb">
                                      <img className="list-modal__thumb-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/7.png")} />
                                    </div>
                                    <div data-target="#carousel" data-slide-to={3} className="list-modal__thumb thumb">
                                      <img className="list-modal__thumb-photo" onError={(e) => { e.target.src = require("../../../img/white.png") }} src={require("../../../img/shop/8.png")} />
                                    </div>
                                  </div>
                                </div>
                                {/* /carousel-inner */}
                                {/*   <a class="left carousel-control" href="#thumbcarousel" role="button" data-slide="prev">
                          <span class="glyphicon glyphicon-chevron-left"></span>
                          </a>
                          <a class="right carousel-control" href="#thumbcarousel" role="button" data-slide="next">
                          <span class="glyphicon glyphicon-chevron-right"></span>
                          </a> */}
                              </div>
                              {/* /thumbcarousel */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End  modal Slider  == */}
              {/* End wishlist  */}
              {/* Javascripts
      ================================================== */}
            </div>
          ))
        }
      </div>
    );
  }
}
export default Wishlist;