import React from "react";
import Wishlist from '../Wishlist/Wishlist'
import WishlistSidebar from '../WishlistSidebar/WishlistSidebar'
class WishlistMain extends React.PureComponent {
    componentWillMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push({
                pathname: '/register',
            })
        }
    }
    render() {
        return (
            <div style={{ marginBottom: 60 }}>
                <div className="wishlist__title ">
                    <h2 className="main-line-title">WISHLIST </h2>
                </div>
                <div className="container">
                    <div className="row">
                        {/* <WishlistSidebar /> */}
                        <div className="wishlist-wrapper col-md-12">
                            {/* <Sortbar /> */}
                            <Wishlist />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WishlistMain;