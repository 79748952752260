import React from "react";
import './Distributors.css'
import { getAllObjects } from '../../../services/CommonServices'
import '../../../css/material-icons.css'
import ShowMap from "../ShowMap/ShowMap";
import MapContainer from "../MapContainer/MapContainer";
import uniqBy from 'lodash.uniqby'

class Distributors extends React.Component {
    constructor(props) {
        super(props)
        this.onSelectCity = this.onSelectCity.bind(this)
        this.filterDistributors = this.filterDistributors.bind(this)
        this.changeView = this.changeView.bind(this)
        this.state = {
            distributors: [],
            contact: [],
            cities: [],
            list: false
        }
    }
    componentDidMount() {
        window.scroll(0, 0)
        const outsideThis = this;
        getAllObjects("distributors", {})
            .then(function (response) {
                let cities = [];
                for (let i = 0; i < response.data.length; i++) {
                    cities.push(response.data[i].city)
                }
                const uniqueCities = uniqBy(cities)
                outsideThis.setState({ distributors: response.data, cities: uniqueCities })
            })
    }
    filterDistributors() {
        const outsideThis = this;
        let query = {
            city: this.state.city
        }
        getAllObjects("distributors", query)
            .then(function (response) {
                outsideThis.setState({ distributors: response.data })
            })

    }
    onSelectCity(event) {
        const context = this
        if (event.target.value === "") {
            this.setState({
                city: undefined,
            }, () => {
                context.filterDistributors();
            })
        } else {
            this.setState({
                city: event.target.value
            }, () => {
                context.filterDistributors();
            })
        }
    }

    changeView(value) {
        this.setState({
            list: value
        })
    }

    render() {
        return (


            < div className="distributors-eg clearfix" id="distributors__eg" >
                <div className="viewselector__holder">
                    <ShowMap changeView={this.changeView} />
                </div>
                {
                    !this.state.list ? (
                        <React.Fragment>
                        <div className="distributors-eg__title">
                            <h4>Select an area to begin </h4>
                        </div>
                        <div className="distributors-holder">
                            <div className="distributors-eg__holder col-md-4">
                                <div className="distributors__select select">
                                    <select onChange={this.onSelectCity}>
                                        <option value={""}>All</option>
                                        {
                                            this.state.cities.map((city, key) => (
                                                <option>{city}</option>
                                            ))
                                        }
                                    </select>
                                    <div className="select__arrow" />
                                </div>
                            </div>

                            {
                                this.state.distributors.map((distributor, key) => (
                                    <div className="col-md-4">
                                        <div className="distributors-eg-box__content">
                                            <div className="distributors-eg-box__holder">
                                                <div className="distributors-eg-box__branch-name">
                                                    <p> {distributor.name}</p>
                                                </div>
                                                <div className="distributors-eg-box__address">
                                                    <p>{distributor.address + " " + distributor.city}</p>
                                                </div>
                                                {
                                                    distributor.contact.map((contact, key) => (
                                                        <div className="distributors-eg-box__phone">
                                                            <p> {contact.contactType + ":"}<span className="distributors-eg-box__phone-number">{contact.number}</span> </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        </React.Fragment>
                ) : (<div className="map-holder">
                <MapContainer objecttype={"distributors"}/>
                </div>
            )
                }
                {/* <div>
                    <Link to={{ pathname: '/map', state: { objecttype: "distributors" } }}><button type="button">Show map</button></Link>
                </div> */}
            </div>

        );
    }
}


export default Distributors;