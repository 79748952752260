import React from 'react'
import './ForgetPass.css'
import config from '../../config'
import axios from 'axios'
import {postObject} from '../../services/CommonServices'
import {toast, Flip} from 'react-toastify'

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  transition: Flip,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  className: 'sub-toast'
}
export default class ForgetPass extends React.PureComponent {
  state = {
    passwrod: '',
    cpasswrod: ''
  }
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    window.scroll(0, 0)
  }
  handleSubmit = e => {
    e.preventDefault()
    if (this.state.passwrod !== this.state.cpasswrod) {
      toast.error(`Passwords do not match`, toastSettings)
      return
    }
    postObject('users/reset', {
      password: this.state.password,
      phone: this.props.location.state.phone
    }).then(response => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
        toast.success(response.data.message, toastSettings)
        this.props.history.push({
          pathname: '/account-settings'
        })
      }
    })
  }
  render() {
    return (
      <div className="terms">
        <div className="contact-us__title ">
          <h2 className="main-line-title">Reset Password</h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="container">
            <div className="forget-holder">
              <div className="login-now__btn-holder">
                <p>
                  Password <sup>*</sup>{' '}
                </p>
                <input
                  className="login-now__btn-password creat__placeholder"
                  placeholder="Password"
                  type="password"
                  value={this.state.password}
                  onChange={e => {
                    this.setState({password: e.target.value})
                  }}
                />
              </div>
              <div className="login-now__btn-holder">
                <p>
                  Confirm Password <sup>*</sup>{' '}
                </p>
                <input
                  className="login-now__btn-password creat__placeholder"
                  placeholder="Confirm password"
                  type="password"
                  value={this.state.cpassword}
                  onChange={e => {
                    this.setState({cpassword: e.target.value})
                  }}
                />
              </div>
              <div className="login-now__submit-holder">
                <button
                  type="submit"
                  class="login-now__submit-btn btn btn-default"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
