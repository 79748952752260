import {Flip} from 'react-toastify';

let config = {}
if (process.env.NODE_ENV === "development") {
    config = {
        API_URL: 'http://localhost:3000/',
        SHARING_URL: 'http://localhost:3000/',
        dyo: 'http://localhost:9000',
        CLDNRY_ID: "qpixio",
        MC_URL: 'https://qpix.us12.list-manage.com/subscribe/post-json?u=01ccf6f4a23736fe89dbb8955&id=441ebbb90a',
    }
} else {
    config = { //=== "production"
        API_URL: 'https://api.gemma.qpix.io/',
        SHARING_URL: 'https://social.gemma.com.eg/',
        dyo: 'https://dyo.gemma.com.eg/',
        CLDNRY_ID: "qpixio",
        MC_URL: 'https://qpix.us12.list-manage.com/subscribe/post-json?u=01ccf6f4a23736fe89dbb8955&id=441ebbb90a',
    }
}
config.toastSettings = {
    position: "top-right",
    autoClose: 5000,
    transition: Flip,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: "sub-toast"
}
config.designs = {
    "00": "Tiles",
    "01": "Listello Req",
    "02": "Mono Req",
    "03": "Tozzeto Req",
    "04": "Soccolo Req",
    "05": "Corner Req",
    "06": "Single Req",
    "07": "Torello Req",
    "09": "Stripe",
    "10": "Graniti Fiandre",
    "20": "Single",
    "21": "Listello",
    "22": "Pannel",
    "23": "Taco",
    "24": "Soccolo",
    "25": "Corner",
    "26": "Triangle",
    "27": "Torello",
    "28": "London",
    "29": "Fashia",
    "30": "Frame",
    "08": "Pannel Req",
    "11": "Frame Req",
    "12": "Stripes Req",
}
config.shippingCosts = {
    "Cairo":0,
    "Giza":0,
    "Alexandria":0,
    "Ismailia":1481,
    "Luxor":3674,
    "Beheira":830,
    "From Sidi Kerir to North Coast (Al-Hammam) (From k21 to k66)": 1221,
    "Dakahlia":1096,
    "Zafarana":1600,
    "Suez":1481,
    "Sharqia":1096,
    "Dabaa":1837,
    "10th of Ramadan City":830,
    "Obour City":830,
    "Arish":2726,
    "Ain Sokhna":1481,
    "Gharbia":830,
    "Hurghada":2726,
    "Fayoum":1108,
    "El Qoseir":3081,
    "Qalyubia":830,
    "Mahalla":830,
    "Mansoura":1096,
    "Monufia":830,
    "Minya":1837,
    "Bahariya Oasis":2726,
    "New Valley":4296,
    "Edfu":4385,
    "Esna":4385,
    "Abu Simbel":6755,
    "Aswan":4918,
    "Asyut":2441,
    "Beni Suef":1600,
    "Port Said":1659,
    "Porto Sokhna":1600,
    "Toshka":3022,
    "South Sinai":2726,
    "Damietta":1481,
    "Ras Sedr":2726,
    "Ras Gharib":2726,
    "Zefta":1096,
    "Safaga":3081,
    "Samanoud":1096,
    "Sohag":2933,
    "From North Coast (Al-Hammam) to Sidi Abd El-Rahman (From k66 to k145)":1481,
    "Sharm El-Sheikh":2726,
    "North Sinai":2726,
    "Taba":2726,
    "Tanta":830,
    "Qena":3437,
    "Kafr El-Sheikh":1096,
    "Marsa Alam":4266,
    "Marsa Matruh":2015,
    "Mit Ghamr":1096,
    "Nuweibaa":2726
}
config.relations = {
    "board-meetings": [{
            year: 2015,
            links: [{
                label: 'Minutes of board meeting 12-Aug-2015',
                link: '2015/Board meetings/Minutes of board meeting 12-Aug-2015.pdf'
            }, {
                label: 'Minutes of board meeting 12-Nov-2015',
                link: '2015/Board meetings/Minutes of board meeting 12-Nov-2015.PDF'
            }]
        }, {
            year: 2016,
            links: [{
                label: 'Minutes of board meeting 20-Mar-2016',
                link: '2016/Board meetings/Minutes of board meeting 20-Mar-2016.PDF'
            }, {
                label: 'Minutes of board meeting 23-May-2016',
                link: '2016/Board meetings/Minutes of board meeting 23-May-2016.pdf'
            }, {
                label: 'تقرير مجلس الإدارة 31-12-2016',
                link: '2016/Board meetings/تقرير مجلس الإدارة 31-12-2016.PDF'
            }, {
                label: 'ملخص قرارات مجلس الإدارة 13-11-2016',
                link: '2016/Board meetings/ملخص قرارات مجلس الإدارة 13-11-2016.PDF'
            }]
        },
        {
            year: 2017,
            links: [{
                label: 'تقرير مجلس الإدارة 31-12-2017',
                link: '2017/Board Meetings/تقرير مجلس الإدارة 31-12-2017.pdf'
            }, {
                label: 'ملخص قرارات مجلس الإدارة 03-04-2017',
                link: '2017/Board Meetings/ملخص قرارات مجلس الإدارة 03-04-2017.PDF'
            }, {
                label: 'ملخص قرارات مجلس الإدارة 09-08-2017',
                link: '2017/Board Meetings/ملخص قرارات مجلس الإدارة 09-08-2017.PDF'
            }, {
                label: 'ملخص قرارات مجلس الإدارة 11-05-2017',
                link: '2017/Board Meetings/ملخص قرارات مجلس الإدارة 11-05-2017.PDF'
            }, {
                label: 'ملخص قرارات مجلس الإدارة 13-11-2017',
                link: '2017/Board Meetings/ملخص قرارات مجلس الإدارة 13-11-2017.PDF'
            }]
        }, {
            year: 2018,
            links: [{
                label: 'ملخص قرارات مجلس الأدارة 12-11-2018',
                link: 'Board Meeting/ملخص قرارات مجلس الأدارة 12-11-2018.pdf'
            }, {
                label: 'ملخص قرارات مجلس الأدارة 12-03-2018',
                link: '2018/Board Meetings/ملخص قرارات مجلس الأدارة 12-03-2018.PDF'
            }, {
                label: 'ملخص قرارات مجلس الأدارة 14-05-2018',
                link: '2018/Board Meetings/ملخص قرارات مجلس الأدارة 14-05-2018.pdf'
            }]
        }
    ],
    "disclosures": [

        {
            year: 2015,
            links: [{
                    label: 'Audit committee report Dec-2015',
                    link: '2015/Disclousre/Audit committee report Dec-2015.pdf'
                },
                {
                    label: 'Audit committee report Jun-2015',
                    link: '2015/Disclousre/Audit committee report Jun-2015.pdf'
                }, {
                    label: 'Audit committee report Sep-2015',
                    link: '2015/Disclousre/Audit committee report Sep-2015.pdf'
                }, {
                    label: 'Cash Dividend ',
                    link: '2015/Disclousre/Cash Dividend .PDF'
                }, {
                    label: 'Cash Dividend2015',
                    link: '2015/Disclousre/Cash Dividend2015.PDF'
                }, {
                    label: 'Dividends Declared2105',
                    link: '2015/Disclousre/Dividends Declared2105.pdf'
                }, {
                    label: 'Shareholder structure Dec-2015.pdf',
                    link: '2015/Disclousre/Shareholder structure Dec-2015.pdf'
                }, {
                    label: 'Shareholder structure Jun-2015.pdf',
                    link: '2015/Disclousre/Shareholder structure Jun-2015.pdf'
                }, {
                    label: 'Shareholder structure Sep-2015.pdf',
                    link: '2015/Disclousre/Shareholder structure Sep-2015.pdf'
                }
            ]
        }, {
            year: 2016,
            links: [{
                    label: 'Audit committee report Jun-2016',
                    link: '2016/Disclosure/Audit committee report Jun-2016.pdf'
                },
                {
                    label: 'Audit committee report Mar-2016',
                    link: '2016/Disclosure/Audit committee report Mar-2016.pdf'
                }, {
                    label: 'Dividends Declared',
                    link: '2016/Disclosure/Dividends Declared.pdf'
                }, {
                    label: 'Shareholder structure Jun-2016',
                    link: '2016/Disclosure/Shareholder structure Jun-2016.pdf'
                }, {
                    label: 'Shareholder structure Mar-2016',
                    link: '2016/Disclosure/Shareholder structure Mar-2016.PDF'
                }, {
                    label: 'تقرير لجنة المراجعة ديسمبر2016',
                    link: '2016/Disclosure/تقرير لجنة المراجعة ديسمبر2016.pdf'
                }, {
                    label: 'تقرير لجنة المراجعة سبتمبر2016',
                    link: '2016/Disclosure/تقرير لجنة المراجعة سبتمبر2016.pdf'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 09-2016',
                    link: '2016/Disclosure/نموذج تقرير أفصاح هيكل المساهمين 09-2016.PDF'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 12-2016',
                    link: '2016/Disclosure/نموذج تقرير أفصاح هيكل المساهمين 12-2016.PDF'
                }
            ]
        }, {
            year: 2017,
            links: [{
                    label: 'الجمعية العامة العادية 2017',
                    link: '2017/Disclousre/الجمعية العامة العادية 2017.pdf'
                },
                {
                    label: 'بيان توزيعات الكوبون',
                    link: '2017/Disclousre/بيان توزيعات الكوبون.pdf'
                }, {
                    label: 'تقرير لجنة المراجعة مارس 2017',
                    link: '2017/Disclousre/تقرير لجنة المراجعة مارس 2017.pdf'
                }, {
                    label: 'تقرير لجنة المراجعة يونيو 2017',
                    link: '2017/Disclousre/تقرير لجنة المراجعة يونيو 2017.pdf'
                }, {
                    label: 'لجنة المراجعة - الربع الثالث 2017',
                    link: '2017/Disclousre/لجنة المراجعة - الربع الثالث 2017.pdf'
                }, {
                    label: 'لجنة المراجعة - الربع الرابع 2017',
                    link: '2017/Disclousre/لجنة المراجعة - الربع الرابع 2017.pdf'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 03-2017.',
                    link: '2017/Disclousre/نموذج تقرير أفصاح هيكل المساهمين 03-2017.PDF'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 06-2017.',
                    link: '2017/Disclousre/نموذج تقرير أفصاح هيكل المساهمين 06-2017.PDF'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 09-2017.',
                    link: '2017/Disclousre/نموذج تقرير أفصاح هيكل المساهمين 09-2017.PDF'
                }, {
                    label: 'نموذج تقرير أفصاح هيكل المساهمين 12-2017.',
                    link: '2017/Disclousre/نموذج تقرير أفصاح هيكل المساهمين 12-2017.PDF'
                }, {
                    label: 'نموذج توزيعات الكوبون النقدى',
                    link: '2017/Disclousre/نموذج توزيعات الكوبون النقدى.PDF'
                }
            ]
        }, {
            year: 2018,
            links: [{
                label: 'ملخص قرارات مجلس الأدارة 12-03-2018',
                link: '2018/Board Meetings/ملخص قرارات مجلس الأدارة 12-03-2018.PDF'
            }, {
                label: 'ملخص قرارات مجلس الأدارة 14-05-2018',
                link: '2018/Board Meetings/ملخص قرارات مجلس الأدارة 14-05-2018.pdf'
            }, {
                label: 'نموذج تقرير أفصاح هيكل المساهمين 09-2018',
                link: '/Disclosures/نموذج تقرير أفصاح هيكل المساهمين 09-2018.pdf'
            }]
        }
    ],
    "press-releases": [{
        year: 2015,
        links: [{
            label: 'Fourth Quarter 2015 Results',
            link: '2015/Press release/Fourth Quarter 2015 Results.pdf'
        }, {
            label: 'Second Quarter 2015 Results',
            link: '2015/Press release/Second Quarter 2015 Results.pdf'
        }]
    }, {
        year: 2016,
        links: [{
            label: 'First Quarter 2016 Results',
            link: '2016/Press release/First Quarter 2016 Results.PDF'
        }, {
            label: 'Second Quarter 2016 Results',
            link: '2016/Press release/Second Quarter 2016 Results.pdf'
        }, {
            label: 'بيان نتائج 30-09-2016',
            link: '2016/Press release/بيان نتائج 30-09-2016.PDF'
        }, {
            label: 'بيان نتائج الاعمال 31-12-2016',
            link: '2016/Press release/بيان نتائج الأعمال 31-12-2016.PDF'
        }]
    }, {
        year: 2017,
        links: [{
            label: 'بيان نتائج الأعمال 31-03-2017',
            link: '2017/Press release/-بيان نتائج الأعمال 31-03-2017.PDF'
        }, {
            label: 'بيان نتائج الأعمال 30-06-2017',
            link: '2017/Press release/بيان نتائج الأعمال 30-06-2017.PDF'
        }, {
            label: 'بيان نتائج الأعمال 30-09-2017',
            link: '2017/Press release/بيان نتائج الأعمال 30-09-2017.PDF'
        }, {
            label: 'بيان نتائج الأعمال 31-12-2017',
            link: '2017/Press release/بيان نتائج الأعمال 31-12-2017.PDF'
        }]
    }, {
        year: 2018,
        links: [{
            label: 'بيان نتائج الأعمال 30-09-2018',
            link: 'Press Release/بيان نتائج الأعمال 30-09-2018.pdf'
        }, {
            label: 'بيان نتائج الأعمال 31-03-2018',
            link: '2018/Press release/بيان نتائج الأعمال 31-03-2018.pdf'
        }, {
            label: 'بيان نتائج الأعمال 30-06-2018',
            link: '2018/Press release/بيان نتائج الأعمال 30-06-2018.pdf'
        }]
    }],
    "annual-reports": [{
            year: 2015,
            links: [{ // 2nd Quarter
                label: 'Annual Report 2015',
                link: "2015/Annual report/Annual Report 2015.pdf"
            }, ]
        },
        {
            year: 2017,
            links: [{
                    label: 'Audit committee report Jun-2017',
                    link: "Audit_committee_report_Jun-2017..pdf"
                },
                {
                    label: 'GEMMA 2017 Q3 Financials (1658 KB)',
                    link: 'GEMMA_2017_Q3_Financials..PDF'
                },
                {
                    label: 'Shareholder structure Dec-2017  (383 KB)',
                    link: 'Shareholder_structure_Dec-2017.PDF'
                }, {
                    label: 'Minutes of board meeting 13-Nov-2017 (319 KB)',
                    link: 'Minutes_of_board_meeting_13-Nov-2017.PDF'
                }
            ]
        }, {
            year: 2018,
            links: [{
                label: 'Audit committee report May-2018',
                link: "بيان نتائج الأعمال 30-06-2018.pdf"
            }]
        }
    ],
    "financial-statements": [

        {
            year: 2015,
            links: [{
                    label: 'GEMMA 2015 Q2 Financials',
                    link: '2015/Financial statements/GEMMA 2015 Q2 Financials.pdf'
                }, {
                    label: 'GEMMA 2015 Q3 Financials',
                    link: '2015/Financial statements/GEMMA 2015 Q3 Financials.pdf'
                }, {
                    label: 'GEMMA 2015 Q3 Financials',
                    link: '2015/Financial statements/GEMMA 2015 Q3 Financials.PDF'
                },
                {
                    label: 'القوائم المالية 31-12-2015',
                    link: '2015/Financial statements/القوائم المالية 31-12-2015.PDF'
                }
            ]
        }, {
            year: 2016,
            links: [{
                    label: 'GEMMA 2016 Q1 Financials',
                    link: '2016/Financial statements/GEMMA 2016 Q1 Financials.PDF'
                }, {
                    label: 'GEMMA 2016 Q2 Financials',
                    link: '2016/Financial statements/GEMMA 2016 Q2 Financials.PDF'
                }, {
                    label: 'القوائم المالية 30-09-2016',
                    link: '2016/Financial Statements/القوائم المالية 30-09-2016.PDF'
                },
                {
                    label: 'القوائم المالية 31-12-2016',
                    link: '2016/Financial Statements/القوائم المالية 31-12-2016.PDF'
                }
            ]
        }, {
            year: 2017,
            links: [{
                    label: 'القوائم المالية 30-06-2017',
                    link: '2017/Financial statements/القوائم المالية 30-06-2017.PDF'
                }, {
                    label: 'القوائم المالية 30-09-2017',
                    link: '2017/Financial statements/القوائم المالية 30-09-2017.PDF'
                }, {
                    label: 'القوائم المالية 31-03-2017',
                    link: '2017/Financial statements/القوائم المالية 31-03-2017.PDF'
                },
                {
                    label: 'القوائم المالية 31-12-2017',
                    link: '2017/Financial statements/القوائم المالية 31-12-2017.PDF'
                }
            ]
        }, {
            year: 2018,
            links: [{
                label: 'القوائم المالية 30-06-2018',
                link: '2018/Financial Statments/القوائم المالية 30-06-2018.PDF'
            }, {
                label: 'القوائم المالية 31-03-2018',
                link: '2018/Financial Statments/القوائم المالية 31-03-2018.PDF'
            }, {
                label: 'القوائم المالية 30-09-2018',
                link: '2018/Financial Statments/القوائم المالية 30-09-2018.PDF'
            }]
        }
    ],
    "general-meetings": [{
        year: 2015,
        links: [{
            label: 'AGM minutes Apr-2015',
            link: '2015/General Meeting/AGM minutes Apr-2015.pdf'
        }]
    }, {
        year: 2016,
        links: [{
            label: 'AGM minutes Apr-2016',
            link: '2016/General Meeting/AGM minutes Apr-2016.pdf'
        }]
    }, {
        year: 2018,
        links: [{
            label: 'الجمعية العامة العادية 2018',
            link: '2018/General Meetings/الجمعية العامة العادية 2018.pdf'
        }]
    }]
}

export default config;