import React from "react";
import './view-selector.css'

import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap"


class viewSelector extends React.PureComponent {
  render() {
    return (
      <div className="col-md-12">
        <div className="view-selector" >
          <div className="btn-group">
            <ButtonToolbar>
              <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                <ToggleButton onClick={() => this.props.changeView(false)}
                  value={1}>Tile View </ToggleButton>
                <ToggleButton onClick={() => this.props.changeView(true)}
                  value={2}>List View</ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </div>
        </div>
      </div>
    );
  }
}

export default viewSelector;
